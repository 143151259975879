import EditIcon from "@mui/icons-material/Edit";
import { Button, IconButton, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { fontStyle } from "app/theme";
import { Confirm, ConfirmModal, LoadingScreen, Tabs } from "components";
import { UserRole } from "generated/openapi";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  leaveOrg,
  selectOrg,
  selectOrgLoading,
  selectUser,
  updateOrg,
  useAppDispatch,
  useAppSelector,
} from "store";
import { CreateOrg } from "./CreateOrg";
import { OrgTeams } from "./OrgTeams";
import { OrgUsers } from "./OrgUsers";

const TABS = [
  {
    key: "Teams",
    Element: OrgTeams,
  },
  {
    key: "Users",
    Element: OrgUsers,
  },
];

export const Org: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(selectUser)!;
  const _org = useAppSelector(selectOrg);
  const loading = useAppSelector(selectOrgLoading);

  const [editing, setEditing] = useState<string | undefined>(undefined);
  const [confirm, setConfirm] = useState<Confirm | undefined>(undefined);

  if (loading) return <LoadingScreen />;

  if (!_org) {
    if (user.userRole === UserRole.Admin) {
      return <CreateOrg />;
    } else {
      navigate("/");
    }
  }

  const org = _org!;
  const isPlayer = user.userRole === UserRole.Player;

  const handleSave = () => {
    if (editing && editing !== org.name) {
      dispatch(updateOrg({ org: { ...org, name: editing } }));
      setEditing(undefined);
    }
  };

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={2} justifyContent="space-between">
        {editing !== undefined ? (
          <>
            <TextField
              autoFocus
              variant="standard"
              sx={{
                width: "100%",
                "& .MuiInputBase-root": {
                  fontWeight: fontStyle.fontWeightSemiBold,
                  fontSize: "2.25rem",
                  lineHeight: 1.167,
                },
                "& .MuiInputBase-input": {
                  p: 0,
                },
              }}
              value={editing}
              onKeyDown={(e) => e.key === "Enter" && handleSave()}
              onChange={(e) => setEditing(e.target.value)}
            />
            <Stack direction="row" gap={2}>
              <Button color="inherit" onClick={() => setEditing(undefined)}>
                Cancel
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={!editing || editing === org.name}
                onClick={() => handleSave()}
              >
                Save
              </Button>
            </Stack>
          </>
        ) : (
          <>
            <Typography sx={{ pb: 2 }} variant="h3">
              {org.name}
            </Typography>
            {!isPlayer && (
              <IconButton
                size="large"
                color="warning"
                onClick={() => setEditing(org.name)}
              >
                <EditIcon fontSize="large" />
              </IconButton>
            )}
          </>
        )}
      </Stack>

      <Tabs tabs={TABS} />

      <Button
        color="error"
        sx={{ marginTop: 4 }}
        onClick={() =>
          setConfirm({
            message: `Are you sure you want to leave the org '${org.name}'?`,
            onConfirm: () => {
              dispatch(leaveOrg({}));
            },
          })
        }
      >
        Leave
      </Button>

      {confirm && (
        <ConfirmModal {...confirm} open onClose={() => setConfirm(undefined)} />
      )}
    </Stack>
  );
};
