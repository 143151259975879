import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Box, Button, Stack, Typography } from "@mui/material";
import { getPlaylist } from "api";
import {
  Confirm,
  ConfirmModal,
  ForzaTeamsInput,
  PlayersInput,
  PlaylistTagsInput,
  PlaylistThumbnail,
} from "components";
import { MinimalPlaylist, Playlist } from "generated/openapi";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import {
  deletePlaylist,
  selectAccessToken,
  selectTeamsMap,
  updatePlaylist,
  useAppDispatch,
  useAppSelector,
} from "store";
import { dateFormat } from "utils";

interface Props {
  playlist: MinimalPlaylist;
}
export const PlaylistItem: FC<Props> = ({ playlist }) => {
  const dispatch = useAppDispatch();

  const teams = useAppSelector(selectTeamsMap)!;
  const token = useAppSelector(selectAccessToken);

  const [confirm, setConfirm] = useState<Confirm | undefined>(undefined);

  const onUpdate = (update: Partial<Playlist>) => {
    getPlaylist({ playlistId: playlist.id, token }).then((p) => {
      dispatch(updatePlaylist({ playlist: { ...p, ...update } }));
    });
  };

  return (
    <Box key={playlist.id} sx={{ bgcolor: "primary.main", height: "100%" }}>
      <Stack direction="column" alignItems="flex-start" sx={{ height: "100%" }}>
        <Box
          sx={{
            width: "100%",
            position: "relative",
            paddingTop: "56.25%",
          }}
        >
          <Box sx={{ position: "absolute", inset: 0 }}>
            <Link to={`/playlists/${playlist.id}/edit`}>
              <PlaylistThumbnail
                playlist={playlist}
                width="100%"
                height="100%"
              />
            </Link>
          </Box>
        </Box>
        <Stack
          gap={2}
          sx={{
            p: 2,
            width: "100%",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Stack
              sx={{
                mr: 3,
                "@supports not (-webkit-line-clamp: 2)": {
                  overflow: "hidden",
                },
              }}
            >
              <Typography
                component={Link}
                to={`/playlists/${playlist.id}/edit`}
                sx={{
                  textDecoration: "none",
                  color: "white",
                  "@supports (-webkit-line-clamp: 2)": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "initial",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  },
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                variant="h5"
              >
                {playlist.name}
              </Typography>
              <Typography variant="caption">
                {dateFormat(playlist.created, "MMM d, yyyy")}
              </Typography>
              {playlist.creator && (
                <Typography variant="caption" sx={{ color: "text.secondary" }}>
                  {playlist.creator.firstName} {playlist.creator.lastName}
                </Typography>
              )}
            </Stack>
            <Stack gap={1}>
              <Button
                size="small"
                component={Link}
                to={`/playlists/${playlist?.id}`}
                variant="outlined"
                sx={{
                  textColor: "white",
                  height: 30,
                  justifyContent: "flex-end",
                }}
                color="success"
                endIcon={<PlayArrowIcon />}
                onClick={() => {}}
              >
                Play
              </Button>
              <Button
                size="small"
                variant="outlined"
                sx={{
                  textColor: "white",
                  height: 30,
                  justifyContent: "flex-end",
                }}
                color="error"
                endIcon={<DeleteIcon />}
                onClick={() =>
                  setConfirm({
                    message: `Are you sure you want to delete '${playlist.name}'?`,
                    onConfirm: () => {
                      dispatch(deletePlaylist({ playlistId: playlist.id }));
                    },
                  })
                }
              >
                Delete
              </Button>
            </Stack>
          </Stack>
          <Stack direction="column" gap={2}>
            <PlaylistTagsInput
              value={playlist.tags}
              variant="standard"
              onChange={(tags) => onUpdate({ tags })}
            />
            <PlayersInput
              value={playlist.playerTags}
              variant="standard"
              options={teams[playlist.teamId].players.map((p) => p.id)}
              onChange={(playerTags) => onUpdate({ playerTags })}
            />
            <ForzaTeamsInput
              variant="standard"
              value={playlist.forzaTeamTags}
              onChange={(forzaTeamTags) => onUpdate({ forzaTeamTags })}
            />
          </Stack>
        </Stack>
      </Stack>

      {confirm && (
        <ConfirmModal {...confirm} open onClose={() => setConfirm(undefined)} />
      )}
    </Box>
  );
};
