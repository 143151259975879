import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EditIcon from "@mui/icons-material/Edit";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import ScaleIcon from "@mui/icons-material/Scale";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import StraightenIcon from "@mui/icons-material/Straighten";
import { Card, IconButton, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Confirm, ConfirmModal, ProfilePicture } from "components";
import { differenceInYears } from "date-fns";
import { User, UserRole } from "generated/openapi";
import { FC, ReactNode, useState } from "react";
import {
  kickFromOrg,
  kickFromTeam,
  selectUser,
  useAppDispatch,
  useAppSelector,
} from "store";
import { dateFormat } from "utils";
import { UpdatePlayer } from "./UpdatePlayer";
import { UpdateUser } from "./UpdateUser";

export interface Props {
  user: User;
  teamId?: number;
}

export const OrgUserCard: FC<Props> = ({ user, teamId }) => {
  const dispatch = useAppDispatch();

  const _user = useAppSelector(selectUser)!;

  const [updateUser, setUpdateUser] = useState(false);
  const [confirm, setConfirm] = useState<Confirm | undefined>(undefined);

  const canEdit =
    _user.id === user.id ||
    (user.userRole === UserRole.Player && _user.userRole !== UserRole.Player);

  const canKick =
    _user.id !== user.id &&
    (_user.userRole === UserRole.Admin ||
      (_user.userRole === UserRole.Coach &&
        (user.userRole === UserRole.Coach ||
          user.userRole === UserRole.Player)));

  const userField = (icon: ReactNode, text: string) => (
    <Stack gap={1} direction="row">
      {icon}
      <Typography>{text}</Typography>
    </Stack>
  );

  return (
    <Card
      sx={{
        bgcolor: "primary.main",
        padding: 2,
        borderRadius: 2,
        maxWidth: 400,
      }}
    >
      <Stack gap={2}>
        <ProfilePicture user={user} />

        <Typography
          fontWeight={_user.id === user.id ? "bold" : "normal"}
          variant="h4"
        >
          {user.firstName} {user.lastName}
        </Typography>

        {user.birthday &&
          userField(
            <CalendarMonthIcon />,
            `${dateFormat(user.birthday, "dd MMM, yyyy")} (${differenceInYears(new Date(), new Date(user.birthday))})`,
          )}
        {user.weight && userField(<ScaleIcon />, `${user.weight} kg`)}
        {user.height && userField(<StraightenIcon />, `${user.height} cm`)}
        {user.position && userField(<SportsSoccerIcon />, user.position)}

        <Stack direction="row" justifyContent="end">
          {canEdit && (
            <IconButton onClick={() => setUpdateUser(true)}>
              <EditIcon />
            </IconButton>
          )}
          {canKick && (
            <IconButton
              onClick={() => {
                setConfirm(
                  teamId === undefined
                    ? {
                        message: `Are you sure you want to remove '${user.firstName} ${user.lastName}' from the org?`,
                        onConfirm: () => {
                          dispatch(kickFromOrg({ userId: user.id }));
                        },
                      }
                    : {
                        message: `Are you sure you want to remove '${user.firstName} ${user.lastName}' from the team?`,
                        onConfirm: () => {
                          dispatch(kickFromTeam({ teamId, userId: user.id }));
                        },
                      },
                );
              }}
            >
              <PersonRemoveIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>

      {updateUser &&
        (_user.id !== user.id && user.userRole === UserRole.Player ? (
          <UpdatePlayer user={user} onClose={() => setUpdateUser(false)} />
        ) : (
          <UpdateUser user={user} onClose={() => setUpdateUser(false)} />
        ))}
      {confirm && (
        <ConfirmModal {...confirm} open onClose={() => setConfirm(undefined)} />
      )}
    </Card>
  );
};
