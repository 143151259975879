import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { createInvite } from "api";
import { Modal, TeamsFilter } from "components";
import { CreateInvite, UserRole } from "generated/openapi";
import { FC } from "react";
import { Form, useForm } from "react-hook-form";
import { selectAccessToken, selectUser, useAppSelector } from "store";

export interface Props {
  invite: CreateInvite;
  onClose: () => void;
}

export const InviteUser: FC<Props> = ({ invite, onClose }) => {
  const token = useAppSelector(selectAccessToken);
  const user = useAppSelector(selectUser)!;

  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm<CreateInvite>({ defaultValues: invite });

  return (
    <Modal open={true} onClose={onClose}>
      <Form
        control={control}
        style={{ display: "flex", flexDirection: "column", gap: 18 }}
        onSubmit={({ data }) => {
          createInvite(data, token!).then(() => onClose());
        }}
      >
        <Typography sx={{ pb: 2 }} variant="h3">
          Invite User
        </Typography>

        <TextField
          autoFocus
          color="info"
          label="Email"
          variant="outlined"
          error={!!errors.email}
          {...register("email", { required: true })}
        />

        <FormControl error={!!errors.userRole} color="info">
          <FormLabel>User Role</FormLabel>
          <RadioGroup defaultValue={invite.userRole}>
            {user.userRole === UserRole.Admin && (
              <FormControlLabel
                value={UserRole.Admin}
                control={<Radio color="info" />}
                label={UserRole.Admin}
                {...register("userRole", { required: true })}
              />
            )}
            <FormControlLabel
              value={UserRole.Coach}
              control={<Radio color="info" />}
              label={UserRole.Coach}
              {...register("userRole", { required: true })}
            />
            <FormControlLabel
              value={UserRole.Player}
              control={<Radio color="info" />}
              label={UserRole.Player}
              {...register("userRole", { required: true })}
            />
          </RadioGroup>
        </FormControl>

        <TeamsFilter
          defaultValue={invite.teamId ?? undefined}
          onChange={(value) => setValue("teamId", value?.id)}
        />

        <Button color="info" type="submit">
          Submit
        </Button>
      </Form>
    </Modal>
  );
};
