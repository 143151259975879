import {
  MinimalPlaylists,
  Playlist,
  PlaylistSegments,
} from "generated/openapi";

export interface PlaylistState {
  segments?: PlaylistSegments;
  playlist?: Playlist;
  playlists?: MinimalPlaylists;
  segmentsLoading: boolean;
  playlistsLoading: boolean;
}

export const initialPlaylistState: PlaylistState = {
  segments: undefined,
  playlist: undefined,
  playlists: undefined,
  segmentsLoading: false,
  playlistsLoading: false,
};
