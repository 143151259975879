import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { fontStyle } from "app/theme";
import { TeamsFilter } from "components";
import { TeamDetailed, UserRole } from "generated/openapi";
import { FC, useEffect, useMemo, useState } from "react";
import {
  selectOrg,
  selectTeamsSorted,
  selectUser,
  updateTeam,
  useAppDispatch,
  useAppSelector,
} from "store";
import { CreateGroup } from "./CreateGroup";
import { CreateTeam } from "./CreateTeam";
import { OrgTeamGroup } from "./OrgTeamGroup";
import { OrgUsersSection } from "./OrgUsersSection";

export const OrgTeams: FC = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser)!;
  const org = useAppSelector(selectOrg)!;
  const teams = useAppSelector(selectTeamsSorted)!;

  const [team, setTeam] = useState<TeamDetailed | undefined>(teams[0]);
  const [editing, setEditing] = useState<string | undefined>(undefined);
  const [createTeam, setCreateTeam] = useState(false);
  const [createGroup, setCreateGroup] = useState(false);

  const groups = useMemo(
    () =>
      team ? [...team.groups].sort((a, b) => a.name.localeCompare(b.name)) : [],
    [team],
  );

  useEffect(() => setTeam(teams.find((t) => t.id === team?.id)), [team, teams]);

  const isAdmin = user.userRole === UserRole.Admin;
  const isCoach = user.userRole === UserRole.Coach;
  const isPlayer = user.userRole === UserRole.Player;

  const handleSave = () => {
    if (editing && editing !== org.name) {
      dispatch(updateTeam({ team: { ...team!, name: editing } }));
      setEditing(undefined);
    }
  };

  return (
    <Stack>
      {teams.length > 1 && (
        <Stack direction="row" gap={2} justifyContent="space-between">
          <TeamsFilter disableClearable value={team?.id} onChange={setTeam} />
          {isAdmin && (
            <Button
              color="inherit"
              sx={{ marginLeft: "auto", marginBottom: 2 }}
              disabled={org.teams.length >= org.maxTeams}
              startIcon={<AddIcon />}
              onClick={() => setCreateTeam(true)}
            >
              Create Team
            </Button>
          )}
        </Stack>
      )}

      {team && (
        <Stack gap={4}>
          <Stack direction="row" gap={2} justifyContent="space-between">
            {editing !== undefined ? (
              <>
                <TextField
                  autoFocus
                  variant="standard"
                  sx={{
                    width: "100%",
                    marginBlock: "1rem",
                    "& .MuiInputBase-root": {
                      fontWeight: fontStyle.fontWeightSemiBold,
                      fontSize: "1.5rem",
                      lineHeight: 1,
                    },
                    "& .MuiInputBase-input": {
                      p: 0,
                    },
                  }}
                  value={editing}
                  onKeyDown={(e) => e.key === "Enter" && handleSave()}
                  onChange={(e) => setEditing(e.target.value)}
                />
                <Stack direction="row" gap={2}>
                  <Button color="inherit" onClick={() => setEditing(undefined)}>
                    Cancel
                  </Button>
                  <Button
                    color="success"
                    variant="contained"
                    disabled={!editing || editing === team.name}
                    onClick={() => handleSave()}
                  >
                    Save
                  </Button>
                </Stack>
              </>
            ) : (
              <>
                <Typography sx={{ marginBlock: "auto" }} variant="h4">
                  {team.name}
                </Typography>
                {!isPlayer && (
                  <IconButton
                    size="large"
                    color="warning"
                    onClick={() => setEditing(team.name)}
                  >
                    <EditIcon fontSize="large" />
                  </IconButton>
                )}
              </>
            )}
          </Stack>
          <OrgUsersSection
            title="Players"
            userRole={UserRole.Player}
            canManage={isCoach || isAdmin}
            users={team.players}
            teamId={team.id}
          />
          <OrgUsersSection
            title="Coaches"
            userRole={UserRole.Coach}
            canManage={isCoach || isAdmin}
            users={team.coaches}
            teamId={team.id}
          />
          <OrgUsersSection
            title="Admins"
            userRole={UserRole.Admin}
            canManage={isAdmin}
            users={team.admins}
            teamId={team.id}
          />
          <Divider flexItem color="gray" sx={{ opacity: 0.6 }} />
          <Stack direction="row" gap={2} justifyContent="space-between">
            <Typography sx={{ pb: 2 }} variant="h5">
              Groups
            </Typography>
            {!isPlayer && (
              <Button
                color="inherit"
                sx={{ marginLeft: "auto", marginBottom: 2 }}
                disabled={org.teams.length >= org.maxTeams}
                startIcon={<AddIcon />}
                onClick={() => setCreateGroup(true)}
              >
                Create Group
              </Button>
            )}
          </Stack>
          {groups?.map((g, i) => (
            <OrgTeamGroup key={i} teamId={team.id} group={g} />
          ))}
        </Stack>
      )}

      {createTeam && <CreateTeam onClose={() => setCreateTeam(false)} />}
      {createGroup && (
        <CreateGroup teamId={team!.id} onClose={() => setCreateGroup(false)} />
      )}
    </Stack>
  );
};
