import { Tabs } from "components";
import { FC } from "react";
import { Playlists } from "./Playlists";
import { Segments } from "./Segments";

const TABS = [
  {
    key: "Playlists",
    Element: Playlists,
  },
  {
    key: "Segments",
    Element: Segments,
  },
];

export const PlaylistsView: FC = () => {
  return <Tabs tabs={TABS} />;
};
