import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { Button, Card, IconButton, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { fontStyle } from "app/theme";
import { Confirm, ConfirmModal } from "components";
import { Group, UserRole } from "generated/openapi";
import { FC, useState } from "react";
import {
  deleteGroup,
  selectUser,
  updateGroup,
  useAppDispatch,
  useAppSelector,
} from "store";
import { AddUsersToGroupModal } from "./AddUsersToGroupModal";

export interface Props {
  teamId: number;
  group: Group;
}

export const OrgTeamGroup: FC<Props> = ({ teamId, group }) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser)!;

  const [editing, setEditing] = useState<string | undefined>(undefined);
  const [addUsers, setAddUsers] = useState(false);
  const [confirm, setConfirm] = useState<Confirm | undefined>(undefined);

  const isPlayer = user.userRole === UserRole.Player;

  const handleSave = () => {
    if (editing && editing !== group.name) {
      dispatch(updateGroup({ teamId, group: { ...group, name: editing } }));
      setEditing(undefined);
    }
  };

  return (
    <Card sx={{ bgcolor: "primary.main", padding: 2, borderRadius: 2 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ marginBottom: 2 }}
      >
        {editing !== undefined ? (
          <>
            <TextField
              autoFocus
              variant="standard"
              sx={{
                width: "100%",
                marginBlock: "1rem",
                "& .MuiInputBase-root": {
                  fontWeight: fontStyle.fontWeightSemiBold,
                  fontSize: "1.5rem",
                  lineHeight: 1,
                },
                "& .MuiInputBase-input": {
                  p: 0,
                },
              }}
              value={editing}
              onKeyDown={(e) => e.key === "Enter" && handleSave()}
              onChange={(e) => setEditing(e.target.value)}
            />
            <Stack direction="row" gap={2}>
              <Button
                color="inherit"
                size="small"
                onClick={() => setEditing(undefined)}
              >
                Cancel
              </Button>
              <Button
                color="success"
                variant="contained"
                size="small"
                disabled={!editing || editing === group.name}
                onClick={() => handleSave()}
              >
                Save
              </Button>
            </Stack>
          </>
        ) : (
          <>
            <Typography
              sx={{ marginBlock: "auto", width: "100%" }}
              variant="h4"
            >
              {group.name}
            </Typography>
            {!isPlayer && (
              <IconButton
                color="warning"
                onClick={() => setEditing(group.name)}
              >
                <EditIcon />
              </IconButton>
            )}
          </>
        )}
        {!isPlayer && (
          <IconButton sx={{ marginLeft: 2 }} onClick={() => setAddUsers(true)}>
            <PersonAddIcon />
          </IconButton>
        )}
      </Stack>
      {group.users.map((u, i) => (
        <Stack key={i} direction="row" justifyContent="space-between">
          <Typography
            sx={{ marginBlock: "auto" }}
            fontWeight={u.id === user.id ? "bold" : "normal"}
          >
            {u.firstName} {u.lastName}
          </Typography>
          {!isPlayer && u.id !== user.id && (
            <IconButton
              onClick={() => {
                setConfirm({
                  message: `Are you sure you want to remove '${u.firstName} ${u.lastName}' from the group '${group.name}'?`,
                  onConfirm: () => {
                    dispatch(
                      updateGroup({
                        teamId,
                        group: {
                          ...group,
                          users: group.users.filter((v) => v.id !== u.id),
                        },
                      }),
                    );
                  },
                });
              }}
            >
              <PersonRemoveIcon />
            </IconButton>
          )}
        </Stack>
      ))}

      {!isPlayer && (
        <Button
          color="error"
          sx={{ float: "right", marginTop: 4 }}
          onClick={() =>
            setConfirm({
              message: `Are you sure you want to delete the group '${group.name}'`,
              onConfirm: () => {
                dispatch(deleteGroup({ teamId, groupId: group.id }));
              },
            })
          }
        >
          Delete
        </Button>
      )}

      {addUsers && (
        <AddUsersToGroupModal
          teamId={teamId}
          group={group}
          onClose={() => setAddUsers(false)}
        />
      )}
      {confirm && (
        <ConfirmModal {...confirm} open onClose={() => setConfirm(undefined)} />
      )}
    </Card>
  );
};
