import {
  ForzaGame,
  ForzaGames,
  V1forzaApiForzaGamesRequest,
} from "generated/openapi";

export interface ForzaGamesState {
  forzaGame?: ForzaGame;
  forzaGames?: ForzaGames;
  forzaGamesFilter: V1forzaApiForzaGamesRequest;
  forzaGamesLoading: boolean;
}

export const initialForzaGamesState: ForzaGamesState = {
  forzaGame: undefined,
  forzaGames: undefined,
  forzaGamesFilter: { limit: 20, offset: 0 },
  forzaGamesLoading: false,
};
