import { ChipAutocomplete } from "components";
import { FC } from "react";
import {
  selectPlaylistTagsMap,
  selectPlaylistTagsSorted,
  useAppSelector,
} from "store";

interface Props {
  value?: number[] | null;
  variant?: "filled" | "outlined" | "standard";
  onChange: (_: number[]) => void;
}

export const PlaylistTagsInput: FC<Props> = ({
  value: _value,
  variant,
  onChange,
}) => {
  const options = useAppSelector(selectPlaylistTagsSorted) ?? [];
  const tags = useAppSelector(selectPlaylistTagsMap) ?? {};

  const value = _value?.length ? _value.map((t) => tags[t]) : [];

  return (
    <ChipAutocomplete
      placeholder="Add tag"
      value={value}
      variant={variant}
      options={options}
      getOptionLabel={(option) => option.name}
      onChange={(value) => onChange(value.map((v) => v.id))}
    />
  );
};
