import { SvgIconComponent } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupsIcon from "@mui/icons-material/Groups";
import ImageIcon from "@mui/icons-material/Image";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PersonIcon from "@mui/icons-material/Person";
import VideoStableIcon from "@mui/icons-material/VideoStable";
import {
  Badge,
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { GetPlaylistSegmentsArgs } from "api";
import theme from "app/theme";
import { ForzaTeamsInput, PlayersInput, SegmentTagsFilter } from "components";
import { PlaylistSegment } from "generated/openapi";
import {
  FC,
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import {
  generatePlaylistHomography,
  selectForzaTeamsMap,
  useAppDispatch,
  useAppSelector,
} from "store";
import { minutesSeconds } from "utils";

interface SegmentIdx {
  segment: PlaylistSegment;
  // Index of this segment in the non-filtered list from the parent component.
  idx: number;
}

export interface Props {
  segments: PlaylistSegment[];
  playlistId?: number;
  selected?: number;
  isPlaylistDirty?: boolean;
  subtitle?: (_: PlaylistSegment) => string;
  onChange?: (segment: PlaylistSegment[]) => void;
  onSelect?: (index?: number) => void;
}

export const PlaylistSegments = forwardRef(
  (
    {
      segments: _segments,
      playlistId,
      selected: _selected,
      isPlaylistDirty,
      subtitle,
      onChange,
      onSelect: _onSelect,
    }: Props,
    ref,
  ) => {
    const xlBreakpoint = useMediaQuery(theme.breakpoints.up(1600));
    const [filters, setFilters] = useState<Partial<GetPlaylistSegmentsArgs>>(
      {},
    );

    const [segments, hasFilters] = useMemo(() => {
      const { tags, players, teams } = filters;
      let s: SegmentIdx[] = _segments.map((segment, idx) => ({ segment, idx }));

      if (tags?.length)
        s = s.filter(({ segment }) =>
          tags?.some((t) => segment.tags.includes(t.id)),
        );
      if (players?.length)
        s = s.filter(({ segment }) =>
          players?.some((p) => segment.playerTags.includes(p)),
        );
      if (teams?.length)
        s = s.filter(({ segment }) =>
          teams?.some((t) => segment.forzaTeamTags.includes(t)),
        );

      return [s, !!(tags?.length ?? players?.length ?? teams?.length)];
    }, [_segments, filters]);

    const [tagOptions, playerOptions, teamOptions] = useMemo(
      () => [
        Array.from(new Set(_segments.flatMap((s) => s.tags))),
        Array.from(new Set(_segments.flatMap((s) => s.playerTags))),
        Array.from(new Set(_segments.flatMap((s) => s.forzaTeamTags))),
      ],
      [_segments],
    );

    const onSelect = (i: number) => _onSelect?.(segments[i]?.idx);

    // Reset index on filter change
    useEffect(() => onSelect(0), [filters]);

    const selectedIdx =
      _selected === undefined
        ? undefined
        : segments.findIndex(({ idx }) => idx === _selected);

    useImperativeHandle(
      ref,
      (): PlaylistSegmentsRef => ({
        next() {
          if (selectedIdx !== undefined && selectedIdx < segments.length - 1) {
            onSelect(selectedIdx + 1);
          }
        },
      }),
      [selectedIdx],
    );

    const onDeletes = useMemo(
      () =>
        onChange
          ? segments.map(({ idx }, i) => () => {
              const newSegments = _segments.filter((_, j) => j !== idx);
              onChange(newSegments);

              if (i === selectedIdx) {
                onSelect(i === segments.length - 1 ? i - 1 : i);
              } else if (selectedIdx && i < selectedIdx) {
                onSelect(selectedIdx - 1);
              }
            })
          : [],
      [_segments.length, segments.length, selectedIdx],
    );

    return (
      <>
        <Box
          sx={{
            width: "100%",
            "& .MuiAutocomplete-paper": {
              width: 330,
            },
            "& .MuiFormLabel-root": {
              color: `${theme.palette.text.secondary} !important`,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: `${theme.palette.primary.light} !important`,
              borderRadius: 0,
            },
          }}
        >
          <Stack
            direction="row"
            gap={1}
            justifyContent="space-between"
            sx={{ pb: 1 }}
          >
            <SegmentTagsFilter
              value={filters.tags ?? []}
              options={tagOptions}
              onChange={(tags) => setFilters({ ...filters, tags })}
            />
            <PlayersInput
              placeholder="Players"
              value={filters.players ?? []}
              options={playerOptions}
              onChange={(players) => setFilters({ ...filters, players })}
            />
            <ForzaTeamsInput
              placeholder="Teams"
              value={filters.teams ?? []}
              options={teamOptions}
              onChange={(teams) => setFilters({ ...filters, teams })}
            />
          </Stack>
        </Box>
        <DragDropContext
          onDragEnd={({ source: src, destination: dst }: DropResult) => {
            if (!onChange || dst?.index === undefined) return;

            if (selectedIdx !== undefined) {
              if (src.index === selectedIdx) {
                onSelect(dst.index);
              } else if (src.index > selectedIdx && dst.index <= selectedIdx) {
                onSelect(selectedIdx + 1);
              } else if (src.index < selectedIdx && dst.index >= selectedIdx) {
                onSelect(selectedIdx - 1);
              }
            }

            onChange(_segments.move(src.index, dst.index));
          }}
        >
          <Droppable droppableId="droppable" isDropDisabled={hasFilters}>
            {(provided, _snapshot) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={{
                  width: "100%",
                  overflowY: xlBreakpoint ? "auto" : "none",
                  maxHeight: "82vh",
                }}
              >
                {segments.map(({ segment }, i) => (
                  <Segment
                    key={i}
                    idx={i}
                    playlistId={playlistId}
                    isPlaylistDirty={isPlaylistDirty}
                    segment={segment}
                    selected={selectedIdx === i}
                    dragDisabled={hasFilters || !onChange}
                    subtitle={subtitle}
                    onSelect={() => onSelect(i)}
                    onDelete={onDeletes[i]}
                  />
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </>
    );
  },
);

interface SProps {
  idx: number;
  segment: PlaylistSegment;
  selected: boolean;
  dragDisabled: boolean;
  playlistId?: number;
  isPlaylistDirty?: boolean;
  subtitle?: (_: PlaylistSegment) => string;
  onSelect: () => void;
  onDelete?: () => void;
}

const Segment: FC<SProps> = memo(
  ({
    idx,
    segment,
    selected,
    dragDisabled,
    subtitle,
    playlistId,
    isPlaylistDirty,
    onSelect,
    onDelete,
  }: SProps) => {
    const teams = useAppSelector(selectForzaTeamsMap);
    const dispatch = useAppDispatch();

    return (
      <Draggable
        draggableId={idx.toString()}
        index={idx}
        isDragDisabled={dragDisabled}
      >
        {(provided, snapshot) => (
          <Box
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            sx={{
              mb: 1,
              "&:last-child": {
                mb: 0,
              },
              userSelect: "none",
              bgcolor: selected ? "primary.light" : "primary.main",
              cursor: dragDisabled ? "pointer" : undefined,
              border: snapshot.isDragging ? "2px solid #aaa" : "none",
              ...provided.draggableProps.style,
            }}
            onClick={() => onSelect()}
          >
            <Stack direction="row" gap={1} sx={{ px: 2, pt: 2 }}>
              <Typography fontWeight="bold">{segment.title}</Typography>
              <Box sx={{ marginLeft: "auto", display: "flex" }}>
                <IconButton
                  disabled={
                    segment.hasHomography ||
                    !!isPlaylistDirty ||
                    !segment.gameHasPanorama
                  }
                  sx={{ width: 40, height: 40 }}
                  color="inherit"
                  onClick={(e) => {
                    e.stopPropagation();
                    playlistId &&
                      dispatch(
                        generatePlaylistHomography({
                          playlistId,
                          segments: [segment.id],
                        }),
                      );
                  }}
                >
                  <VideoStableIcon />
                </IconButton>
                {onDelete && (
                  <IconButton
                    sx={{ width: 40, height: 40 }}
                    color="inherit"
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete();
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            </Stack>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{ px: 2, pb: 2 }}
            >
              {subtitle?.(segment) ??
                `${teams[segment.homeTeamId].name} vs. ${teams[segment.visitingTeamId].name}`}
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                py: 1,
                px: 2,
                alignItems: "center",
              }}
            >
              <Typography variant="caption" sx={{ lineHeight: 0, mr: 0.5 }}>
                {minutesSeconds(segment.toTimestamp - segment.fromTimestamp)}
              </Typography>
              {customBadge(Object.values(segment.drawings).length, ImageIcon)}
              {customBadge(segment.tags.length, LocalOfferIcon)}
              {customBadge(segment.playerTags.length, PersonIcon)}
              {customBadge(segment.forzaTeamTags.length, GroupsIcon)}
              {!!Object.keys(segment.homographyFrames) && (
                <Box sx={{ display: "flex" }}>
                  <Divider
                    sx={{ bgcolor: "primary.light", mr: 1.5 }}
                    orientation="vertical"
                    flexItem
                  />
                  <Badge
                    sx={{
                      mr: Object.keys(segment.homographyFrames).length
                        ? 1
                        : 0.5,
                      "& .MuiBadge-badge": {
                        right: -6,
                        top: 11,
                        color: "white",
                        border: `2px solid ${theme.palette.primary.main}`,
                        padding: "0 4px",
                        bgcolor: "primary.dark",
                      },
                    }}
                    badgeContent={Object.keys(segment.homographyFrames).length}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.9rem",
                        color: "text.secondary",
                        fontWeight: "bold",
                      }}
                    >
                      2D
                    </Typography>
                  </Badge>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Draggable>
    );
  },
  (prevProps, nextProps) =>
    prevProps.idx === nextProps.idx &&
    prevProps.segment === nextProps.segment &&
    prevProps.selected === nextProps.selected &&
    prevProps.onDelete === nextProps.onDelete &&
    prevProps.dragDisabled === nextProps.dragDisabled,
);

export const customBadge = (count: number, Icon: SvgIconComponent) => (
  <>
    <Divider
      sx={{ bgcolor: "primary.light", mr: 0.5 }}
      orientation="vertical"
      flexItem
    />
    <Badge
      sx={{
        mr: count ? 1 : 0.5,
        "& .MuiBadge-badge": {
          right: -3,
          top: 11,
          color: "white",
          border: `2px solid ${theme.palette.primary.main}`,
          padding: "0 4px",
          bgcolor: "primary.dark",
        },
      }}
      badgeContent={count}
    >
      <Icon fontSize="small" sx={{ color: "text.secondary" }} />
    </Badge>
  </>
);

export interface PlaylistSegmentsRef {
  next: () => void;
}
