import {
  Autocomplete,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { FileUpload, Modal } from "components";
import { DateFilter } from "components/HighlightsFilters/DateFilter";
import {
  Position,
  UpdatePlayer as UpdatePlayerBody,
  User,
} from "generated/openapi";
import { FC } from "react";
import { Form, useForm } from "react-hook-form";
import { updatePlayer, useAppDispatch } from "store";

export interface Props {
  user: User;
  onClose: () => void;
}

export const UpdatePlayer: FC<Props> = ({ user, onClose }) => {
  const dispatch = useAppDispatch();

  const { watch, control, setValue } = useForm<UpdatePlayerBody>({
    defaultValues: user,
  });

  return (
    <Modal open={true} onClose={onClose}>
      <Form
        control={control}
        style={{ display: "flex", flexDirection: "column", gap: 18 }}
        onSubmit={({ data }) => {
          dispatch(updatePlayer({ userId: user.id, user: data })).then(() =>
            onClose(),
          );
        }}
      >
        <Typography sx={{ pb: 2 }} variant="h3">
          Update Player
        </Typography>

        <Typography>Profile Picture</Typography>
        <FileUpload
          accept="image/*"
          valid={!!watch("profileImage")}
          onChange={(v) =>
            setValue(
              "profileImage",
              v.substring("data:image/png;base64,".length),
            )
          }
        />

        <DateFilter
          label="Birthday"
          size="medium"
          value={watch("birthday")}
          onChange={(v) => setValue("birthday", v?.toISOString())}
        />

        <TextField
          color="info"
          variant="outlined"
          type="number"
          label="Height"
          value={watch("height") ?? ""}
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
          onChange={(e) => {
            const v = +e.target.value;
            if (Number.isFinite(v)) {
              setValue("height", v);
            }
          }}
        />

        <TextField
          color="info"
          variant="outlined"
          type="number"
          label="Weight"
          value={watch("weight") ?? ""}
          InputProps={{
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          }}
          onChange={(e) => {
            const v = +e.target.value;
            if (Number.isFinite(v)) {
              setValue("weight", v);
            }
          }}
        />

        <Autocomplete
          options={Object.values(Position)}
          value={watch("position")}
          onChange={(_, v) => setValue("position", v)}
          renderInput={(props) => (
            <TextField variant="outlined" {...props} placeholder="Position" />
          )}
        />

        <Button color="info" type="submit">
          Submit
        </Button>
      </Form>
    </Modal>
  );
};
