import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import theme from "app/theme";
import { useLayoutEffect } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, useLocation } from "react-router-dom";
import { store } from "store";
import "utils/prototypes.ts";
import { App } from "./containers";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root");
const root = createRoot(container!);

const auth0Config: Auth0ProviderOptions = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN!,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
  cacheLocation: "localstorage",
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
  authorizationParams: {
    scope: "openid profile email",
    audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
  },
};

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

root.render(
  <StyledEngineProvider injectFirst>
    <Auth0Provider {...auth0Config}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Provider store={store}>
            <ScrollToTop />
            <App />
          </Provider>
        </BrowserRouter>
      </ThemeProvider>
    </Auth0Provider>
  </StyledEngineProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
