import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import { getHomography } from "api/forzaHomographyApi";
import theme from "app/theme";
import { CombinedPlayer, LoadingScreen } from "components";
import { VideoPlayerRef } from "components/VideoPlayer/VideoPlayer";
import { HomographyFrame, HomographyResult } from "generated/openapi";
import { FC, useEffect, useRef, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import {
  getPlaylist,
  selectAccessToken,
  selectPlaylist,
  useAppDispatch,
  useAppSelector,
} from "store";
import { getVideoOffset, updateHomography } from "utils";
import { PlaylistSegments, PlaylistSegmentsRef } from "./PlaylistSegments";

export const Playlist: FC = () => {
  const params = useParams();

  const playlistId = params.playlistId ? +params.playlistId : undefined;

  if (!Number.isFinite(playlistId)) {
    return <Navigate to="/" />;
  }

  return <PlaylistInner id={playlistId!} />;
};

interface Props {
  id: number;
}

const PlaylistInner: FC<Props> = ({ id }) => {
  const dispatch = useAppDispatch();
  const xlBreakpoint = useMediaQuery(theme.breakpoints.up("xl"));

  const playlist = useAppSelector(selectPlaylist);
  const token = useAppSelector(selectAccessToken)!;

  const [segmentIdx, setSegmentIdx] = useState<number | undefined>(0);
  const [homography, setHomography] = useState<HomographyResult | undefined>(
    undefined,
  );
  const [homographyFrames, setHomographyFrames] = useState<
    Record<number, HomographyFrame> | undefined
  >(undefined);

  const segmentsRef = useRef<PlaylistSegmentsRef | null>(null);
  const playerRef = useRef<VideoPlayerRef>(null);

  useEffect(() => {
    if (playlist?.id !== id) {
      dispatch(getPlaylist({ playlistId: id }));
    }
  }, [id, playlist?.id]);

  if (!playlist) {
    return <LoadingScreen />;
  }

  const segment =
    segmentIdx !== undefined ? playlist.segments[segmentIdx] : undefined;

  return (
    <Stack sx={{ width: "100%" }} gap={2}>
      <Stack
        direction={xlBreakpoint ? "row" : "column"}
        justifyContent="space-between"
        gap={1}
      >
        <Box>
          <Typography variant={xlBreakpoint ? "h3" : "h2"}>
            {playlist.name}
          </Typography>
          {playlist.creator && (
            <Typography
              variant="subtitle1"
              sx={{ color: "text.secondary", fontStyle: "italic" }}
            >
              {playlist.creator.firstName} {playlist.creator.lastName}
            </Typography>
          )}
        </Box>
        <Button
          color="warning"
          variant="outlined"
          sx={{
            minWidth: 150,
            height: 35,
            alignSelf: xlBreakpoint ? "flex-end" : "flex-start",
          }}
          component={Link}
          to={`/playlists/${playlist?.id}/edit`}
          startIcon={<EditIcon />}
        >
          Edit Playlist
        </Button>
      </Stack>
      <Stack direction={xlBreakpoint ? "row" : "column"} gap={3}>
        <Box sx={{ width: "100%" }}>
          <CombinedPlayer
            url={
              segment &&
              `${segment.videoBaseUrl}/playlist.m3u8/${segment.videoAssetId}:${segment.fromTimestamp}:${segment.toTimestamp}/Manifest.m3u8`
            }
            ref={playerRef}
            dynamicHomography={homography}
            drawings={segment?.drawings ?? {}}
            onEnded={() => segmentsRef.current?.next()}
            gameId={segment?.forzaGameId}
            getVideoOffset={() =>
              getVideoOffset(
                segment!,
                playerRef.current!.getCurrentMillis(),
                segment?.fromTimestamp,
              )
            }
            onResetHomography={() =>
              !homographyFrames && setHomography(undefined)
            }
            onProgress={
              homographyFrames
                ? ({ playedSeconds }) => {
                    setHomography(
                      updateHomography(
                        playedSeconds * 1_000,
                        undefined,
                        segment,
                        homographyFrames,
                      ) ?? {
                        players: [],
                        ballIdx: undefined,
                      },
                    );
                  }
                : undefined
            }
          />
        </Box>
        {playlist.segments.length > 0 && (
          <Box sx={{ width: xlBreakpoint ? 650 : "100%" }}>
            <PlaylistSegments
              segments={playlist.segments}
              selected={segmentIdx}
              onSelect={(i) => {
                setSegmentIdx(i);
                setHomography(undefined);
                setHomographyFrames(undefined);
              }}
            />
          </Box>
        )}
      </Stack>
      <Box>
        <Button
          disabled={!segment?.hasHomography}
          color="inherit"
          onClick={(e) => {
            if (!homographyFrames) {
              getHomography({
                forzaVideoAssetId:
                  segment!.videoAssetId +
                  (segment!.videoBaseUrl.includes("demo-api")
                    ? 100_000_000
                    : 0),
                startMs: segment!.fromTimestamp,
                endMs: segment!.toTimestamp,
                token,
              }).then((_frames) => {
                const frames = Object.fromEntries(
                  _frames.map((e) => [e.videoOffsetMs, e]),
                );
                const ms = playerRef.current!.getCurrentMillis();
                setHomography(
                  updateHomography(ms, frames, segment, homographyFrames) ?? {
                    players: [],
                    ballIdx: undefined,
                  },
                );
                setHomographyFrames(frames);
              });
            } else {
              setHomographyFrames(undefined);
              setHomography(undefined);
            }
            e.currentTarget.blur();
          }}
        >
          Homography
        </Button>
      </Box>
    </Stack>
  );
};
