import AddIcon from "@mui/icons-material/Add";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  alpha,
  Autocomplete,
  autocompleteClasses,
  Box,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputBase,
  Popper,
  Stack,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import theme from "app/theme";
import { VideoPlayerRef } from "components/VideoPlayer/VideoPlayer";
import { TagItem } from "models";
import { FC, useEffect, useMemo, useState } from "react";
import {
  selectSegmentTagsFlattened,
  selectSegmentTagsFlattenedMap,
  useAppSelector,
} from "store";
import { keybindEq, keybindStr } from "utils";

const TIMEOUT = 2_000;

interface BufferedTag {
  tag: TagItem;
  timestamp: number;
  timeout: NodeJS.Timeout;
}

export interface Props {
  videoPlayer: VideoPlayerRef | null;
  onClick: (tag: TagItem, offset: number) => void;
}

export const ForzaGameTagsMenu: FC<Props> = ({ videoPlayer, onClick }) => {
  const allTags = useAppSelector(selectSegmentTagsFlattened)!;
  const tagsMap = useAppSelector(selectSegmentTagsFlattenedMap)!;
  const [showAll, setShowAll] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [_selectedTags, setSelectedTags] = useState(
    allTags.filter((t) => t.favourite),
  );
  const [inputValue, setInputValue] = useState("");
  const [buffered, setBuffered] = useState<BufferedTag | undefined>(undefined);

  const selectedTags = useMemo(
    () =>
      (showAll ? allTags : _selectedTags).sort((a, b) =>
        a.displayName.localeCompare(b.displayName),
      ),
    [allTags, _selectedTags, showAll],
  );

  useEffect(() => {
    const tags = allTags.filter((t) => t.keybind);

    const fn = (e: KeyboardEvent) => {
      if (e.target instanceof HTMLInputElement) return;

      const kb = { char: e.key, ctrl: e.ctrlKey };

      if (buffered) {
        const sub = allTags.find(
          (t) => t?.parentTagId === buffered.tag.id && keybindEq(kb, t.keybind),
        );
        if (sub) {
          e.preventDefault();
          clearTimeout(buffered!.timeout);
          setBuffered(undefined);
          onClick(sub, buffered!.timestamp);
          return;
        }
      }

      const tag = tags.find((t) => !t.parentTagId && keybindEq(kb, t.keybind));
      if (tag) {
        e.preventDefault();

        if (buffered) {
          clearTimeout(buffered!.timeout);
          setBuffered(undefined);
          onClick(buffered.tag, buffered!.timestamp);
        }

        if (tag.subTags?.some((s) => s.keybind)) {
          setBuffered({
            tag,
            timestamp: videoPlayer!.getCurrentMillis(),
            timeout: setTimeout(() => {
              setBuffered(undefined);
              onClick(tag, TIMEOUT);
            }, TIMEOUT),
          });
        } else {
          onClick(tag, videoPlayer!.getCurrentMillis());
        }
      }
    };

    document.addEventListener("keydown", fn);
    return () => {
      document.removeEventListener("keydown", fn);
    };
  }, [buffered, allTags, tagsMap, onClick]);

  return (
    <Stack direction="row" gap={6} justifyContent="space-between">
      <Stack direction="row" gap={1} flexWrap="wrap">
        <IconButton
          sx={{ borderRadius: 2, height: 40, bgcolor: "#01504C" }}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <AddIcon />
        </IconButton>
        {selectedTags.map((t) => (
          <Box
            key={t.id}
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: 2,
              bgcolor: "third.dark",
              textTransform: "capitalize",
              height: 40,
              px: 2,
              "&:hover": {
                cursor: "pointer",
                bgcolor: "third.main",
              },
            }}
            onClick={() => onClick(t, videoPlayer!.getCurrentMillis())}
          >
            <Typography>
              {t.displayName + (t.keybind ? ` (${keybindStr(t.keybind)})` : "")}
            </Typography>
          </Box>
        ))}
      </Stack>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
        sx={{
          width: 300,
          zIndex: theme.zIndex.modal,
          "& .MuiAutocomplete-root": {
            bgcolor: "primary.dark",
          },
          pb: 1,
        }}
      >
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <div>
            <Autocomplete
              open
              multiple
              size="small"
              disableCloseOnSelect
              value={selectedTags}
              inputValue={inputValue}
              options={allTags}
              renderTags={() => null}
              PopperComponent={PopperComponent}
              getOptionLabel={(option) => option.displayName}
              onChange={(_, value) => setSelectedTags(value)}
              onInputChange={(e, v) => {
                if (e?.target instanceof HTMLInputElement) {
                  setInputValue(v);
                }
              }}
              onKeyDownCapture={(e) => {
                if (inputValue === "" && e.key === "Backspace") {
                  e.stopPropagation();
                }
              }}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props} key={option.id}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={
                        <CheckBoxIcon
                          fontSize="small"
                          sx={{ color: "white" }}
                        />
                      }
                      style={{
                        margin: "0px 4px 0px 4px",
                      }}
                      checked={selected}
                    />
                    {option.displayName}
                  </li>
                );
              }}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder="Filter tags..."
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </Popper>
      <FormGroup>
        <FormControlLabel
          sx={{ width: 160 }}
          control={
            <Switch
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: theme.palette.third.main,
                  "&:hover": {
                    bgcolor: alpha(
                      theme.palette.third.main,
                      theme.palette.action.hoverOpacity,
                    ),
                  },
                },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  bgcolor: theme.palette.third.main,
                },
              }}
              checked={showAll}
              onChange={(e) => setShowAll(e.target.checked)}
            />
          }
          label="Show all tags"
        />
      </FormGroup>
    </Stack>
  );
};

const StyledInput = styled(InputBase)(({ theme }) => ({
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: 4,
  width: "100%",
  zIndex: 2500,
  "& input": {
    color: "white",
    borderRadius: 2,
    backgroundColor: theme.palette.primary.main,
    padding: 10,
    border: `1px solid ${theme.palette.primary.light}`,
  },
}));

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const StyledPopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 0,
      paddingRight: 4,
      borderBottom: `1px solid  ${theme.palette.primary.main}`,
      "&[aria-selected='true']": {
        backgroundColor: alpha(theme.palette.primary.main, 0.6),
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.primary.dark,
        },
    },
  },
}));

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal: _, anchorEl: __, open: ___, ...other } = props;
  return <StyledPopper {...other} />;
}
