import { Autocomplete, TextField, Typography } from "@mui/material";
import { TeamDetailed } from "generated/openapi";
import { FC } from "react";
import { selectTeamsMap, selectTeamsSorted, useAppSelector } from "store";

interface Props {
  label?: string;
  value?: number;
  defaultValue?: number;
  teams?: TeamDetailed[];
  disableClearable?: boolean;
  onChange: (_?: TeamDetailed) => void;
}

export const TeamsFilter: FC<Props> = ({
  label,
  value,
  defaultValue,
  teams,
  disableClearable,
  onChange,
}) => {
  const _teams = useAppSelector(selectTeamsSorted) ?? [];
  const teamsMap = useAppSelector(selectTeamsMap) ?? {};

  label ??= "Team";
  teams ??= _teams;

  return (
    <Autocomplete
      size="small"
      sx={{ minWidth: "12rem" }}
      disableClearable={disableClearable}
      value={value !== undefined ? teamsMap[value] : undefined}
      defaultValue={
        defaultValue !== undefined ? teamsMap[defaultValue] : undefined
      }
      options={teams}
      renderInput={(params) => (
        <TextField {...params} label={label ?? "Team"} />
      )}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Typography {...props} key={option.id}>
          {option.name}
        </Typography>
      )}
      onChange={(_, v) => onChange(v ?? undefined)}
      onKeyDown={(e) => e.stopPropagation()}
    />
  );
};
