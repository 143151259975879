import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { IconButton, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { CreateInvite, User, UserRole } from "generated/openapi";
import { FC, useState } from "react";
import { InviteUser } from "./InviteUser";
import { OrgUserCard } from "./OrgUserCard";

export interface Props {
  title: string;
  userRole: UserRole;
  canManage: boolean;
  users: User[];
  teamId?: number;
}

export const OrgUsersSection: FC<Props> = ({
  title,
  userRole,
  canManage,
  users,
  teamId,
}) => {
  const [inviteUser, setInviteUser] = useState<CreateInvite | undefined>(
    undefined,
  );

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ marginBottom: 2 }}
      >
        <Typography sx={{ marginBlock: "auto" }} variant="h4">
          {title}
        </Typography>
        {canManage && (
          <IconButton
            onClick={() => setInviteUser({ email: "", userRole, teamId })}
          >
            <PersonAddIcon />
          </IconButton>
        )}
      </Stack>
      <Stack direction="row" gap={2}>
        {users.map((u) => (
          <OrgUserCard key={u.id} user={u} teamId={teamId} />
        ))}
      </Stack>

      {inviteUser && (
        <InviteUser
          invite={inviteUser}
          onClose={() => setInviteUser(undefined)}
        />
      )}
    </Stack>
  );
};
