import { useAuth0 } from "@auth0/auth0-react";
import { Alert, CssBaseline, Snackbar } from "@mui/material";
import { Layout, LoadingScreen } from "components";
import {
  AcceptInvite,
  CreateJournalEntry,
  CreateUser,
  EditPlaylist,
  ForzaGame,
  ForzaGames,
  ForzaVideo,
  HighlightsView,
  Journal,
  JournalEntry,
  LiveStream,
  LiveStreamsView,
  Org,
  Panorama,
  PlayerJournal,
  Playlist,
  PlaylistsView,
  SettingsView,
} from "containers";
import { SelectKickoff } from "containers/Playlist/SelectKickoff";
import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  getForzaTeams,
  selectAccessTokenObj,
  selectAlert,
  selectForzaTeamsLoading,
  selectIsOrcaAdmin,
  selectLoggedInResourcesLoading,
  selectUser,
  setAlert,
  setAuthState,
  useAppDispatch,
  useAppSelector,
} from "store";

const userRoles = ["Gengar:Admin", "Gengar:Coach", "Gengar:Player"];

export const App: React.FC = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();

  const dispatch = useAppDispatch();
  const token = useAppSelector(selectAccessTokenObj);
  const isOrcaAdmin = useAppSelector(selectIsOrcaAdmin);
  const storeUser = useAppSelector(selectUser);
  const teamsLoading = useAppSelector(selectForzaTeamsLoading);
  const alert = useAppSelector(selectAlert);
  const loggedInResourcesLoading = useAppSelector(
    selectLoggedInResourcesLoading,
  );

  useEffect(() => {
    dispatch(getForzaTeams());
  }, [dispatch]);

  const getAccessToken = (cacheMode?: "off") => {
    getAccessTokenSilently({ cacheMode }).then((token) => {
      dispatch(setAuthState({ user: user!, token }));
    });
  };

  useEffect(() => {
    if (isAuthenticated && !token) {
      getAccessToken();
    }
  }, [isAuthenticated, token]);

  useEffect(() => {
    if (!token) return;

    const diff = token.exp * 1_000 - new Date().getTime();
    const v = setTimeout(() => getAccessToken("off"), diff / 2);

    return () => clearTimeout(v);
  }, [token]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (teamsLoading) {
    return <LoadingScreen />;
  }

  if (isAuthenticated && !token) {
    return <LoadingScreen />;
  }

  const mustCreateUser =
    isAuthenticated && !userRoles.some((r) => token?.user_roles.includes(r));

  if (
    !mustCreateUser &&
    isAuthenticated &&
    (!storeUser || loggedInResourcesLoading)
  ) {
    return <LoadingScreen />;
  }

  return (
    <>
      <CssBaseline />
      <Layout>
        <Routes>
          <Route path="/invites/accept" element={<AcceptInvite />} />

          {mustCreateUser ? (
            <Route path="*" element={<CreateUser />} />
          ) : (
            <>
              <Route path="/" element={<HighlightsView />} />
              <Route path="/forza/events/:eventId" element={<ForzaVideo />} />

              {isAuthenticated && (
                <>
                  <Route path="/org" element={<Org />} />
                  <Route path="/forza/games" element={<ForzaGames />} />
                  <Route
                    path="/forza/games/:gameId/kickoff"
                    element={<SelectKickoff />}
                  />
                  <Route path="/forza/games/:gameId" element={<ForzaGame />} />
                  <Route path="/playlists" element={<PlaylistsView />} />
                  <Route path="/playlists/:playlistId" element={<Playlist />} />
                  <Route
                    path="/playlists/:playlistId/edit"
                    element={<EditPlaylist />}
                  />
                  <Route path="/live_streams" element={<LiveStreamsView />} />
                  <Route
                    path="/live_streams/:liveStreamId"
                    element={<LiveStream />}
                  />
                  <Route path="/journals" element={<Journal />} />
                  <Route
                    path="/journals/entry/:entryId"
                    element={<JournalEntry />}
                  />
                  <Route
                    path="/journals/:playerId"
                    element={<PlayerJournal />}
                  />
                  <Route
                    path="/journals/:playerId/create"
                    element={<CreateJournalEntry />}
                  />
                  <Route path="/settings" element={<SettingsView />} />

                  {isOrcaAdmin && (
                    <Route path="/panorama/:gameId" element={<Panorama />} />
                  )}
                </>
              )}

              <Route path="*" element={<Navigate to="/" />} />
            </>
          )}
        </Routes>
        {alert && (
          <Snackbar
            anchorOrigin={
              alert.anchorOrigin ?? { vertical: "top", horizontal: "center" }
            }
            open={!!alert}
            onClose={() => dispatch(setAlert(undefined))}
            autoHideDuration={alert.autoHideDuration ?? 4000}
          >
            <Alert
              onClose={() => dispatch(setAlert(undefined))}
              severity={alert.severity}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {alert.message}
            </Alert>
          </Snackbar>
        )}
      </Layout>
    </>
  );
};
