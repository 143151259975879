/* tslint:disable */
/* eslint-disable */
/**
 * web-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * Auto-generated discriminant enum variants
 * @export
 * @enum {string}
 */

export const ApiErrorDiscriminants = {
    VideoIteratorEnd: 'VideoIteratorEnd',
    ObjectNotFound: 'ObjectNotFound',
    MissingInviteOrUserRole: 'MissingInviteOrUserRole',
    CantKickSelf: 'CantKickSelf',
    InvalidForzaEventTypeFilterCombination: 'InvalidForzaEventTypeFilterCombination',
    InviteExpired: 'InviteExpired',
    UserAlreadyPartOfOrg: 'UserAlreadyPartOfOrg',
    UserNotPartOfAnOrg: 'UserNotPartOfAnOrg',
    UserNotPartOfATeam: 'UserNotPartOfATeam',
    UserNotPartOfTeam: 'UserNotPartOfTeam',
    UserAndTeamNotPartOfSameOrg: 'UserAndTeamNotPartOfSameOrg',
    UsersNotPartOfSameOrg: 'UsersNotPartOfSameOrg',
    InvalidPlaylistSegmentId: 'InvalidPlaylistSegmentId',
    PlaylistSegmentsNotPartOfPlaylist: 'PlaylistSegmentsNotPartOfPlaylist',
    PlaylistExportEmptySegments: 'PlaylistExportEmptySegments',
    CantModifyArchivedLiveStream: 'CantModifyArchivedLiveStream',
    InvalidPlaylistType: 'InvalidPlaylistType',
    TooManyTeams: 'TooManyTeams',
    TooManyAdmins: 'TooManyAdmins',
    TooManyCoaches: 'TooManyCoaches',
    TooManyPlayers: 'TooManyPlayers',
    LastAdminCantLeave: 'LastAdminCantLeave',
    PlayersCantManageOthers: 'PlayersCantManageOthers',
    UsersCantManageOthersWithHigherRole: 'UsersCantManageOthersWithHigherRole',
    DifferentUserAndInviteRole: 'DifferentUserAndInviteRole',
    UserAlreadyExists: 'UserAlreadyExists',
    PlaylistTagAlreadyExists: 'PlaylistTagAlreadyExists',
    PlaylistSegmentTagAlreadyExists: 'PlaylistSegmentTagAlreadyExists',
    EmptyTag: 'EmptyTag',
    EmailAlreadyInUse: 'EmailAlreadyInUse',
    VideoAssetNotFound: 'VideoAssetNotFound',
    JournalMustBeForPlayer: 'JournalMustBeForPlayer',
    CannotModifyDeletedJournalEntryComment: 'CannotModifyDeletedJournalEntryComment',
    CreatorNotPartOfTeam: 'CreatorNotPartOfTeam',
    TaggedPlayersNotPartOfTeam: 'TaggedPlayersNotPartOfTeam',
    ForzaTeamDoesNotExist: 'ForzaTeamDoesNotExist',
    UserNotPartOfGroupTeam: 'UserNotPartOfGroupTeam',
    GroupUsersNotFound: 'GroupUsersNotFound',
    GroupNotPartOfTeam: 'GroupNotPartOfTeam',
    UserNotExpectedRole: 'UserNotExpectedRole',
    UserNotPartOfTeams: 'UserNotPartOfTeams',
    UsersNotPartOfSameTeam: 'UsersNotPartOfSameTeam',
    MissingLiveStreamPlaylist: 'MissingLiveStreamPlaylist',
    PermissionDenied: 'PermissionDenied',
    InvalidImage: 'InvalidImage',
    InvalidStartEnd: 'InvalidStartEnd',
    Timeout: 'Timeout',
    Unexpected: 'Unexpected'
} as const;

export type ApiErrorDiscriminants = typeof ApiErrorDiscriminants[keyof typeof ApiErrorDiscriminants];


/**
 * 
 * @export
 * @interface ApiErrorResponse
 */
export interface ApiErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiErrorResponse
     */
    'description': string;
    /**
     * 
     * @type {ApiErrorDiscriminants}
     * @memberof ApiErrorResponse
     */
    'error': ApiErrorDiscriminants;
}


/**
 * 
 * @export
 * @interface Arrow
 */
export interface Arrow {
    /**
     * 
     * @type {string}
     * @memberof Arrow
     */
    'stroke': string;
    /**
     * 
     * @type {number}
     * @memberof Arrow
     */
    'strokeWidth': number;
    /**
     * 
     * @type {number}
     * @memberof Arrow
     */
    'x1': number;
    /**
     * 
     * @type {number}
     * @memberof Arrow
     */
    'x2': number;
    /**
     * 
     * @type {number}
     * @memberof Arrow
     */
    'y1': number;
    /**
     * 
     * @type {number}
     * @memberof Arrow
     */
    'y2': number;
}
/**
 * 
 * @export
 * @interface BoundingBox
 */
export interface BoundingBox {
    /**
     * 
     * @type {number}
     * @memberof BoundingBox
     */
    'x1': number;
    /**
     * 
     * @type {number}
     * @memberof BoundingBox
     */
    'x2': number;
    /**
     * 
     * @type {number}
     * @memberof BoundingBox
     */
    'y1': number;
    /**
     * 
     * @type {number}
     * @memberof BoundingBox
     */
    'y2': number;
}
/**
 * 
 * @export
 * @interface ConnectedEllipses
 */
export interface ConnectedEllipses {
    /**
     * 
     * @type {Array<EllipseMinimal>}
     * @memberof ConnectedEllipses
     */
    'ellipses': Array<EllipseMinimal>;
    /**
     * 
     * @type {string}
     * @memberof ConnectedEllipses
     */
    'fill': string;
    /**
     * 
     * @type {string}
     * @memberof ConnectedEllipses
     */
    'stroke': string;
    /**
     * 
     * @type {number}
     * @memberof ConnectedEllipses
     */
    'strokeWidth': number;
}
/**
 * 
 * @export
 * @interface CreateGroup
 */
export interface CreateGroup {
    /**
     * 
     * @type {string}
     * @memberof CreateGroup
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateGroup
     */
    'users'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface CreateInvite
 */
export interface CreateInvite {
    /**
     * 
     * @type {string}
     * @memberof CreateInvite
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof CreateInvite
     */
    'teamId'?: number | null;
    /**
     * 
     * @type {UserRole}
     * @memberof CreateInvite
     */
    'userRole': UserRole;
}


/**
 * 
 * @export
 * @interface CreateJournalEntry
 */
export interface CreateJournalEntry {
    /**
     * 
     * @type {string}
     * @memberof CreateJournalEntry
     */
    'entry': string;
}
/**
 * 
 * @export
 * @interface CreateJournalEntryComment
 */
export interface CreateJournalEntryComment {
    /**
     * 
     * @type {string}
     * @memberof CreateJournalEntryComment
     */
    'comment': string;
    /**
     * 
     * @type {number}
     * @memberof CreateJournalEntryComment
     */
    'replyTo'?: number | null;
}
/**
 * 
 * @export
 * @interface CreateLiveMessage
 */
export interface CreateLiveMessage {
    /**
     * 
     * @type {string}
     * @memberof CreateLiveMessage
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface CreateLiveStream
 */
export interface CreateLiveStream {
    /**
     * 
     * @type {number}
     * @memberof CreateLiveStream
     */
    'gameId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateLiveStream
     */
    'name': string;
    /**
     * 
     * @type {TacticalPitch}
     * @memberof CreateLiveStream
     */
    'tacticalPitch': TacticalPitch;
    /**
     * 
     * @type {number}
     * @memberof CreateLiveStream
     */
    'teamId': number;
}
/**
 * 
 * @export
 * @interface CreateOrg
 */
export interface CreateOrg {
    /**
     * 
     * @type {string}
     * @memberof CreateOrg
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreatePlaylist
 */
export interface CreatePlaylist {
    /**
     * 
     * @type {Array<number>}
     * @memberof CreatePlaylist
     */
    'forzaTeamTags'?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePlaylist
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePlaylist
     */
    'playerTags'?: Array<string> | null;
    /**
     * 
     * @type {Array<CreatePlaylistSegment>}
     * @memberof CreatePlaylist
     */
    'segments'?: Array<CreatePlaylistSegment> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePlaylist
     */
    'sharedWith'?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreatePlaylist
     */
    'sharedWithGroups'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreatePlaylist
     */
    'tags'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof CreatePlaylist
     */
    'teamId': number;
    /**
     * 
     * @type {string}
     * @memberof CreatePlaylist
     */
    'thumbnailUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface CreatePlaylistExport
 */
export interface CreatePlaylistExport {
    /**
     * 
     * @type {boolean}
     * @memberof CreatePlaylistExport
     */
    'invert': boolean;
    /**
     * If not provided each segment will be exported as individual files with their respective names. If provided a single export will be created including all specified segments with the given name.
     * @type {string}
     * @memberof CreatePlaylistExport
     */
    'name'?: string | null;
    /**
     * 
     * @type {ImagePlacement}
     * @memberof CreatePlaylistExport
     */
    'placement': ImagePlacement;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreatePlaylistExport
     */
    'segments': Array<number>;
}


/**
 * 
 * @export
 * @interface CreatePlaylistSegment
 */
export interface CreatePlaylistSegment {
    /**
     * 
     * @type {string}
     * @memberof CreatePlaylistSegment
     */
    'description'?: string | null;
    /**
     * 
     * @type {{ [key: string]: Array<Shape>; }}
     * @memberof CreatePlaylistSegment
     */
    'drawings'?: { [key: string]: Array<Shape>; } | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePlaylistSegment
     */
    'favourite': boolean;
    /**
     * 
     * @type {Array<FootballFieldGridIdx>}
     * @memberof CreatePlaylistSegment
     */
    'footballFieldGrids'?: Array<FootballFieldGridIdx> | null;
    /**
     * 
     * @type {number}
     * @memberof CreatePlaylistSegment
     */
    'footballFieldLeftTeamId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreatePlaylistSegment
     */
    'footballFieldRightTeamId'?: number | null;
    /**
     * 
     * @type {Array<FootballFieldGridIdx>}
     * @memberof CreatePlaylistSegment
     */
    'footballFieldSequenceGrids'?: Array<FootballFieldGridIdx> | null;
    /**
     * 
     * @type {number}
     * @memberof CreatePlaylistSegment
     */
    'forzaGameId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreatePlaylistSegment
     */
    'forzaTeamTags'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof CreatePlaylistSegment
     */
    'fromTimestamp': number;
    /**
     * 
     * @type {{ [key: string]: HomographyResult; }}
     * @memberof CreatePlaylistSegment
     */
    'homographyFrames'?: { [key: string]: HomographyResult; } | null;
    /**
     * 
     * @type {number}
     * @memberof CreatePlaylistSegment
     */
    'id'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePlaylistSegment
     */
    'playerTags'?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreatePlaylistSegment
     */
    'tags'?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePlaylistSegment
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof CreatePlaylistSegment
     */
    'toTimestamp': number;
    /**
     * 
     * @type {string}
     * @memberof CreatePlaylistSegment
     */
    'userId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreatePlaylistSegment
     */
    'videoAssetId': number;
}
/**
 * 
 * @export
 * @interface CreatePlaylistSegmentSubTag
 */
export interface CreatePlaylistSegmentSubTag {
    /**
     * 
     * @type {number}
     * @memberof CreatePlaylistSegmentSubTag
     */
    'endOffset'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePlaylistSegmentSubTag
     */
    'favourite': boolean;
    /**
     * 
     * @type {Keybind}
     * @memberof CreatePlaylistSegmentSubTag
     */
    'keybind'?: Keybind | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePlaylistSegmentSubTag
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreatePlaylistSegmentSubTag
     */
    'startOffset'?: number | null;
}
/**
 * 
 * @export
 * @interface CreatePlaylistSegmentTag
 */
export interface CreatePlaylistSegmentTag {
    /**
     * 
     * @type {number}
     * @memberof CreatePlaylistSegmentTag
     */
    'endOffset'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePlaylistSegmentTag
     */
    'favourite': boolean;
    /**
     * 
     * @type {Keybind}
     * @memberof CreatePlaylistSegmentTag
     */
    'keybind'?: Keybind | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePlaylistSegmentTag
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreatePlaylistSegmentTag
     */
    'startOffset'?: number | null;
    /**
     * 
     * @type {Array<CreatePlaylistSegmentSubTag>}
     * @memberof CreatePlaylistSegmentTag
     */
    'subTags'?: Array<CreatePlaylistSegmentSubTag> | null;
}
/**
 * 
 * @export
 * @interface CreatePlaylistTag
 */
export interface CreatePlaylistTag {
    /**
     * 
     * @type {string}
     * @memberof CreatePlaylistTag
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateTeam
 */
export interface CreateTeam {
    /**
     * 
     * @type {string}
     * @memberof CreateTeam
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateUser
 */
export interface CreateUser {
    /**
     * 
     * @type {string}
     * @memberof CreateUser
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUser
     */
    'inviteId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUser
     */
    'lastName': string;
    /**
     * 
     * @type {UserRole}
     * @memberof CreateUser
     */
    'userRole'?: UserRole | null;
}
/**
 * 
 * @export
 * @interface Ellipse
 */
export interface Ellipse {
    /**
     * 
     * @type {number}
     * @memberof Ellipse
     */
    'offsetX': number;
    /**
     * 
     * @type {number}
     * @memberof Ellipse
     */
    'offsetY': number;
    /**
     * 
     * @type {number}
     * @memberof Ellipse
     */
    'radiusX': number;
    /**
     * 
     * @type {number}
     * @memberof Ellipse
     */
    'radiusY': number;
    /**
     * 
     * @type {number}
     * @memberof Ellipse
     */
    'rotation': number;
    /**
     * 
     * @type {number}
     * @memberof Ellipse
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof Ellipse
     */
    'y': number;
    /**
     * 
     * @type {string}
     * @memberof Ellipse
     */
    'fill': string;
    /**
     * 
     * @type {string}
     * @memberof Ellipse
     */
    'stroke'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Ellipse
     */
    'strokeWidth'?: number | null;
}
/**
 * 
 * @export
 * @interface EllipseAllOf
 */
export interface EllipseAllOf {
    /**
     * 
     * @type {string}
     * @memberof EllipseAllOf
     */
    'fill': string;
    /**
     * 
     * @type {string}
     * @memberof EllipseAllOf
     */
    'stroke'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EllipseAllOf
     */
    'strokeWidth'?: number | null;
}
/**
 * 
 * @export
 * @interface EllipseMinimal
 */
export interface EllipseMinimal {
    /**
     * 
     * @type {number}
     * @memberof EllipseMinimal
     */
    'offsetX': number;
    /**
     * 
     * @type {number}
     * @memberof EllipseMinimal
     */
    'offsetY': number;
    /**
     * 
     * @type {number}
     * @memberof EllipseMinimal
     */
    'radiusX': number;
    /**
     * 
     * @type {number}
     * @memberof EllipseMinimal
     */
    'radiusY': number;
    /**
     * 
     * @type {number}
     * @memberof EllipseMinimal
     */
    'rotation': number;
    /**
     * 
     * @type {number}
     * @memberof EllipseMinimal
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof EllipseMinimal
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface FootballFieldGridIdx
 */
export interface FootballFieldGridIdx {
    /**
     * 
     * @type {number}
     * @memberof FootballFieldGridIdx
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof FootballFieldGridIdx
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface ForzaEvent
 */
export interface ForzaEvent {
    /**
     * 
     * @type {string}
     * @memberof ForzaEvent
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof ForzaEvent
     */
    'forzaGameId': number;
    /**
     * 
     * @type {number}
     * @memberof ForzaEvent
     */
    'fromTimestamp': number;
    /**
     * 
     * @type {number}
     * @memberof ForzaEvent
     */
    'homeTeamId': number;
    /**
     * 
     * @type {number}
     * @memberof ForzaEvent
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ForzaEvent
     */
    'recordingTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof ForzaEvent
     */
    'thumbnailUrl': string;
    /**
     * 
     * @type {number}
     * @memberof ForzaEvent
     */
    'toTimestamp': number;
    /**
     * 
     * @type {number}
     * @memberof ForzaEvent
     */
    'videoAssetId': number;
    /**
     * 
     * @type {string}
     * @memberof ForzaEvent
     */
    'videoBaseUrl': string;
    /**
     * 
     * @type {number}
     * @memberof ForzaEvent
     */
    'visitingTeamId': number;
    /**
     * 
     * @type {number}
     * @memberof ForzaEvent
     */
    'wallClockTimeSeconds'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ForzaEventType = {
    Goal: 'Goal',
    Penalty: 'Penalty',
    RedCard: 'RedCard',
    YellowCard: 'YellowCard',
    Shot: 'Shot',
    Corner: 'Corner',
    Offside: 'Offside',
    Freekick: 'Freekick',
    GamePhase: 'GamePhase'
} as const;

export type ForzaEventType = typeof ForzaEventType[keyof typeof ForzaEventType];


/**
 * 
 * @export
 * @interface ForzaEvents
 */
export interface ForzaEvents {
    /**
     * 
     * @type {number}
     * @memberof ForzaEvents
     */
    'total': number;
    /**
     * 
     * @type {Array<ForzaEvent>}
     * @memberof ForzaEvents
     */
    'values': Array<ForzaEvent>;
}
/**
 * 
 * @export
 * @interface ForzaGame
 */
export interface ForzaGame {
    /**
     * 
     * @type {string}
     * @memberof ForzaGame
     */
    'date': string;
    /**
     * 
     * @type {Array<ForzaEvent>}
     * @memberof ForzaGame
     */
    'events': Array<ForzaEvent>;
    /**
     * 
     * @type {boolean}
     * @memberof ForzaGame
     */
    'hasPanorama': boolean;
    /**
     * 
     * @type {number}
     * @memberof ForzaGame
     */
    'homeTeamId': number;
    /**
     * 
     * @type {number}
     * @memberof ForzaGame
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof ForzaGame
     */
    'isLive': boolean;
    /**
     * 
     * @type {Array<User>}
     * @memberof ForzaGame
     */
    'playlistCreators': Array<User>;
    /**
     * 
     * @type {Array<ForzaVideoAsset>}
     * @memberof ForzaGame
     */
    'videoAssets': Array<ForzaVideoAsset>;
    /**
     * 
     * @type {number}
     * @memberof ForzaGame
     */
    'visitingTeamId': number;
}
/**
 * 
 * @export
 * @interface ForzaGameMinimal
 */
export interface ForzaGameMinimal {
    /**
     * 
     * @type {string}
     * @memberof ForzaGameMinimal
     */
    'date': string;
    /**
     * 
     * @type {boolean}
     * @memberof ForzaGameMinimal
     */
    'hasPanorama': boolean;
    /**
     * 
     * @type {number}
     * @memberof ForzaGameMinimal
     */
    'homeTeamId': number;
    /**
     * 
     * @type {number}
     * @memberof ForzaGameMinimal
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof ForzaGameMinimal
     */
    'isLive': boolean;
    /**
     * 
     * @type {Array<ForzaVideoAsset>}
     * @memberof ForzaGameMinimal
     */
    'videoAssets': Array<ForzaVideoAsset>;
    /**
     * 
     * @type {number}
     * @memberof ForzaGameMinimal
     */
    'visitingTeamId': number;
}
/**
 * 
 * @export
 * @interface ForzaGames
 */
export interface ForzaGames {
    /**
     * 
     * @type {number}
     * @memberof ForzaGames
     */
    'total': number;
    /**
     * 
     * @type {Array<ForzaGame>}
     * @memberof ForzaGames
     */
    'values': Array<ForzaGame>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ForzaGoalType = {
    Standard: 'Standard',
    Header: 'Header',
    OwnGoal: 'OwnGoal',
    Penalty: 'Penalty',
    Freekick: 'Freekick',
    Corner: 'Corner'
} as const;

export type ForzaGoalType = typeof ForzaGoalType[keyof typeof ForzaGoalType];


/**
 * 
 * @export
 * @interface ForzaPlayer
 */
export interface ForzaPlayer {
    /**
     * 
     * @type {number}
     * @memberof ForzaPlayer
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ForzaPlayer
     */
    'name': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ForzaRedCardType = {
    ViolentConduct: 'ViolentConduct',
    SecondYellow: 'SecondYellow',
    DenyingGoalChance: 'DenyingGoalChance'
} as const;

export type ForzaRedCardType = typeof ForzaRedCardType[keyof typeof ForzaRedCardType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ForzaSetPiece = {
    Corner: 'Corner',
    Penalty: 'Penalty',
    ThrowIn: 'ThrowIn',
    Freekick: 'Freekick'
} as const;

export type ForzaSetPiece = typeof ForzaSetPiece[keyof typeof ForzaSetPiece];


/**
 * 
 * @export
 * @enum {string}
 */

export const ForzaShotResult = {
    Wide: 'Wide',
    Saved: 'Saved',
    Blocked: 'Blocked',
    Post: 'Post',
    Bar: 'Bar'
} as const;

export type ForzaShotResult = typeof ForzaShotResult[keyof typeof ForzaShotResult];


/**
 * 
 * @export
 * @enum {string}
 */

export const ForzaShotType = {
    Standard: 'Standard',
    Header: 'Header',
    Penalty: 'Penalty',
    Freekick: 'Freekick'
} as const;

export type ForzaShotType = typeof ForzaShotType[keyof typeof ForzaShotType];


/**
 * 
 * @export
 * @interface ForzaTeam
 */
export interface ForzaTeam {
    /**
     * 
     * @type {string}
     * @memberof ForzaTeam
     */
    'coach'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ForzaTeam
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ForzaTeam
     */
    'name': string;
    /**
     * 
     * @type {Array<ForzaPlayer>}
     * @memberof ForzaTeam
     */
    'players': Array<ForzaPlayer>;
    /**
     * 
     * @type {string}
     * @memberof ForzaTeam
     */
    'shortName'?: string | null;
}
/**
 * 
 * @export
 * @interface ForzaVideoAsset
 */
export interface ForzaVideoAsset {
    /**
     * 
     * @type {number}
     * @memberof ForzaVideoAsset
     */
    'firstHalfZeroOffsetWallClockTime'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ForzaVideoAsset
     */
    'isLive': boolean;
    /**
     * 
     * @type {number}
     * @memberof ForzaVideoAsset
     */
    'secondHalfZeroOffsetWallClockTime'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ForzaVideoAsset
     */
    'videoAssetId': number;
    /**
     * 
     * @type {string}
     * @memberof ForzaVideoAsset
     */
    'videoBaseUrl': string;
}
/**
 * 
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * 
     * @type {number}
     * @memberof Group
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'name': string;
    /**
     * 
     * @type {Array<User>}
     * @memberof Group
     */
    'users': Array<User>;
}
/**
 * 
 * @export
 * @interface Homography
 */
export interface Homography {
    /**
     * 
     * @type {string}
     * @memberof Homography
     */
    'image': string;
    /**
     * 
     * @type {VideoOffset}
     * @memberof Homography
     */
    'videoOffset'?: VideoOffset | null;
}
/**
 * 
 * @export
 * @interface HomographyBall
 */
export interface HomographyBall {
    /**
     * 
     * @type {number}
     * @memberof HomographyBall
     */
    'xPercentage': number;
    /**
     * 
     * @type {number}
     * @memberof HomographyBall
     */
    'yPercentage': number;
}
/**
 * 
 * @export
 * @interface HomographyFrame
 */
export interface HomographyFrame {
    /**
     * 
     * @type {HomographyBall}
     * @memberof HomographyFrame
     */
    'ball'?: HomographyBall | null;
    /**
     * 
     * @type {Array<HomographyPlayer>}
     * @memberof HomographyFrame
     */
    'players': Array<HomographyPlayer>;
    /**
     * 
     * @type {number}
     * @memberof HomographyFrame
     */
    'videoAssetId': number;
    /**
     * 
     * @type {number}
     * @memberof HomographyFrame
     */
    'videoOffsetMs': number;
}
/**
 * 
 * @export
 * @interface HomographyFrameAllOf
 */
export interface HomographyFrameAllOf {
    /**
     * 
     * @type {number}
     * @memberof HomographyFrameAllOf
     */
    'videoAssetId': number;
    /**
     * 
     * @type {number}
     * @memberof HomographyFrameAllOf
     */
    'videoOffsetMs': number;
}
/**
 * 
 * @export
 * @interface HomographyPlayer
 */
export interface HomographyPlayer {
    /**
     * 
     * @type {PlayerTeam}
     * @memberof HomographyPlayer
     */
    'team'?: PlayerTeam | null;
    /**
     * 
     * @type {number}
     * @memberof HomographyPlayer
     */
    'xPercentage': number;
    /**
     * 
     * @type {number}
     * @memberof HomographyPlayer
     */
    'yPercentage': number;
}
/**
 * 
 * @export
 * @interface HomographyResult
 */
export interface HomographyResult {
    /**
     * 
     * @type {number}
     * @memberof HomographyResult
     */
    'ballIdx'?: number | null;
    /**
     * 
     * @type {Array<HomographyPlayer>}
     * @memberof HomographyResult
     */
    'players': Array<HomographyPlayer>;
}
/**
 * 
 * @export
 * @interface HomographyResultBallPosition
 */
export interface HomographyResultBallPosition {
    /**
     * 
     * @type {HomographyBall}
     * @memberof HomographyResultBallPosition
     */
    'ball'?: HomographyBall | null;
    /**
     * 
     * @type {Array<HomographyPlayer>}
     * @memberof HomographyResultBallPosition
     */
    'players': Array<HomographyPlayer>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ImagePlacement = {
    TopLeft: 'TopLeft',
    TopRight: 'TopRight',
    BottomLeft: 'BottomLeft',
    BottomRight: 'BottomRight'
} as const;

export type ImagePlacement = typeof ImagePlacement[keyof typeof ImagePlacement];


/**
 * 
 * @export
 * @interface Invite
 */
export interface Invite {
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof Invite
     */
    'orgId': number;
    /**
     * 
     * @type {number}
     * @memberof Invite
     */
    'teamId'?: number | null;
    /**
     * 
     * @type {UserRole}
     * @memberof Invite
     */
    'userRole': UserRole;
}


/**
 * 
 * @export
 * @interface JournalEntry
 */
export interface JournalEntry {
    /**
     * 
     * @type {Array<JournalEntryComment>}
     * @memberof JournalEntry
     */
    'comments': Array<JournalEntryComment>;
    /**
     * 
     * @type {string}
     * @memberof JournalEntry
     */
    'created': string;
    /**
     * 
     * @type {User}
     * @memberof JournalEntry
     */
    'creator'?: User | null;
    /**
     * 
     * @type {string}
     * @memberof JournalEntry
     */
    'entry': string;
    /**
     * 
     * @type {number}
     * @memberof JournalEntry
     */
    'id': number;
    /**
     * 
     * @type {User}
     * @memberof JournalEntry
     */
    'player': User;
    /**
     * 
     * @type {string}
     * @memberof JournalEntry
     */
    'updated': string;
}
/**
 * 
 * @export
 * @interface JournalEntryComment
 */
export interface JournalEntryComment {
    /**
     * 
     * @type {string}
     * @memberof JournalEntryComment
     */
    'comment': string;
    /**
     * 
     * @type {Array<JournalEntryComment>}
     * @memberof JournalEntryComment
     */
    'comments': Array<JournalEntryComment>;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryComment
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryComment
     */
    'deleted'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof JournalEntryComment
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryComment
     */
    'updated': string;
    /**
     * 
     * @type {User}
     * @memberof JournalEntryComment
     */
    'user'?: User | null;
}
/**
 * 
 * @export
 * @interface Keybind
 */
export interface Keybind {
    /**
     * 
     * @type {string}
     * @memberof Keybind
     */
    'char': string;
    /**
     * 
     * @type {boolean}
     * @memberof Keybind
     */
    'ctrl': boolean;
}
/**
 * 
 * @export
 * @interface LiveMessage
 */
export interface LiveMessage {
    /**
     * 
     * @type {number}
     * @memberof LiveMessage
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof LiveMessage
     */
    'liveStreamId': number;
    /**
     * 
     * @type {string}
     * @memberof LiveMessage
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof LiveMessage
     */
    'timestamp': string;
    /**
     * 
     * @type {User}
     * @memberof LiveMessage
     */
    'user'?: User | null;
}
/**
 * 
 * @export
 * @interface LiveStream
 */
export interface LiveStream {
    /**
     * 
     * @type {ForzaGameMinimal}
     * @memberof LiveStream
     */
    'game': ForzaGameMinimal;
    /**
     * 
     * @type {number}
     * @memberof LiveStream
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof LiveStream
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof LiveStream
     */
    'teamId': number;
}
/**
 * 
 * @export
 * @interface LiveStreamDetailed
 */
export interface LiveStreamDetailed {
    /**
     * 
     * @type {Array<LiveStreamUser>}
     * @memberof LiveStreamDetailed
     */
    'activeUsers': Array<LiveStreamUser>;
    /**
     * 
     * @type {number}
     * @memberof LiveStreamDetailed
     */
    'channelId'?: number | null;
    /**
     * 
     * @type {ForzaGameMinimal}
     * @memberof LiveStreamDetailed
     */
    'game': ForzaGameMinimal;
    /**
     * 
     * @type {number}
     * @memberof LiveStreamDetailed
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamDetailed
     */
    'isLive': boolean;
    /**
     * 
     * @type {Array<LiveMessage>}
     * @memberof LiveStreamDetailed
     */
    'messages': Array<LiveMessage>;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamDetailed
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof LiveStreamDetailed
     */
    'playlistId': number;
    /**
     * 
     * @type {Array<PlaylistSegment>}
     * @memberof LiveStreamDetailed
     */
    'segments': Array<PlaylistSegment>;
    /**
     * 
     * @type {TacticalPitch}
     * @memberof LiveStreamDetailed
     */
    'tacticalPitch': TacticalPitch;
    /**
     * 
     * @type {number}
     * @memberof LiveStreamDetailed
     */
    'teamId': number;
}
/**
 * @type LiveStreamEvent
 * @export
 */
export type LiveStreamEvent = LiveStreamEventOneOf | LiveStreamEventOneOf1 | LiveStreamEventOneOf10 | LiveStreamEventOneOf11 | LiveStreamEventOneOf2 | LiveStreamEventOneOf3 | LiveStreamEventOneOf4 | LiveStreamEventOneOf5 | LiveStreamEventOneOf6 | LiveStreamEventOneOf7 | LiveStreamEventOneOf8 | LiveStreamEventOneOf9;

/**
 * Auto-generated discriminant enum variants
 * @export
 * @enum {string}
 */

export const LiveStreamEventDiscriminants = {
    Initial: 'Initial',
    Archived: 'Archived',
    NewlyArchived: 'NewlyArchived',
    NewSegment: 'NewSegment',
    UpdateSegment: 'UpdateSegment',
    DeleteSegment: 'DeleteSegment',
    SendSegment: 'SendSegment',
    NewMessage: 'NewMessage',
    UserJoined: 'UserJoined',
    UserModeUpdated: 'UserModeUpdated',
    ActiveUsers: 'ActiveUsers',
    UpdateTacticalPitch: 'UpdateTacticalPitch'
} as const;

export type LiveStreamEventDiscriminants = typeof LiveStreamEventDiscriminants[keyof typeof LiveStreamEventDiscriminants];


/**
 * 
 * @export
 * @interface LiveStreamEventOneOf
 */
export interface LiveStreamEventOneOf {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamEventOneOf
     */
    'type': LiveStreamEventOneOfTypeEnum;
    /**
     * 
     * @type {LiveStreamDetailed}
     * @memberof LiveStreamEventOneOf
     */
    'value': LiveStreamDetailed;
}

export const LiveStreamEventOneOfTypeEnum = {
    Initial: 'Initial'
} as const;

export type LiveStreamEventOneOfTypeEnum = typeof LiveStreamEventOneOfTypeEnum[keyof typeof LiveStreamEventOneOfTypeEnum];

/**
 * 
 * @export
 * @interface LiveStreamEventOneOf1
 */
export interface LiveStreamEventOneOf1 {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamEventOneOf1
     */
    'type': LiveStreamEventOneOf1TypeEnum;
    /**
     * 
     * @type {LiveStreamDetailed}
     * @memberof LiveStreamEventOneOf1
     */
    'value': LiveStreamDetailed;
}

export const LiveStreamEventOneOf1TypeEnum = {
    Archived: 'Archived'
} as const;

export type LiveStreamEventOneOf1TypeEnum = typeof LiveStreamEventOneOf1TypeEnum[keyof typeof LiveStreamEventOneOf1TypeEnum];

/**
 * 
 * @export
 * @interface LiveStreamEventOneOf10
 */
export interface LiveStreamEventOneOf10 {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamEventOneOf10
     */
    'type': LiveStreamEventOneOf10TypeEnum;
    /**
     * 
     * @type {Array<LiveStreamUser>}
     * @memberof LiveStreamEventOneOf10
     */
    'value': Array<LiveStreamUser>;
}

export const LiveStreamEventOneOf10TypeEnum = {
    ActiveUsers: 'ActiveUsers'
} as const;

export type LiveStreamEventOneOf10TypeEnum = typeof LiveStreamEventOneOf10TypeEnum[keyof typeof LiveStreamEventOneOf10TypeEnum];

/**
 * 
 * @export
 * @interface LiveStreamEventOneOf11
 */
export interface LiveStreamEventOneOf11 {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamEventOneOf11
     */
    'type': LiveStreamEventOneOf11TypeEnum;
    /**
     * 
     * @type {TacticalPitch}
     * @memberof LiveStreamEventOneOf11
     */
    'value': TacticalPitch;
}

export const LiveStreamEventOneOf11TypeEnum = {
    UpdateTacticalPitch: 'UpdateTacticalPitch'
} as const;

export type LiveStreamEventOneOf11TypeEnum = typeof LiveStreamEventOneOf11TypeEnum[keyof typeof LiveStreamEventOneOf11TypeEnum];

/**
 * 
 * @export
 * @interface LiveStreamEventOneOf2
 */
export interface LiveStreamEventOneOf2 {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamEventOneOf2
     */
    'type': LiveStreamEventOneOf2TypeEnum;
}

export const LiveStreamEventOneOf2TypeEnum = {
    NewlyArchived: 'NewlyArchived'
} as const;

export type LiveStreamEventOneOf2TypeEnum = typeof LiveStreamEventOneOf2TypeEnum[keyof typeof LiveStreamEventOneOf2TypeEnum];

/**
 * 
 * @export
 * @interface LiveStreamEventOneOf3
 */
export interface LiveStreamEventOneOf3 {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamEventOneOf3
     */
    'type': LiveStreamEventOneOf3TypeEnum;
    /**
     * 
     * @type {PlaylistSegment}
     * @memberof LiveStreamEventOneOf3
     */
    'value': PlaylistSegment;
}

export const LiveStreamEventOneOf3TypeEnum = {
    NewSegment: 'NewSegment'
} as const;

export type LiveStreamEventOneOf3TypeEnum = typeof LiveStreamEventOneOf3TypeEnum[keyof typeof LiveStreamEventOneOf3TypeEnum];

/**
 * 
 * @export
 * @interface LiveStreamEventOneOf4
 */
export interface LiveStreamEventOneOf4 {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamEventOneOf4
     */
    'type': LiveStreamEventOneOf4TypeEnum;
    /**
     * 
     * @type {PlaylistSegment}
     * @memberof LiveStreamEventOneOf4
     */
    'value': PlaylistSegment;
}

export const LiveStreamEventOneOf4TypeEnum = {
    UpdateSegment: 'UpdateSegment'
} as const;

export type LiveStreamEventOneOf4TypeEnum = typeof LiveStreamEventOneOf4TypeEnum[keyof typeof LiveStreamEventOneOf4TypeEnum];

/**
 * 
 * @export
 * @interface LiveStreamEventOneOf5
 */
export interface LiveStreamEventOneOf5 {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamEventOneOf5
     */
    'type': LiveStreamEventOneOf5TypeEnum;
    /**
     * 
     * @type {LiveStreamEventOneOf5Value}
     * @memberof LiveStreamEventOneOf5
     */
    'value': LiveStreamEventOneOf5Value;
}

export const LiveStreamEventOneOf5TypeEnum = {
    DeleteSegment: 'DeleteSegment'
} as const;

export type LiveStreamEventOneOf5TypeEnum = typeof LiveStreamEventOneOf5TypeEnum[keyof typeof LiveStreamEventOneOf5TypeEnum];

/**
 * 
 * @export
 * @interface LiveStreamEventOneOf5Value
 */
export interface LiveStreamEventOneOf5Value {
    /**
     * 
     * @type {number}
     * @memberof LiveStreamEventOneOf5Value
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface LiveStreamEventOneOf6
 */
export interface LiveStreamEventOneOf6 {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamEventOneOf6
     */
    'type': LiveStreamEventOneOf6TypeEnum;
    /**
     * 
     * @type {LiveStreamEventOneOf6Value}
     * @memberof LiveStreamEventOneOf6
     */
    'value': LiveStreamEventOneOf6Value;
}

export const LiveStreamEventOneOf6TypeEnum = {
    SendSegment: 'SendSegment'
} as const;

export type LiveStreamEventOneOf6TypeEnum = typeof LiveStreamEventOneOf6TypeEnum[keyof typeof LiveStreamEventOneOf6TypeEnum];

/**
 * 
 * @export
 * @interface LiveStreamEventOneOf6Value
 */
export interface LiveStreamEventOneOf6Value {
    /**
     * 
     * @type {number}
     * @memberof LiveStreamEventOneOf6Value
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamEventOneOf6Value
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface LiveStreamEventOneOf7
 */
export interface LiveStreamEventOneOf7 {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamEventOneOf7
     */
    'type': LiveStreamEventOneOf7TypeEnum;
    /**
     * 
     * @type {LiveMessage}
     * @memberof LiveStreamEventOneOf7
     */
    'value': LiveMessage;
}

export const LiveStreamEventOneOf7TypeEnum = {
    NewMessage: 'NewMessage'
} as const;

export type LiveStreamEventOneOf7TypeEnum = typeof LiveStreamEventOneOf7TypeEnum[keyof typeof LiveStreamEventOneOf7TypeEnum];

/**
 * 
 * @export
 * @interface LiveStreamEventOneOf8
 */
export interface LiveStreamEventOneOf8 {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamEventOneOf8
     */
    'type': LiveStreamEventOneOf8TypeEnum;
    /**
     * 
     * @type {LiveStreamUser}
     * @memberof LiveStreamEventOneOf8
     */
    'value': LiveStreamUser;
}

export const LiveStreamEventOneOf8TypeEnum = {
    UserJoined: 'UserJoined'
} as const;

export type LiveStreamEventOneOf8TypeEnum = typeof LiveStreamEventOneOf8TypeEnum[keyof typeof LiveStreamEventOneOf8TypeEnum];

/**
 * 
 * @export
 * @interface LiveStreamEventOneOf9
 */
export interface LiveStreamEventOneOf9 {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamEventOneOf9
     */
    'type': LiveStreamEventOneOf9TypeEnum;
    /**
     * 
     * @type {LiveStreamUser}
     * @memberof LiveStreamEventOneOf9
     */
    'value': LiveStreamUser;
}

export const LiveStreamEventOneOf9TypeEnum = {
    UserModeUpdated: 'UserModeUpdated'
} as const;

export type LiveStreamEventOneOf9TypeEnum = typeof LiveStreamEventOneOf9TypeEnum[keyof typeof LiveStreamEventOneOf9TypeEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const LiveStreamMode = {
    Normal: 'Normal',
    Focus: 'Focus'
} as const;

export type LiveStreamMode = typeof LiveStreamMode[keyof typeof LiveStreamMode];


/**
 * 
 * @export
 * @interface LiveStreamUser
 */
export interface LiveStreamUser {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamUser
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamUser
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamUser
     */
    'lastName': string;
    /**
     * 
     * @type {Array<LiveStreamMode>}
     * @memberof LiveStreamUser
     */
    'modes': Array<LiveStreamMode>;
}
/**
 * 
 * @export
 * @interface MinimalGroup
 */
export interface MinimalGroup {
    /**
     * 
     * @type {number}
     * @memberof MinimalGroup
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MinimalGroup
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface MinimalPlaylist
 */
export interface MinimalPlaylist {
    /**
     * 
     * @type {string}
     * @memberof MinimalPlaylist
     */
    'created': string;
    /**
     * 
     * @type {MinimalUser}
     * @memberof MinimalPlaylist
     */
    'creator'?: MinimalUser | null;
    /**
     * 
     * @type {number}
     * @memberof MinimalPlaylist
     */
    'durationMs': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof MinimalPlaylist
     */
    'forzaTeamTags': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof MinimalPlaylist
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MinimalPlaylist
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof MinimalPlaylist
     */
    'numSegments': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof MinimalPlaylist
     */
    'playerTags': Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MinimalPlaylist
     */
    'tags': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof MinimalPlaylist
     */
    'teamId': number;
    /**
     * 
     * @type {string}
     * @memberof MinimalPlaylist
     */
    'thumbnailUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface MinimalPlaylists
 */
export interface MinimalPlaylists {
    /**
     * 
     * @type {number}
     * @memberof MinimalPlaylists
     */
    'total': number;
    /**
     * 
     * @type {Array<MinimalPlaylist>}
     * @memberof MinimalPlaylists
     */
    'values': Array<MinimalPlaylist>;
}
/**
 * 
 * @export
 * @interface MinimalUser
 */
export interface MinimalUser {
    /**
     * 
     * @type {string}
     * @memberof MinimalUser
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof MinimalUser
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MinimalUser
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface Org
 */
export interface Org {
    /**
     * 
     * @type {number}
     * @memberof Org
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Org
     */
    'maxAdmins': number;
    /**
     * 
     * @type {number}
     * @memberof Org
     */
    'maxCoaches': number;
    /**
     * 
     * @type {number}
     * @memberof Org
     */
    'maxPlayers': number;
    /**
     * 
     * @type {number}
     * @memberof Org
     */
    'maxTeams': number;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface OrgDetailed
 */
export interface OrgDetailed {
    /**
     * 
     * @type {number}
     * @memberof OrgDetailed
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof OrgDetailed
     */
    'maxAdmins': number;
    /**
     * 
     * @type {number}
     * @memberof OrgDetailed
     */
    'maxCoaches': number;
    /**
     * 
     * @type {number}
     * @memberof OrgDetailed
     */
    'maxPlayers': number;
    /**
     * 
     * @type {number}
     * @memberof OrgDetailed
     */
    'maxTeams': number;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailed
     */
    'name': string;
    /**
     * 
     * @type {Array<User>}
     * @memberof OrgDetailed
     */
    'otherUsers': Array<User>;
    /**
     * 
     * @type {Array<TeamDetailed>}
     * @memberof OrgDetailed
     */
    'teams': Array<TeamDetailed>;
}
/**
 * 
 * @export
 * @interface Panorama
 */
export interface Panorama {
    /**
     * 
     * @type {number}
     * @memberof Panorama
     */
    'height': number;
    /**
     * 
     * @type {string}
     * @memberof Panorama
     */
    'image': string;
    /**
     * 
     * @type {number}
     * @memberof Panorama
     */
    'width': number;
}
/**
 * 
 * @export
 * @interface PanoramaOptionPath
 */
export interface PanoramaOptionPath {
    /**
     * 
     * @type {number}
     * @memberof PanoramaOptionPath
     */
    'forza_video_asset_id': number;
    /**
     * 
     * @type {number}
     * @memberof PanoramaOptionPath
     */
    'timestamp': number;
}
/**
 * 
 * @export
 * @interface PanoramaUpdate
 */
export interface PanoramaUpdate {
    /**
     * 
     * @type {string}
     * @memberof PanoramaUpdate
     */
    'image': string;
    /**
     * 
     * @type {Array<ReferencePoint>}
     * @memberof PanoramaUpdate
     */
    'referencePoints': Array<ReferencePoint>;
}
/**
 * 
 * @export
 * @interface PlayerBoundingBox
 */
export interface PlayerBoundingBox {
    /**
     * 
     * @type {number}
     * @memberof PlayerBoundingBox
     */
    'x1': number;
    /**
     * 
     * @type {number}
     * @memberof PlayerBoundingBox
     */
    'x2': number;
    /**
     * 
     * @type {number}
     * @memberof PlayerBoundingBox
     */
    'y1': number;
    /**
     * 
     * @type {number}
     * @memberof PlayerBoundingBox
     */
    'y2': number;
    /**
     * 
     * @type {PlayerTeam}
     * @memberof PlayerBoundingBox
     */
    'team'?: PlayerTeam | null;
}
/**
 * 
 * @export
 * @interface PlayerBoundingBoxAllOf
 */
export interface PlayerBoundingBoxAllOf {
    /**
     * 
     * @type {PlayerTeam}
     * @memberof PlayerBoundingBoxAllOf
     */
    'team'?: PlayerTeam | null;
}
/**
 * 
 * @export
 * @interface PlayerDetection
 */
export interface PlayerDetection {
    /**
     * 
     * @type {string}
     * @memberof PlayerDetection
     */
    'image': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PlayerTeam = {
    A: 'A',
    B: 'B'
} as const;

export type PlayerTeam = typeof PlayerTeam[keyof typeof PlayerTeam];


/**
 * 
 * @export
 * @interface Playlist
 */
export interface Playlist {
    /**
     * 
     * @type {string}
     * @memberof Playlist
     */
    'created': string;
    /**
     * 
     * @type {User}
     * @memberof Playlist
     */
    'creator'?: User | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof Playlist
     */
    'forzaTeamTags': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof Playlist
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Playlist
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Playlist
     */
    'playerTags': Array<string>;
    /**
     * 
     * @type {Array<PlaylistSegment>}
     * @memberof Playlist
     */
    'segments': Array<PlaylistSegment>;
    /**
     * 
     * @type {Array<User>}
     * @memberof Playlist
     */
    'sharedWith': Array<User>;
    /**
     * 
     * @type {Array<MinimalGroup>}
     * @memberof Playlist
     */
    'sharedWithGroups': Array<MinimalGroup>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Playlist
     */
    'tags': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof Playlist
     */
    'teamId': number;
    /**
     * 
     * @type {string}
     * @memberof Playlist
     */
    'thumbnailUrl'?: string | null;
    /**
     * 
     * @type {Array<PlaylistVideoInfo>}
     * @memberof Playlist
     */
    'videos': Array<PlaylistVideoInfo>;
}
/**
 * 
 * @export
 * @interface PlaylistSegment
 */
export interface PlaylistSegment {
    /**
     * 
     * @type {MinimalUser}
     * @memberof PlaylistSegment
     */
    'creator'?: MinimalUser | null;
    /**
     * 
     * @type {string}
     * @memberof PlaylistSegment
     */
    'description'?: string | null;
    /**
     * 
     * @type {{ [key: string]: Array<Shape>; }}
     * @memberof PlaylistSegment
     */
    'drawings': { [key: string]: Array<Shape>; };
    /**
     * 
     * @type {boolean}
     * @memberof PlaylistSegment
     */
    'favourite': boolean;
    /**
     * 
     * @type {Array<FootballFieldGridIdx>}
     * @memberof PlaylistSegment
     */
    'footballFieldGrids': Array<FootballFieldGridIdx>;
    /**
     * 
     * @type {number}
     * @memberof PlaylistSegment
     */
    'footballFieldLeftTeamId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlaylistSegment
     */
    'footballFieldRightTeamId'?: number | null;
    /**
     * 
     * @type {Array<FootballFieldGridIdx>}
     * @memberof PlaylistSegment
     */
    'footballFieldSequenceGrids': Array<FootballFieldGridIdx>;
    /**
     * 
     * @type {number}
     * @memberof PlaylistSegment
     */
    'forzaGameId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof PlaylistSegment
     */
    'forzaTeamTags': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof PlaylistSegment
     */
    'fromTimestamp': number;
    /**
     * 
     * @type {boolean}
     * @memberof PlaylistSegment
     */
    'gameHasPanorama': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlaylistSegment
     */
    'hasHomography': boolean;
    /**
     * 
     * @type {number}
     * @memberof PlaylistSegment
     */
    'homeTeamId': number;
    /**
     * 
     * @type {{ [key: string]: HomographyResult; }}
     * @memberof PlaylistSegment
     */
    'homographyFrames': { [key: string]: HomographyResult; };
    /**
     * 
     * @type {number}
     * @memberof PlaylistSegment
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PlaylistSegment
     */
    'manualKickoffTimeMs'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlaylistSegment
     */
    'playerTags': Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PlaylistSegment
     */
    'tags': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof PlaylistSegment
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof PlaylistSegment
     */
    'toTimestamp': number;
    /**
     * 
     * @type {number}
     * @memberof PlaylistSegment
     */
    'videoAssetId': number;
    /**
     * 
     * @type {string}
     * @memberof PlaylistSegment
     */
    'videoBaseUrl': string;
    /**
     * 
     * @type {number}
     * @memberof PlaylistSegment
     */
    'visitingTeamId': number;
    /**
     * 
     * @type {number}
     * @memberof PlaylistSegment
     */
    'wallClockTimeSeconds'?: number | null;
}
/**
 * 
 * @export
 * @interface PlaylistSegmentHomography
 */
export interface PlaylistSegmentHomography {
    /**
     * 
     * @type {Array<number>}
     * @memberof PlaylistSegmentHomography
     */
    'segmentIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface PlaylistSegmentSubTag
 */
export interface PlaylistSegmentSubTag {
    /**
     * 
     * @type {number}
     * @memberof PlaylistSegmentSubTag
     */
    'endOffset'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PlaylistSegmentSubTag
     */
    'favourite': boolean;
    /**
     * 
     * @type {number}
     * @memberof PlaylistSegmentSubTag
     */
    'id': number;
    /**
     * 
     * @type {Keybind}
     * @memberof PlaylistSegmentSubTag
     */
    'keybind'?: Keybind | null;
    /**
     * 
     * @type {string}
     * @memberof PlaylistSegmentSubTag
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PlaylistSegmentSubTag
     */
    'parentTagId': number;
    /**
     * 
     * @type {number}
     * @memberof PlaylistSegmentSubTag
     */
    'startOffset'?: number | null;
}
/**
 * 
 * @export
 * @interface PlaylistSegmentTag
 */
export interface PlaylistSegmentTag {
    /**
     * 
     * @type {number}
     * @memberof PlaylistSegmentTag
     */
    'endOffset'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PlaylistSegmentTag
     */
    'favourite': boolean;
    /**
     * 
     * @type {number}
     * @memberof PlaylistSegmentTag
     */
    'id': number;
    /**
     * 
     * @type {Keybind}
     * @memberof PlaylistSegmentTag
     */
    'keybind'?: Keybind | null;
    /**
     * 
     * @type {string}
     * @memberof PlaylistSegmentTag
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PlaylistSegmentTag
     */
    'startOffset'?: number | null;
    /**
     * 
     * @type {Array<PlaylistSegmentSubTag>}
     * @memberof PlaylistSegmentTag
     */
    'subTags': Array<PlaylistSegmentSubTag>;
}
/**
 * 
 * @export
 * @interface PlaylistSegments
 */
export interface PlaylistSegments {
    /**
     * 
     * @type {number}
     * @memberof PlaylistSegments
     */
    'total': number;
    /**
     * 
     * @type {Array<PlaylistSegment>}
     * @memberof PlaylistSegments
     */
    'values': Array<PlaylistSegment>;
}
/**
 * 
 * @export
 * @interface PlaylistTag
 */
export interface PlaylistTag {
    /**
     * 
     * @type {number}
     * @memberof PlaylistTag
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PlaylistTag
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PlaylistVideoInfo
 */
export interface PlaylistVideoInfo {
    /**
     * 
     * @type {string}
     * @memberof PlaylistVideoInfo
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof PlaylistVideoInfo
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PlaylistVideoInfo
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface Playlists
 */
export interface Playlists {
    /**
     * 
     * @type {number}
     * @memberof Playlists
     */
    'total': number;
    /**
     * 
     * @type {Array<Playlist>}
     * @memberof Playlists
     */
    'values': Array<Playlist>;
}
/**
 * 
 * @export
 * @interface Polygon
 */
export interface Polygon {
    /**
     * 
     * @type {string}
     * @memberof Polygon
     */
    'fill': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof Polygon
     */
    'points': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof Polygon
     */
    'stroke'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Polygon
     */
    'strokeWidth'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Position = {
    Goalkeeper: 'Goalkeeper',
    Defender: 'Defender',
    Fullback: 'Fullback',
    Midfielder: 'Midfielder',
    Winger: 'Winger',
    Striker: 'Striker'
} as const;

export type Position = typeof Position[keyof typeof Position];


/**
 * 
 * @export
 * @interface Rect
 */
export interface Rect {
    /**
     * 
     * @type {string}
     * @memberof Rect
     */
    'fill': string;
    /**
     * 
     * @type {number}
     * @memberof Rect
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof Rect
     */
    'rotation': number;
    /**
     * 
     * @type {string}
     * @memberof Rect
     */
    'stroke'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Rect
     */
    'strokeWidth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Rect
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof Rect
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof Rect
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface ReferencePoint
 */
export interface ReferencePoint {
    /**
     * 
     * @type {number}
     * @memberof ReferencePoint
     */
    'index': number;
    /**
     * 
     * @type {number}
     * @memberof ReferencePoint
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof ReferencePoint
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface SetGameKickoff
 */
export interface SetGameKickoff {
    /**
     * 
     * @type {number}
     * @memberof SetGameKickoff
     */
    'manualKickoffTimeMs'?: number | null;
}
/**
 * 
 * @export
 * @interface SetLiveStreamMode
 */
export interface SetLiveStreamMode {
    /**
     * 
     * @type {number}
     * @memberof SetLiveStreamMode
     */
    'channelId': number;
    /**
     * 
     * @type {LiveStreamMode}
     * @memberof SetLiveStreamMode
     */
    'mode': LiveStreamMode;
}


/**
 * 
 * @export
 * @interface SetLiveStreamThumbnail
 */
export interface SetLiveStreamThumbnail {
    /**
     * 
     * @type {string}
     * @memberof SetLiveStreamThumbnail
     */
    'thumbnail': string;
}
/**
 * 
 * @export
 * @interface SetTemplateReferencePoints
 */
export interface SetTemplateReferencePoints {
    /**
     * 
     * @type {Array<ReferencePoint>}
     * @memberof SetTemplateReferencePoints
     */
    'points': Array<ReferencePoint>;
}
/**
 * @type Shape
 * @export
 */
export type Shape = ShapeOneOf | ShapeOneOf1 | ShapeOneOf2 | ShapeOneOf3 | ShapeOneOf4 | ShapeOneOf5;

/**
 * Auto-generated discriminant enum variants
 * @export
 * @enum {string}
 */

export const ShapeDiscriminants = {
    Rect: 'Rect',
    Polygon: 'Polygon',
    Ellipse: 'Ellipse',
    Arrow: 'Arrow',
    Text: 'Text',
    ConnectedEllipses: 'ConnectedEllipses'
} as const;

export type ShapeDiscriminants = typeof ShapeDiscriminants[keyof typeof ShapeDiscriminants];


/**
 * 
 * @export
 * @interface ShapeOneOf
 */
export interface ShapeOneOf {
    /**
     * 
     * @type {string}
     * @memberof ShapeOneOf
     */
    'type': ShapeOneOfTypeEnum;
    /**
     * 
     * @type {Rect}
     * @memberof ShapeOneOf
     */
    'value': Rect;
}

export const ShapeOneOfTypeEnum = {
    Rect: 'Rect'
} as const;

export type ShapeOneOfTypeEnum = typeof ShapeOneOfTypeEnum[keyof typeof ShapeOneOfTypeEnum];

/**
 * 
 * @export
 * @interface ShapeOneOf1
 */
export interface ShapeOneOf1 {
    /**
     * 
     * @type {string}
     * @memberof ShapeOneOf1
     */
    'type': ShapeOneOf1TypeEnum;
    /**
     * 
     * @type {Polygon}
     * @memberof ShapeOneOf1
     */
    'value': Polygon;
}

export const ShapeOneOf1TypeEnum = {
    Polygon: 'Polygon'
} as const;

export type ShapeOneOf1TypeEnum = typeof ShapeOneOf1TypeEnum[keyof typeof ShapeOneOf1TypeEnum];

/**
 * 
 * @export
 * @interface ShapeOneOf2
 */
export interface ShapeOneOf2 {
    /**
     * 
     * @type {string}
     * @memberof ShapeOneOf2
     */
    'type': ShapeOneOf2TypeEnum;
    /**
     * 
     * @type {Ellipse}
     * @memberof ShapeOneOf2
     */
    'value': Ellipse;
}

export const ShapeOneOf2TypeEnum = {
    Ellipse: 'Ellipse'
} as const;

export type ShapeOneOf2TypeEnum = typeof ShapeOneOf2TypeEnum[keyof typeof ShapeOneOf2TypeEnum];

/**
 * 
 * @export
 * @interface ShapeOneOf3
 */
export interface ShapeOneOf3 {
    /**
     * 
     * @type {string}
     * @memberof ShapeOneOf3
     */
    'type': ShapeOneOf3TypeEnum;
    /**
     * 
     * @type {Arrow}
     * @memberof ShapeOneOf3
     */
    'value': Arrow;
}

export const ShapeOneOf3TypeEnum = {
    Arrow: 'Arrow'
} as const;

export type ShapeOneOf3TypeEnum = typeof ShapeOneOf3TypeEnum[keyof typeof ShapeOneOf3TypeEnum];

/**
 * 
 * @export
 * @interface ShapeOneOf4
 */
export interface ShapeOneOf4 {
    /**
     * 
     * @type {string}
     * @memberof ShapeOneOf4
     */
    'type': ShapeOneOf4TypeEnum;
    /**
     * 
     * @type {Text}
     * @memberof ShapeOneOf4
     */
    'value': Text;
}

export const ShapeOneOf4TypeEnum = {
    Text: 'Text'
} as const;

export type ShapeOneOf4TypeEnum = typeof ShapeOneOf4TypeEnum[keyof typeof ShapeOneOf4TypeEnum];

/**
 * 
 * @export
 * @interface ShapeOneOf5
 */
export interface ShapeOneOf5 {
    /**
     * 
     * @type {string}
     * @memberof ShapeOneOf5
     */
    'type': ShapeOneOf5TypeEnum;
    /**
     * 
     * @type {ConnectedEllipses}
     * @memberof ShapeOneOf5
     */
    'value': ConnectedEllipses;
}

export const ShapeOneOf5TypeEnum = {
    ConnectedEllipses: 'ConnectedEllipses'
} as const;

export type ShapeOneOf5TypeEnum = typeof ShapeOneOf5TypeEnum[keyof typeof ShapeOneOf5TypeEnum];

/**
 * 
 * @export
 * @interface StitchPanorama
 */
export interface StitchPanorama {
    /**
     * 
     * @type {Array<number>}
     * @memberof StitchPanorama
     */
    'timestamps': Array<number>;
}
/**
 * 
 * @export
 * @interface TacticalPitch
 */
export interface TacticalPitch {
    /**
     * 
     * @type {number}
     * @memberof TacticalPitch
     */
    'ballIdx'?: number | null;
    /**
     * 
     * @type {Array<TacticalPlayer>}
     * @memberof TacticalPitch
     */
    'players'?: Array<TacticalPlayer>;
}
/**
 * 
 * @export
 * @interface TacticalPlayer
 */
export interface TacticalPlayer {
    /**
     * 
     * @type {TacticalTeam}
     * @memberof TacticalPlayer
     */
    'team': TacticalTeam;
    /**
     * 
     * @type {number}
     * @memberof TacticalPlayer
     */
    'xPercentage': number;
    /**
     * 
     * @type {number}
     * @memberof TacticalPlayer
     */
    'yPercentage': number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TacticalTeam = {
    A: 'A',
    B: 'B'
} as const;

export type TacticalTeam = typeof TacticalTeam[keyof typeof TacticalTeam];


/**
 * 
 * @export
 * @interface Team
 */
export interface Team {
    /**
     * 
     * @type {number}
     * @memberof Team
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Team
     */
    'orgId': number;
}
/**
 * 
 * @export
 * @interface TeamDetailed
 */
export interface TeamDetailed {
    /**
     * 
     * @type {Array<User>}
     * @memberof TeamDetailed
     */
    'admins': Array<User>;
    /**
     * 
     * @type {Array<User>}
     * @memberof TeamDetailed
     */
    'coaches': Array<User>;
    /**
     * 
     * @type {Array<Group>}
     * @memberof TeamDetailed
     */
    'groups': Array<Group>;
    /**
     * 
     * @type {number}
     * @memberof TeamDetailed
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TeamDetailed
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof TeamDetailed
     */
    'orgId': number;
    /**
     * 
     * @type {Array<User>}
     * @memberof TeamDetailed
     */
    'players': Array<User>;
}
/**
 * 
 * @export
 * @interface Text
 */
export interface Text {
    /**
     * 
     * @type {string}
     * @memberof Text
     */
    'fill': string;
    /**
     * 
     * @type {number}
     * @memberof Text
     */
    'fontSize': number;
    /**
     * 
     * @type {number}
     * @memberof Text
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof Text
     */
    'rotation': number;
    /**
     * 
     * @type {string}
     * @memberof Text
     */
    'text': string;
    /**
     * 
     * @type {number}
     * @memberof Text
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof Text
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof Text
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface UpdateForzaGame
 */
export interface UpdateForzaGame {
    /**
     * 
     * @type {PanoramaUpdate}
     * @memberof UpdateForzaGame
     */
    'panorama'?: PanoramaUpdate | null;
}
/**
 * 
 * @export
 * @interface UpdateJournalEntry
 */
export interface UpdateJournalEntry {
    /**
     * 
     * @type {string}
     * @memberof UpdateJournalEntry
     */
    'entry': string;
}
/**
 * 
 * @export
 * @interface UpdateJournalEntryComment
 */
export interface UpdateJournalEntryComment {
    /**
     * 
     * @type {string}
     * @memberof UpdateJournalEntryComment
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface UpdatePlayer
 */
export interface UpdatePlayer {
    /**
     * 
     * @type {string}
     * @memberof UpdatePlayer
     */
    'birthday'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdatePlayer
     */
    'height'?: number | null;
    /**
     * 
     * @type {Position}
     * @memberof UpdatePlayer
     */
    'position'?: Position | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlayer
     */
    'profileImage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdatePlayer
     */
    'weight'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdatePlaylistSegmentSubTag
 */
export interface UpdatePlaylistSegmentSubTag {
    /**
     * 
     * @type {number}
     * @memberof UpdatePlaylistSegmentSubTag
     */
    'endOffset'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePlaylistSegmentSubTag
     */
    'favourite': boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdatePlaylistSegmentSubTag
     */
    'id'?: number | null;
    /**
     * 
     * @type {Keybind}
     * @memberof UpdatePlaylistSegmentSubTag
     */
    'keybind'?: Keybind | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlaylistSegmentSubTag
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UpdatePlaylistSegmentSubTag
     */
    'startOffset'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdatePlaylistSegmentTag
 */
export interface UpdatePlaylistSegmentTag {
    /**
     * 
     * @type {number}
     * @memberof UpdatePlaylistSegmentTag
     */
    'endOffset'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePlaylistSegmentTag
     */
    'favourite': boolean;
    /**
     * 
     * @type {Keybind}
     * @memberof UpdatePlaylistSegmentTag
     */
    'keybind'?: Keybind | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlaylistSegmentTag
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UpdatePlaylistSegmentTag
     */
    'startOffset'?: number | null;
    /**
     * 
     * @type {Array<UpdatePlaylistSegmentSubTag>}
     * @memberof UpdatePlaylistSegmentTag
     */
    'subTags'?: Array<UpdatePlaylistSegmentSubTag> | null;
}
/**
 * 
 * @export
 * @interface UpdateUser
 */
export interface UpdateUser {
    /**
     * 
     * @type {string}
     * @memberof UpdateUser
     */
    'birthday'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUser
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUser
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUser
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUser
     */
    'profileImage'?: string | null;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'birthday'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName': string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'height'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName': string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'orgId'?: number | null;
    /**
     * 
     * @type {Position}
     * @memberof User
     */
    'position'?: Position | null;
    /**
     * 
     * @type {UserRole}
     * @memberof User
     */
    'userRole': UserRole;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'weight'?: number | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const UserRole = {
    Admin: 'Admin',
    Coach: 'Coach',
    Player: 'Player'
} as const;

export type UserRole = typeof UserRole[keyof typeof UserRole];


/**
 * 
 * @export
 * @interface VgliveEvent
 */
export interface VgliveEvent {
    /**
     * 
     * @type {number}
     * @memberof VgliveEvent
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof VgliveEvent
     */
    'startTimestamp': string;
    /**
     * 
     * @type {VgliveTeam}
     * @memberof VgliveEvent
     */
    'teamHome': VgliveTeam;
    /**
     * 
     * @type {VgliveTeam}
     * @memberof VgliveEvent
     */
    'teamVisitor': VgliveTeam;
    /**
     * 
     * @type {number}
     * @memberof VgliveEvent
     */
    'vgliveTournamentId': number;
}
/**
 * 
 * @export
 * @interface VgliveTeam
 */
export interface VgliveTeam {
    /**
     * 
     * @type {number}
     * @memberof VgliveTeam
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof VgliveTeam
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof VgliveTeam
     */
    'shortName'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const VgliveTournament = {
    Eliteserien: 'Eliteserien'
} as const;

export type VgliveTournament = typeof VgliveTournament[keyof typeof VgliveTournament];


/**
 * @type VideoExportEvent
 * @export
 */
export type VideoExportEvent = VideoExportEventOneOf | VideoExportEventOneOf1 | VideoExportEventOneOf2 | VideoExportEventOneOf3 | VideoExportEventOneOf4 | VideoExportEventOneOf5;

/**
 * Auto-generated discriminant enum variants
 * @export
 * @enum {string}
 */

export const VideoExportEventDiscriminants = {
    Initial: 'Initial',
    NewVideos: 'NewVideos',
    Progress: 'Progress',
    StartingUpload: 'StartingUpload',
    Finished: 'Finished',
    Error: 'Error'
} as const;

export type VideoExportEventDiscriminants = typeof VideoExportEventDiscriminants[keyof typeof VideoExportEventDiscriminants];


/**
 * 
 * @export
 * @interface VideoExportEventOneOf
 */
export interface VideoExportEventOneOf {
    /**
     * 
     * @type {string}
     * @memberof VideoExportEventOneOf
     */
    'type': VideoExportEventOneOfTypeEnum;
    /**
     * 
     * @type {Array<PlaylistVideoInfo>}
     * @memberof VideoExportEventOneOf
     */
    'value': Array<PlaylistVideoInfo>;
}

export const VideoExportEventOneOfTypeEnum = {
    Initial: 'Initial'
} as const;

export type VideoExportEventOneOfTypeEnum = typeof VideoExportEventOneOfTypeEnum[keyof typeof VideoExportEventOneOfTypeEnum];

/**
 * 
 * @export
 * @interface VideoExportEventOneOf1
 */
export interface VideoExportEventOneOf1 {
    /**
     * 
     * @type {string}
     * @memberof VideoExportEventOneOf1
     */
    'type': VideoExportEventOneOf1TypeEnum;
    /**
     * 
     * @type {Array<PlaylistVideoInfo>}
     * @memberof VideoExportEventOneOf1
     */
    'value': Array<PlaylistVideoInfo>;
}

export const VideoExportEventOneOf1TypeEnum = {
    NewVideos: 'NewVideos'
} as const;

export type VideoExportEventOneOf1TypeEnum = typeof VideoExportEventOneOf1TypeEnum[keyof typeof VideoExportEventOneOf1TypeEnum];

/**
 * 
 * @export
 * @interface VideoExportEventOneOf2
 */
export interface VideoExportEventOneOf2 {
    /**
     * 
     * @type {string}
     * @memberof VideoExportEventOneOf2
     */
    'type': VideoExportEventOneOf2TypeEnum;
    /**
     * 
     * @type {VideoExportEventOneOf2Value}
     * @memberof VideoExportEventOneOf2
     */
    'value': VideoExportEventOneOf2Value;
}

export const VideoExportEventOneOf2TypeEnum = {
    Progress: 'Progress'
} as const;

export type VideoExportEventOneOf2TypeEnum = typeof VideoExportEventOneOf2TypeEnum[keyof typeof VideoExportEventOneOf2TypeEnum];

/**
 * 
 * @export
 * @interface VideoExportEventOneOf2Value
 */
export interface VideoExportEventOneOf2Value {
    /**
     * 
     * @type {number}
     * @memberof VideoExportEventOneOf2Value
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof VideoExportEventOneOf2Value
     */
    'progress': number;
}
/**
 * 
 * @export
 * @interface VideoExportEventOneOf3
 */
export interface VideoExportEventOneOf3 {
    /**
     * 
     * @type {string}
     * @memberof VideoExportEventOneOf3
     */
    'type': VideoExportEventOneOf3TypeEnum;
    /**
     * 
     * @type {LiveStreamEventOneOf5Value}
     * @memberof VideoExportEventOneOf3
     */
    'value': LiveStreamEventOneOf5Value;
}

export const VideoExportEventOneOf3TypeEnum = {
    StartingUpload: 'StartingUpload'
} as const;

export type VideoExportEventOneOf3TypeEnum = typeof VideoExportEventOneOf3TypeEnum[keyof typeof VideoExportEventOneOf3TypeEnum];

/**
 * 
 * @export
 * @interface VideoExportEventOneOf4
 */
export interface VideoExportEventOneOf4 {
    /**
     * 
     * @type {string}
     * @memberof VideoExportEventOneOf4
     */
    'type': VideoExportEventOneOf4TypeEnum;
    /**
     * 
     * @type {PlaylistVideoInfo}
     * @memberof VideoExportEventOneOf4
     */
    'value': PlaylistVideoInfo;
}

export const VideoExportEventOneOf4TypeEnum = {
    Finished: 'Finished'
} as const;

export type VideoExportEventOneOf4TypeEnum = typeof VideoExportEventOneOf4TypeEnum[keyof typeof VideoExportEventOneOf4TypeEnum];

/**
 * 
 * @export
 * @interface VideoExportEventOneOf5
 */
export interface VideoExportEventOneOf5 {
    /**
     * 
     * @type {string}
     * @memberof VideoExportEventOneOf5
     */
    'type': VideoExportEventOneOf5TypeEnum;
    /**
     * 
     * @type {PlaylistVideoInfo}
     * @memberof VideoExportEventOneOf5
     */
    'value': PlaylistVideoInfo;
}

export const VideoExportEventOneOf5TypeEnum = {
    Error: 'Error'
} as const;

export type VideoExportEventOneOf5TypeEnum = typeof VideoExportEventOneOf5TypeEnum[keyof typeof VideoExportEventOneOf5TypeEnum];

/**
 * 
 * @export
 * @interface VideoOffset
 */
export interface VideoOffset {
    /**
     * 
     * @type {number}
     * @memberof VideoOffset
     */
    'forzaVideoAssetId': number;
    /**
     * 
     * @type {number}
     * @memberof VideoOffset
     */
    'videoOffsetMs': number;
}

/**
 * V1forzaApi - axios parameter creator
 * @export
 */
export const V1forzaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} forzaEventId id of the event to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forzaEvent: async (forzaEventId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forzaEventId' is not null or undefined
            assertParamExists('forzaEvent', 'forzaEventId', forzaEventId)
            const localVarPath = `/v1.0/forza_events/{forza_event_id}`
                .replace(`{${"forza_event_id"}}`, encodeURIComponent(String(forzaEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForzaEventType} forzaEventTypeId event types to retrieve
         * @param {number} [teamId] 
         * @param {number} [oppositionTeamId] 
         * @param {number} [playerId] 
         * @param {string} [to] 
         * @param {string} [from] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {number} [assistBy] 
         * @param {ForzaGoalType} [goalType] 
         * @param {ForzaSetPiece} [afterSetPiece] 
         * @param {boolean} [onTarget] 
         * @param {ForzaShotType} [shotType] 
         * @param {ForzaShotResult} [shotResult] 
         * @param {number} [playerAwarded] 
         * @param {ForzaRedCardType} [redCardType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forzaEvents: async (forzaEventTypeId: ForzaEventType, teamId?: number, oppositionTeamId?: number, playerId?: number, to?: string, from?: string, limit?: number, offset?: number, assistBy?: number, goalType?: ForzaGoalType, afterSetPiece?: ForzaSetPiece, onTarget?: boolean, shotType?: ForzaShotType, shotResult?: ForzaShotResult, playerAwarded?: number, redCardType?: ForzaRedCardType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forzaEventTypeId' is not null or undefined
            assertParamExists('forzaEvents', 'forzaEventTypeId', forzaEventTypeId)
            const localVarPath = `/v1.0/forza_events/{forza_event_type_id}`
                .replace(`{${"forza_event_type_id"}}`, encodeURIComponent(String(forzaEventTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (teamId !== undefined) {
                localVarQueryParameter['teamId'] = teamId;
            }

            if (oppositionTeamId !== undefined) {
                localVarQueryParameter['oppositionTeamId'] = oppositionTeamId;
            }

            if (playerId !== undefined) {
                localVarQueryParameter['playerId'] = playerId;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (assistBy !== undefined) {
                localVarQueryParameter['assistBy'] = assistBy;
            }

            if (goalType !== undefined) {
                localVarQueryParameter['goalType'] = goalType;
            }

            if (afterSetPiece !== undefined) {
                localVarQueryParameter['afterSetPiece'] = afterSetPiece;
            }

            if (onTarget !== undefined) {
                localVarQueryParameter['onTarget'] = onTarget;
            }

            if (shotType !== undefined) {
                localVarQueryParameter['shotType'] = shotType;
            }

            if (shotResult !== undefined) {
                localVarQueryParameter['shotResult'] = shotResult;
            }

            if (playerAwarded !== undefined) {
                localVarQueryParameter['playerAwarded'] = playerAwarded;
            }

            if (redCardType !== undefined) {
                localVarQueryParameter['redCardType'] = redCardType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forzaGameId the game to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forzaGame: async (forzaGameId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forzaGameId' is not null or undefined
            assertParamExists('forzaGame', 'forzaGameId', forzaGameId)
            const localVarPath = `/v1.0/forza_games/{forza_game_id}`
                .replace(`{${"forza_game_id"}}`, encodeURIComponent(String(forzaGameId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [teamId1] 
         * @param {number} [teamId2] 
         * @param {string} [startDate] 
         * @param {string} [stopDate] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [isLive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forzaGames: async (teamId1?: number, teamId2?: number, startDate?: string, stopDate?: string, limit?: number, offset?: number, isLive?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/forza_games`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (teamId1 !== undefined) {
                localVarQueryParameter['teamId1'] = teamId1;
            }

            if (teamId2 !== undefined) {
                localVarQueryParameter['teamId2'] = teamId2;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (stopDate !== undefined) {
                localVarQueryParameter['stopDate'] = (stopDate as any instanceof Date) ?
                    (stopDate as any).toISOString() :
                    stopDate;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (isLive !== undefined) {
                localVarQueryParameter['isLive'] = isLive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forzaPlayers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/forza_players`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [season] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forzaTeams: async (season?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/forza_teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (season !== undefined) {
                localVarQueryParameter['season'] = season;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forzaVideoAssetId the video asset to retrieve homography for
         * @param {number} startMs the end offset into the video asset to retrieve homography
         * @param {number} endMs the start offset into the video asset to retrieve homography
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forzaVideoAssetHomography: async (forzaVideoAssetId: number, startMs: number, endMs: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forzaVideoAssetId' is not null or undefined
            assertParamExists('forzaVideoAssetHomography', 'forzaVideoAssetId', forzaVideoAssetId)
            // verify required parameter 'startMs' is not null or undefined
            assertParamExists('forzaVideoAssetHomography', 'startMs', startMs)
            // verify required parameter 'endMs' is not null or undefined
            assertParamExists('forzaVideoAssetHomography', 'endMs', endMs)
            const localVarPath = `/v1.0/forza_video_assets/{forza_video_asset_id}/homography/{start_ms}/{end_ms}`
                .replace(`{${"forza_video_asset_id"}}`, encodeURIComponent(String(forzaVideoAssetId)))
                .replace(`{${"start_ms"}}`, encodeURIComponent(String(startMs)))
                .replace(`{${"end_ms"}}`, encodeURIComponent(String(endMs)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forzaGameId the game to update
         * @param {SetGameKickoff} setGameKickoff the game to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setForzaGameKickoff: async (forzaGameId: number, setGameKickoff: SetGameKickoff, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forzaGameId' is not null or undefined
            assertParamExists('setForzaGameKickoff', 'forzaGameId', forzaGameId)
            // verify required parameter 'setGameKickoff' is not null or undefined
            assertParamExists('setForzaGameKickoff', 'setGameKickoff', setGameKickoff)
            const localVarPath = `/v1.0/forza_games/{forza_game_id}/set_game_kickoff`
                .replace(`{${"forza_game_id"}}`, encodeURIComponent(String(forzaGameId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setGameKickoff, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forzaGameId the game to update
         * @param {UpdateForzaGame} updateForzaGame the game to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateForzaGame: async (forzaGameId: number, updateForzaGame: UpdateForzaGame, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forzaGameId' is not null or undefined
            assertParamExists('updateForzaGame', 'forzaGameId', forzaGameId)
            // verify required parameter 'updateForzaGame' is not null or undefined
            assertParamExists('updateForzaGame', 'updateForzaGame', updateForzaGame)
            const localVarPath = `/v1.0/forza_games/{forza_game_id}`
                .replace(`{${"forza_game_id"}}`, encodeURIComponent(String(forzaGameId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateForzaGame, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1forzaApi - functional programming interface
 * @export
 */
export const V1forzaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1forzaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} forzaEventId id of the event to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forzaEvent(forzaEventId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForzaEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forzaEvent(forzaEventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ForzaEventType} forzaEventTypeId event types to retrieve
         * @param {number} [teamId] 
         * @param {number} [oppositionTeamId] 
         * @param {number} [playerId] 
         * @param {string} [to] 
         * @param {string} [from] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {number} [assistBy] 
         * @param {ForzaGoalType} [goalType] 
         * @param {ForzaSetPiece} [afterSetPiece] 
         * @param {boolean} [onTarget] 
         * @param {ForzaShotType} [shotType] 
         * @param {ForzaShotResult} [shotResult] 
         * @param {number} [playerAwarded] 
         * @param {ForzaRedCardType} [redCardType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forzaEvents(forzaEventTypeId: ForzaEventType, teamId?: number, oppositionTeamId?: number, playerId?: number, to?: string, from?: string, limit?: number, offset?: number, assistBy?: number, goalType?: ForzaGoalType, afterSetPiece?: ForzaSetPiece, onTarget?: boolean, shotType?: ForzaShotType, shotResult?: ForzaShotResult, playerAwarded?: number, redCardType?: ForzaRedCardType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForzaEvents>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forzaEvents(forzaEventTypeId, teamId, oppositionTeamId, playerId, to, from, limit, offset, assistBy, goalType, afterSetPiece, onTarget, shotType, shotResult, playerAwarded, redCardType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forzaGameId the game to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forzaGame(forzaGameId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForzaGame>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forzaGame(forzaGameId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [teamId1] 
         * @param {number} [teamId2] 
         * @param {string} [startDate] 
         * @param {string} [stopDate] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [isLive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forzaGames(teamId1?: number, teamId2?: number, startDate?: string, stopDate?: string, limit?: number, offset?: number, isLive?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForzaGames>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forzaGames(teamId1, teamId2, startDate, stopDate, limit, offset, isLive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forzaPlayers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ForzaPlayer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forzaPlayers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [season] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forzaTeams(season?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ForzaTeam>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forzaTeams(season, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forzaVideoAssetId the video asset to retrieve homography for
         * @param {number} startMs the end offset into the video asset to retrieve homography
         * @param {number} endMs the start offset into the video asset to retrieve homography
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forzaVideoAssetHomography(forzaVideoAssetId: number, startMs: number, endMs: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HomographyFrame>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forzaVideoAssetHomography(forzaVideoAssetId, startMs, endMs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forzaGameId the game to update
         * @param {SetGameKickoff} setGameKickoff the game to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setForzaGameKickoff(forzaGameId: number, setGameKickoff: SetGameKickoff, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setForzaGameKickoff(forzaGameId, setGameKickoff, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forzaGameId the game to update
         * @param {UpdateForzaGame} updateForzaGame the game to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateForzaGame(forzaGameId: number, updateForzaGame: UpdateForzaGame, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateForzaGame(forzaGameId, updateForzaGame, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1forzaApi - factory interface
 * @export
 */
export const V1forzaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1forzaApiFp(configuration)
    return {
        /**
         * 
         * @param {V1forzaApiForzaEventRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forzaEvent(requestParameters: V1forzaApiForzaEventRequest, options?: AxiosRequestConfig): AxiosPromise<ForzaEvent> {
            return localVarFp.forzaEvent(requestParameters.forzaEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1forzaApiForzaEventsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forzaEvents(requestParameters: V1forzaApiForzaEventsRequest, options?: AxiosRequestConfig): AxiosPromise<ForzaEvents> {
            return localVarFp.forzaEvents(requestParameters.forzaEventTypeId, requestParameters.teamId, requestParameters.oppositionTeamId, requestParameters.playerId, requestParameters.to, requestParameters.from, requestParameters.limit, requestParameters.offset, requestParameters.assistBy, requestParameters.goalType, requestParameters.afterSetPiece, requestParameters.onTarget, requestParameters.shotType, requestParameters.shotResult, requestParameters.playerAwarded, requestParameters.redCardType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1forzaApiForzaGameRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forzaGame(requestParameters: V1forzaApiForzaGameRequest, options?: AxiosRequestConfig): AxiosPromise<ForzaGame> {
            return localVarFp.forzaGame(requestParameters.forzaGameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1forzaApiForzaGamesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forzaGames(requestParameters: V1forzaApiForzaGamesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ForzaGames> {
            return localVarFp.forzaGames(requestParameters.teamId1, requestParameters.teamId2, requestParameters.startDate, requestParameters.stopDate, requestParameters.limit, requestParameters.offset, requestParameters.isLive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forzaPlayers(options?: AxiosRequestConfig): AxiosPromise<Array<ForzaPlayer>> {
            return localVarFp.forzaPlayers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1forzaApiForzaTeamsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forzaTeams(requestParameters: V1forzaApiForzaTeamsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ForzaTeam>> {
            return localVarFp.forzaTeams(requestParameters.season, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1forzaApiForzaVideoAssetHomographyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forzaVideoAssetHomography(requestParameters: V1forzaApiForzaVideoAssetHomographyRequest, options?: AxiosRequestConfig): AxiosPromise<Array<HomographyFrame>> {
            return localVarFp.forzaVideoAssetHomography(requestParameters.forzaVideoAssetId, requestParameters.startMs, requestParameters.endMs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1forzaApiSetForzaGameKickoffRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setForzaGameKickoff(requestParameters: V1forzaApiSetForzaGameKickoffRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.setForzaGameKickoff(requestParameters.forzaGameId, requestParameters.setGameKickoff, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1forzaApiUpdateForzaGameRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateForzaGame(requestParameters: V1forzaApiUpdateForzaGameRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateForzaGame(requestParameters.forzaGameId, requestParameters.updateForzaGame, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forzaEvent operation in V1forzaApi.
 * @export
 * @interface V1forzaApiForzaEventRequest
 */
export interface V1forzaApiForzaEventRequest {
    /**
     * id of the event to retrieve
     * @type {number}
     * @memberof V1forzaApiForzaEvent
     */
    readonly forzaEventId: number
}

/**
 * Request parameters for forzaEvents operation in V1forzaApi.
 * @export
 * @interface V1forzaApiForzaEventsRequest
 */
export interface V1forzaApiForzaEventsRequest {
    /**
     * event types to retrieve
     * @type {ForzaEventType}
     * @memberof V1forzaApiForzaEvents
     */
    readonly forzaEventTypeId: ForzaEventType

    /**
     * 
     * @type {number}
     * @memberof V1forzaApiForzaEvents
     */
    readonly teamId?: number

    /**
     * 
     * @type {number}
     * @memberof V1forzaApiForzaEvents
     */
    readonly oppositionTeamId?: number

    /**
     * 
     * @type {number}
     * @memberof V1forzaApiForzaEvents
     */
    readonly playerId?: number

    /**
     * 
     * @type {string}
     * @memberof V1forzaApiForzaEvents
     */
    readonly to?: string

    /**
     * 
     * @type {string}
     * @memberof V1forzaApiForzaEvents
     */
    readonly from?: string

    /**
     * 
     * @type {number}
     * @memberof V1forzaApiForzaEvents
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof V1forzaApiForzaEvents
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof V1forzaApiForzaEvents
     */
    readonly assistBy?: number

    /**
     * 
     * @type {ForzaGoalType}
     * @memberof V1forzaApiForzaEvents
     */
    readonly goalType?: ForzaGoalType

    /**
     * 
     * @type {ForzaSetPiece}
     * @memberof V1forzaApiForzaEvents
     */
    readonly afterSetPiece?: ForzaSetPiece

    /**
     * 
     * @type {boolean}
     * @memberof V1forzaApiForzaEvents
     */
    readonly onTarget?: boolean

    /**
     * 
     * @type {ForzaShotType}
     * @memberof V1forzaApiForzaEvents
     */
    readonly shotType?: ForzaShotType

    /**
     * 
     * @type {ForzaShotResult}
     * @memberof V1forzaApiForzaEvents
     */
    readonly shotResult?: ForzaShotResult

    /**
     * 
     * @type {number}
     * @memberof V1forzaApiForzaEvents
     */
    readonly playerAwarded?: number

    /**
     * 
     * @type {ForzaRedCardType}
     * @memberof V1forzaApiForzaEvents
     */
    readonly redCardType?: ForzaRedCardType
}

/**
 * Request parameters for forzaGame operation in V1forzaApi.
 * @export
 * @interface V1forzaApiForzaGameRequest
 */
export interface V1forzaApiForzaGameRequest {
    /**
     * the game to retrieve
     * @type {number}
     * @memberof V1forzaApiForzaGame
     */
    readonly forzaGameId: number
}

/**
 * Request parameters for forzaGames operation in V1forzaApi.
 * @export
 * @interface V1forzaApiForzaGamesRequest
 */
export interface V1forzaApiForzaGamesRequest {
    /**
     * 
     * @type {number}
     * @memberof V1forzaApiForzaGames
     */
    readonly teamId1?: number

    /**
     * 
     * @type {number}
     * @memberof V1forzaApiForzaGames
     */
    readonly teamId2?: number

    /**
     * 
     * @type {string}
     * @memberof V1forzaApiForzaGames
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof V1forzaApiForzaGames
     */
    readonly stopDate?: string

    /**
     * 
     * @type {number}
     * @memberof V1forzaApiForzaGames
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof V1forzaApiForzaGames
     */
    readonly offset?: number

    /**
     * 
     * @type {boolean}
     * @memberof V1forzaApiForzaGames
     */
    readonly isLive?: boolean
}

/**
 * Request parameters for forzaTeams operation in V1forzaApi.
 * @export
 * @interface V1forzaApiForzaTeamsRequest
 */
export interface V1forzaApiForzaTeamsRequest {
    /**
     * 
     * @type {number}
     * @memberof V1forzaApiForzaTeams
     */
    readonly season?: number
}

/**
 * Request parameters for forzaVideoAssetHomography operation in V1forzaApi.
 * @export
 * @interface V1forzaApiForzaVideoAssetHomographyRequest
 */
export interface V1forzaApiForzaVideoAssetHomographyRequest {
    /**
     * the video asset to retrieve homography for
     * @type {number}
     * @memberof V1forzaApiForzaVideoAssetHomography
     */
    readonly forzaVideoAssetId: number

    /**
     * the end offset into the video asset to retrieve homography
     * @type {number}
     * @memberof V1forzaApiForzaVideoAssetHomography
     */
    readonly startMs: number

    /**
     * the start offset into the video asset to retrieve homography
     * @type {number}
     * @memberof V1forzaApiForzaVideoAssetHomography
     */
    readonly endMs: number
}

/**
 * Request parameters for setForzaGameKickoff operation in V1forzaApi.
 * @export
 * @interface V1forzaApiSetForzaGameKickoffRequest
 */
export interface V1forzaApiSetForzaGameKickoffRequest {
    /**
     * the game to update
     * @type {number}
     * @memberof V1forzaApiSetForzaGameKickoff
     */
    readonly forzaGameId: number

    /**
     * the game to update
     * @type {SetGameKickoff}
     * @memberof V1forzaApiSetForzaGameKickoff
     */
    readonly setGameKickoff: SetGameKickoff
}

/**
 * Request parameters for updateForzaGame operation in V1forzaApi.
 * @export
 * @interface V1forzaApiUpdateForzaGameRequest
 */
export interface V1forzaApiUpdateForzaGameRequest {
    /**
     * the game to update
     * @type {number}
     * @memberof V1forzaApiUpdateForzaGame
     */
    readonly forzaGameId: number

    /**
     * the game to update
     * @type {UpdateForzaGame}
     * @memberof V1forzaApiUpdateForzaGame
     */
    readonly updateForzaGame: UpdateForzaGame
}

/**
 * V1forzaApi - object-oriented interface
 * @export
 * @class V1forzaApi
 * @extends {BaseAPI}
 */
export class V1forzaApi extends BaseAPI {
    /**
     * 
     * @param {V1forzaApiForzaEventRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1forzaApi
     */
    public forzaEvent(requestParameters: V1forzaApiForzaEventRequest, options?: AxiosRequestConfig) {
        return V1forzaApiFp(this.configuration).forzaEvent(requestParameters.forzaEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1forzaApiForzaEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1forzaApi
     */
    public forzaEvents(requestParameters: V1forzaApiForzaEventsRequest, options?: AxiosRequestConfig) {
        return V1forzaApiFp(this.configuration).forzaEvents(requestParameters.forzaEventTypeId, requestParameters.teamId, requestParameters.oppositionTeamId, requestParameters.playerId, requestParameters.to, requestParameters.from, requestParameters.limit, requestParameters.offset, requestParameters.assistBy, requestParameters.goalType, requestParameters.afterSetPiece, requestParameters.onTarget, requestParameters.shotType, requestParameters.shotResult, requestParameters.playerAwarded, requestParameters.redCardType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1forzaApiForzaGameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1forzaApi
     */
    public forzaGame(requestParameters: V1forzaApiForzaGameRequest, options?: AxiosRequestConfig) {
        return V1forzaApiFp(this.configuration).forzaGame(requestParameters.forzaGameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1forzaApiForzaGamesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1forzaApi
     */
    public forzaGames(requestParameters: V1forzaApiForzaGamesRequest = {}, options?: AxiosRequestConfig) {
        return V1forzaApiFp(this.configuration).forzaGames(requestParameters.teamId1, requestParameters.teamId2, requestParameters.startDate, requestParameters.stopDate, requestParameters.limit, requestParameters.offset, requestParameters.isLive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1forzaApi
     */
    public forzaPlayers(options?: AxiosRequestConfig) {
        return V1forzaApiFp(this.configuration).forzaPlayers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1forzaApiForzaTeamsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1forzaApi
     */
    public forzaTeams(requestParameters: V1forzaApiForzaTeamsRequest = {}, options?: AxiosRequestConfig) {
        return V1forzaApiFp(this.configuration).forzaTeams(requestParameters.season, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1forzaApiForzaVideoAssetHomographyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1forzaApi
     */
    public forzaVideoAssetHomography(requestParameters: V1forzaApiForzaVideoAssetHomographyRequest, options?: AxiosRequestConfig) {
        return V1forzaApiFp(this.configuration).forzaVideoAssetHomography(requestParameters.forzaVideoAssetId, requestParameters.startMs, requestParameters.endMs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1forzaApiSetForzaGameKickoffRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1forzaApi
     */
    public setForzaGameKickoff(requestParameters: V1forzaApiSetForzaGameKickoffRequest, options?: AxiosRequestConfig) {
        return V1forzaApiFp(this.configuration).setForzaGameKickoff(requestParameters.forzaGameId, requestParameters.setGameKickoff, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1forzaApiUpdateForzaGameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1forzaApi
     */
    public updateForzaGame(requestParameters: V1forzaApiUpdateForzaGameRequest, options?: AxiosRequestConfig) {
        return V1forzaApiFp(this.configuration).updateForzaGame(requestParameters.forzaGameId, requestParameters.updateForzaGame, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1groupApi - axios parameter creator
 * @export
 */
export const V1groupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} teamId the team to create groups for
         * @param {CreateGroup} createGroup the group to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup: async (teamId: number, createGroup: CreateGroup, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('createGroup', 'teamId', teamId)
            // verify required parameter 'createGroup' is not null or undefined
            assertParamExists('createGroup', 'createGroup', createGroup)
            const localVarPath = `/v1.0/groups/{team_id}`
                .replace(`{${"team_id"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} groupId the group to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup: async (groupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('deleteGroup', 'groupId', groupId)
            const localVarPath = `/v1.0/groups/{group_id}`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} teamId the team to get groups for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups: async (teamId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('getGroups', 'teamId', teamId)
            const localVarPath = `/v1.0/groups/{team_id}`
                .replace(`{${"team_id"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} groupId the group to update
         * @param {CreateGroup} createGroup the updated group object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup: async (groupId: number, createGroup: CreateGroup, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('updateGroup', 'groupId', groupId)
            // verify required parameter 'createGroup' is not null or undefined
            assertParamExists('updateGroup', 'createGroup', createGroup)
            const localVarPath = `/v1.0/groups/{group_id}`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1groupApi - functional programming interface
 * @export
 */
export const V1groupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1groupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} teamId the team to create groups for
         * @param {CreateGroup} createGroup the group to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGroup(teamId: number, createGroup: CreateGroup, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGroup(teamId, createGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} groupId the group to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGroup(groupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGroup(groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} teamId the team to get groups for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroups(teamId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Group>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroups(teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} groupId the group to update
         * @param {CreateGroup} createGroup the updated group object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroup(groupId: number, createGroup: CreateGroup, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroup(groupId, createGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1groupApi - factory interface
 * @export
 */
export const V1groupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1groupApiFp(configuration)
    return {
        /**
         * 
         * @param {V1groupApiCreateGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup(requestParameters: V1groupApiCreateGroupRequest, options?: AxiosRequestConfig): AxiosPromise<Group> {
            return localVarFp.createGroup(requestParameters.teamId, requestParameters.createGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1groupApiDeleteGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup(requestParameters: V1groupApiDeleteGroupRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteGroup(requestParameters.groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1groupApiGetGroupsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups(requestParameters: V1groupApiGetGroupsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<Group>> {
            return localVarFp.getGroups(requestParameters.teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1groupApiUpdateGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(requestParameters: V1groupApiUpdateGroupRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateGroup(requestParameters.groupId, requestParameters.createGroup, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createGroup operation in V1groupApi.
 * @export
 * @interface V1groupApiCreateGroupRequest
 */
export interface V1groupApiCreateGroupRequest {
    /**
     * the team to create groups for
     * @type {number}
     * @memberof V1groupApiCreateGroup
     */
    readonly teamId: number

    /**
     * the group to create
     * @type {CreateGroup}
     * @memberof V1groupApiCreateGroup
     */
    readonly createGroup: CreateGroup
}

/**
 * Request parameters for deleteGroup operation in V1groupApi.
 * @export
 * @interface V1groupApiDeleteGroupRequest
 */
export interface V1groupApiDeleteGroupRequest {
    /**
     * the group to delete
     * @type {number}
     * @memberof V1groupApiDeleteGroup
     */
    readonly groupId: number
}

/**
 * Request parameters for getGroups operation in V1groupApi.
 * @export
 * @interface V1groupApiGetGroupsRequest
 */
export interface V1groupApiGetGroupsRequest {
    /**
     * the team to get groups for
     * @type {number}
     * @memberof V1groupApiGetGroups
     */
    readonly teamId: number
}

/**
 * Request parameters for updateGroup operation in V1groupApi.
 * @export
 * @interface V1groupApiUpdateGroupRequest
 */
export interface V1groupApiUpdateGroupRequest {
    /**
     * the group to update
     * @type {number}
     * @memberof V1groupApiUpdateGroup
     */
    readonly groupId: number

    /**
     * the updated group object
     * @type {CreateGroup}
     * @memberof V1groupApiUpdateGroup
     */
    readonly createGroup: CreateGroup
}

/**
 * V1groupApi - object-oriented interface
 * @export
 * @class V1groupApi
 * @extends {BaseAPI}
 */
export class V1groupApi extends BaseAPI {
    /**
     * 
     * @param {V1groupApiCreateGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1groupApi
     */
    public createGroup(requestParameters: V1groupApiCreateGroupRequest, options?: AxiosRequestConfig) {
        return V1groupApiFp(this.configuration).createGroup(requestParameters.teamId, requestParameters.createGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1groupApiDeleteGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1groupApi
     */
    public deleteGroup(requestParameters: V1groupApiDeleteGroupRequest, options?: AxiosRequestConfig) {
        return V1groupApiFp(this.configuration).deleteGroup(requestParameters.groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1groupApiGetGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1groupApi
     */
    public getGroups(requestParameters: V1groupApiGetGroupsRequest, options?: AxiosRequestConfig) {
        return V1groupApiFp(this.configuration).getGroups(requestParameters.teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1groupApiUpdateGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1groupApi
     */
    public updateGroup(requestParameters: V1groupApiUpdateGroupRequest, options?: AxiosRequestConfig) {
        return V1groupApiFp(this.configuration).updateGroup(requestParameters.groupId, requestParameters.createGroup, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1inviteApi - axios parameter creator
 * @export
 */
export const V1inviteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} inviteId the invite to accept
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvite: async (inviteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteId' is not null or undefined
            assertParamExists('acceptInvite', 'inviteId', inviteId)
            const localVarPath = `/v1.0/invites/{invite_id}/accept`
                .replace(`{${"invite_id"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateInvite} createInvite the invite to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvite: async (createInvite: CreateInvite, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createInvite' is not null or undefined
            assertParamExists('createInvite', 'createInvite', createInvite)
            const localVarPath = `/v1.0/invites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInvite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inviteId the invite to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvite: async (inviteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteId' is not null or undefined
            assertParamExists('getInvite', 'inviteId', inviteId)
            const localVarPath = `/v1.0/invites/{invite_id}`
                .replace(`{${"invite_id"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1inviteApi - functional programming interface
 * @export
 */
export const V1inviteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1inviteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} inviteId the invite to accept
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptInvite(inviteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptInvite(inviteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateInvite} createInvite the invite to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvite(createInvite: CreateInvite, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvite(createInvite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} inviteId the invite to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvite(inviteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvite(inviteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1inviteApi - factory interface
 * @export
 */
export const V1inviteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1inviteApiFp(configuration)
    return {
        /**
         * 
         * @param {V1inviteApiAcceptInviteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvite(requestParameters: V1inviteApiAcceptInviteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.acceptInvite(requestParameters.inviteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1inviteApiCreateInviteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvite(requestParameters: V1inviteApiCreateInviteRequest, options?: AxiosRequestConfig): AxiosPromise<Invite> {
            return localVarFp.createInvite(requestParameters.createInvite, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1inviteApiGetInviteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvite(requestParameters: V1inviteApiGetInviteRequest, options?: AxiosRequestConfig): AxiosPromise<Invite> {
            return localVarFp.getInvite(requestParameters.inviteId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for acceptInvite operation in V1inviteApi.
 * @export
 * @interface V1inviteApiAcceptInviteRequest
 */
export interface V1inviteApiAcceptInviteRequest {
    /**
     * the invite to accept
     * @type {string}
     * @memberof V1inviteApiAcceptInvite
     */
    readonly inviteId: string
}

/**
 * Request parameters for createInvite operation in V1inviteApi.
 * @export
 * @interface V1inviteApiCreateInviteRequest
 */
export interface V1inviteApiCreateInviteRequest {
    /**
     * the invite to create
     * @type {CreateInvite}
     * @memberof V1inviteApiCreateInvite
     */
    readonly createInvite: CreateInvite
}

/**
 * Request parameters for getInvite operation in V1inviteApi.
 * @export
 * @interface V1inviteApiGetInviteRequest
 */
export interface V1inviteApiGetInviteRequest {
    /**
     * the invite to retrieve
     * @type {string}
     * @memberof V1inviteApiGetInvite
     */
    readonly inviteId: string
}

/**
 * V1inviteApi - object-oriented interface
 * @export
 * @class V1inviteApi
 * @extends {BaseAPI}
 */
export class V1inviteApi extends BaseAPI {
    /**
     * 
     * @param {V1inviteApiAcceptInviteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1inviteApi
     */
    public acceptInvite(requestParameters: V1inviteApiAcceptInviteRequest, options?: AxiosRequestConfig) {
        return V1inviteApiFp(this.configuration).acceptInvite(requestParameters.inviteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1inviteApiCreateInviteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1inviteApi
     */
    public createInvite(requestParameters: V1inviteApiCreateInviteRequest, options?: AxiosRequestConfig) {
        return V1inviteApiFp(this.configuration).createInvite(requestParameters.createInvite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1inviteApiGetInviteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1inviteApi
     */
    public getInvite(requestParameters: V1inviteApiGetInviteRequest, options?: AxiosRequestConfig) {
        return V1inviteApiFp(this.configuration).getInvite(requestParameters.inviteId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1journalApi - axios parameter creator
 * @export
 */
export const V1journalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} playerId the player to create journal entry for
         * @param {CreateJournalEntry} createJournalEntry the journal entry to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJournalEntry: async (playerId: string, createJournalEntry: CreateJournalEntry, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'playerId' is not null or undefined
            assertParamExists('createJournalEntry', 'playerId', playerId)
            // verify required parameter 'createJournalEntry' is not null or undefined
            assertParamExists('createJournalEntry', 'createJournalEntry', createJournalEntry)
            const localVarPath = `/v1.0/journal_entries/{player_id}`
                .replace(`{${"player_id"}}`, encodeURIComponent(String(playerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createJournalEntry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} journalEntryId the journal entry to create a comment on
         * @param {CreateJournalEntryComment} createJournalEntryComment the comment to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJournalEntryComment: async (journalEntryId: number, createJournalEntryComment: CreateJournalEntryComment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'journalEntryId' is not null or undefined
            assertParamExists('createJournalEntryComment', 'journalEntryId', journalEntryId)
            // verify required parameter 'createJournalEntryComment' is not null or undefined
            assertParamExists('createJournalEntryComment', 'createJournalEntryComment', createJournalEntryComment)
            const localVarPath = `/v1.0/journal_entries/{journal_entry_id}/comments`
                .replace(`{${"journal_entry_id"}}`, encodeURIComponent(String(journalEntryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createJournalEntryComment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} journalEntryId the journal entry to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJournalEntry: async (journalEntryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'journalEntryId' is not null or undefined
            assertParamExists('deleteJournalEntry', 'journalEntryId', journalEntryId)
            const localVarPath = `/v1.0/journal_entries/{journal_entry_id}`
                .replace(`{${"journal_entry_id"}}`, encodeURIComponent(String(journalEntryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} journalEntryCommentId the comment to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJournalEntryComment: async (journalEntryCommentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'journalEntryCommentId' is not null or undefined
            assertParamExists('deleteJournalEntryComment', 'journalEntryCommentId', journalEntryCommentId)
            const localVarPath = `/v1.0/journal_entries/comments/{journal_entry_comment_id}`
                .replace(`{${"journal_entry_comment_id"}}`, encodeURIComponent(String(journalEntryCommentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} playerId the player to retrieve journal entries for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalEntries: async (playerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'playerId' is not null or undefined
            assertParamExists('getJournalEntries', 'playerId', playerId)
            const localVarPath = `/v1.0/journal_entries/{player_id}`
                .replace(`{${"player_id"}}`, encodeURIComponent(String(playerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} journalEntryId the journal entry to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalEntry: async (journalEntryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'journalEntryId' is not null or undefined
            assertParamExists('getJournalEntry', 'journalEntryId', journalEntryId)
            const localVarPath = `/v1.0/journal_entries/{journal_entry_id}`
                .replace(`{${"journal_entry_id"}}`, encodeURIComponent(String(journalEntryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} journalEntryId the journal entry to update
         * @param {UpdateJournalEntry} updateJournalEntry the updated journal entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJournalEntry: async (journalEntryId: number, updateJournalEntry: UpdateJournalEntry, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'journalEntryId' is not null or undefined
            assertParamExists('updateJournalEntry', 'journalEntryId', journalEntryId)
            // verify required parameter 'updateJournalEntry' is not null or undefined
            assertParamExists('updateJournalEntry', 'updateJournalEntry', updateJournalEntry)
            const localVarPath = `/v1.0/journal_entries/{journal_entry_id}`
                .replace(`{${"journal_entry_id"}}`, encodeURIComponent(String(journalEntryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateJournalEntry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} journalEntryCommentId the journal entry comment to update
         * @param {UpdateJournalEntryComment} updateJournalEntryComment the updated comment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJournalEntryComment: async (journalEntryCommentId: number, updateJournalEntryComment: UpdateJournalEntryComment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'journalEntryCommentId' is not null or undefined
            assertParamExists('updateJournalEntryComment', 'journalEntryCommentId', journalEntryCommentId)
            // verify required parameter 'updateJournalEntryComment' is not null or undefined
            assertParamExists('updateJournalEntryComment', 'updateJournalEntryComment', updateJournalEntryComment)
            const localVarPath = `/v1.0/journal_entries/comments/{journal_entry_comment_id}`
                .replace(`{${"journal_entry_comment_id"}}`, encodeURIComponent(String(journalEntryCommentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateJournalEntryComment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1journalApi - functional programming interface
 * @export
 */
export const V1journalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1journalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} playerId the player to create journal entry for
         * @param {CreateJournalEntry} createJournalEntry the journal entry to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createJournalEntry(playerId: string, createJournalEntry: CreateJournalEntry, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createJournalEntry(playerId, createJournalEntry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} journalEntryId the journal entry to create a comment on
         * @param {CreateJournalEntryComment} createJournalEntryComment the comment to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createJournalEntryComment(journalEntryId: number, createJournalEntryComment: CreateJournalEntryComment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalEntryComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createJournalEntryComment(journalEntryId, createJournalEntryComment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} journalEntryId the journal entry to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJournalEntry(journalEntryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteJournalEntry(journalEntryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} journalEntryCommentId the comment to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJournalEntryComment(journalEntryCommentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteJournalEntryComment(journalEntryCommentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} playerId the player to retrieve journal entries for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJournalEntries(playerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JournalEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJournalEntries(playerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} journalEntryId the journal entry to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJournalEntry(journalEntryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJournalEntry(journalEntryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} journalEntryId the journal entry to update
         * @param {UpdateJournalEntry} updateJournalEntry the updated journal entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateJournalEntry(journalEntryId: number, updateJournalEntry: UpdateJournalEntry, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateJournalEntry(journalEntryId, updateJournalEntry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} journalEntryCommentId the journal entry comment to update
         * @param {UpdateJournalEntryComment} updateJournalEntryComment the updated comment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateJournalEntryComment(journalEntryCommentId: number, updateJournalEntryComment: UpdateJournalEntryComment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateJournalEntryComment(journalEntryCommentId, updateJournalEntryComment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1journalApi - factory interface
 * @export
 */
export const V1journalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1journalApiFp(configuration)
    return {
        /**
         * 
         * @param {V1journalApiCreateJournalEntryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJournalEntry(requestParameters: V1journalApiCreateJournalEntryRequest, options?: AxiosRequestConfig): AxiosPromise<JournalEntry> {
            return localVarFp.createJournalEntry(requestParameters.playerId, requestParameters.createJournalEntry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1journalApiCreateJournalEntryCommentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJournalEntryComment(requestParameters: V1journalApiCreateJournalEntryCommentRequest, options?: AxiosRequestConfig): AxiosPromise<JournalEntryComment> {
            return localVarFp.createJournalEntryComment(requestParameters.journalEntryId, requestParameters.createJournalEntryComment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1journalApiDeleteJournalEntryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJournalEntry(requestParameters: V1journalApiDeleteJournalEntryRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteJournalEntry(requestParameters.journalEntryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1journalApiDeleteJournalEntryCommentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJournalEntryComment(requestParameters: V1journalApiDeleteJournalEntryCommentRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteJournalEntryComment(requestParameters.journalEntryCommentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1journalApiGetJournalEntriesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalEntries(requestParameters: V1journalApiGetJournalEntriesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<JournalEntry>> {
            return localVarFp.getJournalEntries(requestParameters.playerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1journalApiGetJournalEntryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalEntry(requestParameters: V1journalApiGetJournalEntryRequest, options?: AxiosRequestConfig): AxiosPromise<JournalEntry> {
            return localVarFp.getJournalEntry(requestParameters.journalEntryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1journalApiUpdateJournalEntryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJournalEntry(requestParameters: V1journalApiUpdateJournalEntryRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateJournalEntry(requestParameters.journalEntryId, requestParameters.updateJournalEntry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1journalApiUpdateJournalEntryCommentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJournalEntryComment(requestParameters: V1journalApiUpdateJournalEntryCommentRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateJournalEntryComment(requestParameters.journalEntryCommentId, requestParameters.updateJournalEntryComment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createJournalEntry operation in V1journalApi.
 * @export
 * @interface V1journalApiCreateJournalEntryRequest
 */
export interface V1journalApiCreateJournalEntryRequest {
    /**
     * the player to create journal entry for
     * @type {string}
     * @memberof V1journalApiCreateJournalEntry
     */
    readonly playerId: string

    /**
     * the journal entry to create
     * @type {CreateJournalEntry}
     * @memberof V1journalApiCreateJournalEntry
     */
    readonly createJournalEntry: CreateJournalEntry
}

/**
 * Request parameters for createJournalEntryComment operation in V1journalApi.
 * @export
 * @interface V1journalApiCreateJournalEntryCommentRequest
 */
export interface V1journalApiCreateJournalEntryCommentRequest {
    /**
     * the journal entry to create a comment on
     * @type {number}
     * @memberof V1journalApiCreateJournalEntryComment
     */
    readonly journalEntryId: number

    /**
     * the comment to create
     * @type {CreateJournalEntryComment}
     * @memberof V1journalApiCreateJournalEntryComment
     */
    readonly createJournalEntryComment: CreateJournalEntryComment
}

/**
 * Request parameters for deleteJournalEntry operation in V1journalApi.
 * @export
 * @interface V1journalApiDeleteJournalEntryRequest
 */
export interface V1journalApiDeleteJournalEntryRequest {
    /**
     * the journal entry to delete
     * @type {number}
     * @memberof V1journalApiDeleteJournalEntry
     */
    readonly journalEntryId: number
}

/**
 * Request parameters for deleteJournalEntryComment operation in V1journalApi.
 * @export
 * @interface V1journalApiDeleteJournalEntryCommentRequest
 */
export interface V1journalApiDeleteJournalEntryCommentRequest {
    /**
     * the comment to delete
     * @type {number}
     * @memberof V1journalApiDeleteJournalEntryComment
     */
    readonly journalEntryCommentId: number
}

/**
 * Request parameters for getJournalEntries operation in V1journalApi.
 * @export
 * @interface V1journalApiGetJournalEntriesRequest
 */
export interface V1journalApiGetJournalEntriesRequest {
    /**
     * the player to retrieve journal entries for
     * @type {string}
     * @memberof V1journalApiGetJournalEntries
     */
    readonly playerId: string
}

/**
 * Request parameters for getJournalEntry operation in V1journalApi.
 * @export
 * @interface V1journalApiGetJournalEntryRequest
 */
export interface V1journalApiGetJournalEntryRequest {
    /**
     * the journal entry to retrieve
     * @type {number}
     * @memberof V1journalApiGetJournalEntry
     */
    readonly journalEntryId: number
}

/**
 * Request parameters for updateJournalEntry operation in V1journalApi.
 * @export
 * @interface V1journalApiUpdateJournalEntryRequest
 */
export interface V1journalApiUpdateJournalEntryRequest {
    /**
     * the journal entry to update
     * @type {number}
     * @memberof V1journalApiUpdateJournalEntry
     */
    readonly journalEntryId: number

    /**
     * the updated journal entry
     * @type {UpdateJournalEntry}
     * @memberof V1journalApiUpdateJournalEntry
     */
    readonly updateJournalEntry: UpdateJournalEntry
}

/**
 * Request parameters for updateJournalEntryComment operation in V1journalApi.
 * @export
 * @interface V1journalApiUpdateJournalEntryCommentRequest
 */
export interface V1journalApiUpdateJournalEntryCommentRequest {
    /**
     * the journal entry comment to update
     * @type {number}
     * @memberof V1journalApiUpdateJournalEntryComment
     */
    readonly journalEntryCommentId: number

    /**
     * the updated comment
     * @type {UpdateJournalEntryComment}
     * @memberof V1journalApiUpdateJournalEntryComment
     */
    readonly updateJournalEntryComment: UpdateJournalEntryComment
}

/**
 * V1journalApi - object-oriented interface
 * @export
 * @class V1journalApi
 * @extends {BaseAPI}
 */
export class V1journalApi extends BaseAPI {
    /**
     * 
     * @param {V1journalApiCreateJournalEntryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1journalApi
     */
    public createJournalEntry(requestParameters: V1journalApiCreateJournalEntryRequest, options?: AxiosRequestConfig) {
        return V1journalApiFp(this.configuration).createJournalEntry(requestParameters.playerId, requestParameters.createJournalEntry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1journalApiCreateJournalEntryCommentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1journalApi
     */
    public createJournalEntryComment(requestParameters: V1journalApiCreateJournalEntryCommentRequest, options?: AxiosRequestConfig) {
        return V1journalApiFp(this.configuration).createJournalEntryComment(requestParameters.journalEntryId, requestParameters.createJournalEntryComment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1journalApiDeleteJournalEntryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1journalApi
     */
    public deleteJournalEntry(requestParameters: V1journalApiDeleteJournalEntryRequest, options?: AxiosRequestConfig) {
        return V1journalApiFp(this.configuration).deleteJournalEntry(requestParameters.journalEntryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1journalApiDeleteJournalEntryCommentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1journalApi
     */
    public deleteJournalEntryComment(requestParameters: V1journalApiDeleteJournalEntryCommentRequest, options?: AxiosRequestConfig) {
        return V1journalApiFp(this.configuration).deleteJournalEntryComment(requestParameters.journalEntryCommentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1journalApiGetJournalEntriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1journalApi
     */
    public getJournalEntries(requestParameters: V1journalApiGetJournalEntriesRequest, options?: AxiosRequestConfig) {
        return V1journalApiFp(this.configuration).getJournalEntries(requestParameters.playerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1journalApiGetJournalEntryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1journalApi
     */
    public getJournalEntry(requestParameters: V1journalApiGetJournalEntryRequest, options?: AxiosRequestConfig) {
        return V1journalApiFp(this.configuration).getJournalEntry(requestParameters.journalEntryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1journalApiUpdateJournalEntryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1journalApi
     */
    public updateJournalEntry(requestParameters: V1journalApiUpdateJournalEntryRequest, options?: AxiosRequestConfig) {
        return V1journalApiFp(this.configuration).updateJournalEntry(requestParameters.journalEntryId, requestParameters.updateJournalEntry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1journalApiUpdateJournalEntryCommentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1journalApi
     */
    public updateJournalEntryComment(requestParameters: V1journalApiUpdateJournalEntryCommentRequest, options?: AxiosRequestConfig) {
        return V1journalApiFp(this.configuration).updateJournalEntryComment(requestParameters.journalEntryCommentId, requestParameters.updateJournalEntryComment, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1liveStreamApi - axios parameter creator
 * @export
 */
export const V1liveStreamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} liveStreamId the live stream to create message for
         * @param {CreateLiveMessage} createLiveMessage the message to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLiveMessage: async (liveStreamId: number, createLiveMessage: CreateLiveMessage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'liveStreamId' is not null or undefined
            assertParamExists('createLiveMessage', 'liveStreamId', liveStreamId)
            // verify required parameter 'createLiveMessage' is not null or undefined
            assertParamExists('createLiveMessage', 'createLiveMessage', createLiveMessage)
            const localVarPath = `/v1.0/live_streams/{live_stream_id}/messages`
                .replace(`{${"live_stream_id"}}`, encodeURIComponent(String(liveStreamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLiveMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} liveStreamId the live stream to create segment for
         * @param {CreatePlaylistSegment} createPlaylistSegment the segment to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLiveSegment: async (liveStreamId: number, createPlaylistSegment: CreatePlaylistSegment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'liveStreamId' is not null or undefined
            assertParamExists('createLiveSegment', 'liveStreamId', liveStreamId)
            // verify required parameter 'createPlaylistSegment' is not null or undefined
            assertParamExists('createLiveSegment', 'createPlaylistSegment', createPlaylistSegment)
            const localVarPath = `/v1.0/live_streams/{live_stream_id}/segments`
                .replace(`{${"live_stream_id"}}`, encodeURIComponent(String(liveStreamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlaylistSegment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateLiveStream} createLiveStream 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLiveStream: async (createLiveStream: CreateLiveStream, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLiveStream' is not null or undefined
            assertParamExists('createLiveStream', 'createLiveStream', createLiveStream)
            const localVarPath = `/v1.0/live_streams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLiveStream, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} liveStreamId 
         * @param {number} playlistSegmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLiveSegment: async (liveStreamId: number, playlistSegmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'liveStreamId' is not null or undefined
            assertParamExists('deleteLiveSegment', 'liveStreamId', liveStreamId)
            // verify required parameter 'playlistSegmentId' is not null or undefined
            assertParamExists('deleteLiveSegment', 'playlistSegmentId', playlistSegmentId)
            const localVarPath = `/v1.0/live_streams/{live_stream_id}/segments/{playlist_segment_id}`
                .replace(`{${"live_stream_id"}}`, encodeURIComponent(String(liveStreamId)))
                .replace(`{${"playlist_segment_id"}}`, encodeURIComponent(String(playlistSegmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [live] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveStreams: async (live?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/live_streams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (live !== undefined) {
                localVarQueryParameter['live'] = live;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} liveStreamId the live stream to join
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinLiveStream: async (liveStreamId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'liveStreamId' is not null or undefined
            assertParamExists('joinLiveStream', 'liveStreamId', liveStreamId)
            const localVarPath = `/v1.0/live_streams/{live_stream_id}`
                .replace(`{${"live_stream_id"}}`, encodeURIComponent(String(liveStreamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} liveStreamId the live stream to create segment for
         * @param {number} playlistSegmentId the segment to send
         * @param {string} userId the user to send the segment to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendLiveSegment: async (liveStreamId: number, playlistSegmentId: number, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'liveStreamId' is not null or undefined
            assertParamExists('sendLiveSegment', 'liveStreamId', liveStreamId)
            // verify required parameter 'playlistSegmentId' is not null or undefined
            assertParamExists('sendLiveSegment', 'playlistSegmentId', playlistSegmentId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('sendLiveSegment', 'userId', userId)
            const localVarPath = `/v1.0/live_streams/{live_stream_id}/segments/{playlist_segment_id}/send/{user_id}`
                .replace(`{${"live_stream_id"}}`, encodeURIComponent(String(liveStreamId)))
                .replace(`{${"playlist_segment_id"}}`, encodeURIComponent(String(playlistSegmentId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} liveStreamId the live stream to update
         * @param {SetLiveStreamMode} setLiveStreamMode the channel and mode to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLiveStreamMode: async (liveStreamId: number, setLiveStreamMode: SetLiveStreamMode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'liveStreamId' is not null or undefined
            assertParamExists('setLiveStreamMode', 'liveStreamId', liveStreamId)
            // verify required parameter 'setLiveStreamMode' is not null or undefined
            assertParamExists('setLiveStreamMode', 'setLiveStreamMode', setLiveStreamMode)
            const localVarPath = `/v1.0/live_streams/{live_stream_id}/mode`
                .replace(`{${"live_stream_id"}}`, encodeURIComponent(String(liveStreamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setLiveStreamMode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} liveStreamId the live stream to update
         * @param {SetLiveStreamThumbnail} setLiveStreamThumbnail the thumbnail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLiveStreamThumbnail: async (liveStreamId: number, setLiveStreamThumbnail: SetLiveStreamThumbnail, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'liveStreamId' is not null or undefined
            assertParamExists('setLiveStreamThumbnail', 'liveStreamId', liveStreamId)
            // verify required parameter 'setLiveStreamThumbnail' is not null or undefined
            assertParamExists('setLiveStreamThumbnail', 'setLiveStreamThumbnail', setLiveStreamThumbnail)
            const localVarPath = `/v1.0/live_streams/{live_stream_id}/thumbnail`
                .replace(`{${"live_stream_id"}}`, encodeURIComponent(String(liveStreamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setLiveStreamThumbnail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} liveStreamId the live stream to update segment for
         * @param {CreatePlaylistSegment} createPlaylistSegment the updated segment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLiveSegment: async (liveStreamId: number, createPlaylistSegment: CreatePlaylistSegment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'liveStreamId' is not null or undefined
            assertParamExists('updateLiveSegment', 'liveStreamId', liveStreamId)
            // verify required parameter 'createPlaylistSegment' is not null or undefined
            assertParamExists('updateLiveSegment', 'createPlaylistSegment', createPlaylistSegment)
            const localVarPath = `/v1.0/live_streams/{live_stream_id}/segments`
                .replace(`{${"live_stream_id"}}`, encodeURIComponent(String(liveStreamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlaylistSegment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} liveStreamId the live stream to update
         * @param {TacticalPitch} tacticalPitch the tactical pitch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLiveStreamTacticalPitch: async (liveStreamId: number, tacticalPitch: TacticalPitch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'liveStreamId' is not null or undefined
            assertParamExists('updateLiveStreamTacticalPitch', 'liveStreamId', liveStreamId)
            // verify required parameter 'tacticalPitch' is not null or undefined
            assertParamExists('updateLiveStreamTacticalPitch', 'tacticalPitch', tacticalPitch)
            const localVarPath = `/v1.0/live_streams/{live_stream_id}/tactical_pitch`
                .replace(`{${"live_stream_id"}}`, encodeURIComponent(String(liveStreamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tacticalPitch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1liveStreamApi - functional programming interface
 * @export
 */
export const V1liveStreamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1liveStreamApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} liveStreamId the live stream to create message for
         * @param {CreateLiveMessage} createLiveMessage the message to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLiveMessage(liveStreamId: number, createLiveMessage: CreateLiveMessage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LiveMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLiveMessage(liveStreamId, createLiveMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} liveStreamId the live stream to create segment for
         * @param {CreatePlaylistSegment} createPlaylistSegment the segment to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLiveSegment(liveStreamId: number, createPlaylistSegment: CreatePlaylistSegment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaylistSegment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLiveSegment(liveStreamId, createPlaylistSegment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateLiveStream} createLiveStream 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLiveStream(createLiveStream: CreateLiveStream, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLiveStream(createLiveStream, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} liveStreamId 
         * @param {number} playlistSegmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLiveSegment(liveStreamId: number, playlistSegmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLiveSegment(liveStreamId, playlistSegmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [live] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLiveStreams(live?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LiveStream>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLiveStreams(live, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} liveStreamId the live stream to join
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async joinLiveStream(liveStreamId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LiveStreamEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.joinLiveStream(liveStreamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} liveStreamId the live stream to create segment for
         * @param {number} playlistSegmentId the segment to send
         * @param {string} userId the user to send the segment to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendLiveSegment(liveStreamId: number, playlistSegmentId: number, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendLiveSegment(liveStreamId, playlistSegmentId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} liveStreamId the live stream to update
         * @param {SetLiveStreamMode} setLiveStreamMode the channel and mode to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setLiveStreamMode(liveStreamId: number, setLiveStreamMode: SetLiveStreamMode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setLiveStreamMode(liveStreamId, setLiveStreamMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} liveStreamId the live stream to update
         * @param {SetLiveStreamThumbnail} setLiveStreamThumbnail the thumbnail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setLiveStreamThumbnail(liveStreamId: number, setLiveStreamThumbnail: SetLiveStreamThumbnail, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setLiveStreamThumbnail(liveStreamId, setLiveStreamThumbnail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} liveStreamId the live stream to update segment for
         * @param {CreatePlaylistSegment} createPlaylistSegment the updated segment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLiveSegment(liveStreamId: number, createPlaylistSegment: CreatePlaylistSegment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaylistSegment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLiveSegment(liveStreamId, createPlaylistSegment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} liveStreamId the live stream to update
         * @param {TacticalPitch} tacticalPitch the tactical pitch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLiveStreamTacticalPitch(liveStreamId: number, tacticalPitch: TacticalPitch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLiveStreamTacticalPitch(liveStreamId, tacticalPitch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1liveStreamApi - factory interface
 * @export
 */
export const V1liveStreamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1liveStreamApiFp(configuration)
    return {
        /**
         * 
         * @param {V1liveStreamApiCreateLiveMessageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLiveMessage(requestParameters: V1liveStreamApiCreateLiveMessageRequest, options?: AxiosRequestConfig): AxiosPromise<LiveMessage> {
            return localVarFp.createLiveMessage(requestParameters.liveStreamId, requestParameters.createLiveMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1liveStreamApiCreateLiveSegmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLiveSegment(requestParameters: V1liveStreamApiCreateLiveSegmentRequest, options?: AxiosRequestConfig): AxiosPromise<PlaylistSegment> {
            return localVarFp.createLiveSegment(requestParameters.liveStreamId, requestParameters.createPlaylistSegment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1liveStreamApiCreateLiveStreamRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLiveStream(requestParameters: V1liveStreamApiCreateLiveStreamRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.createLiveStream(requestParameters.createLiveStream, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1liveStreamApiDeleteLiveSegmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLiveSegment(requestParameters: V1liveStreamApiDeleteLiveSegmentRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteLiveSegment(requestParameters.liveStreamId, requestParameters.playlistSegmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1liveStreamApiGetLiveStreamsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveStreams(requestParameters: V1liveStreamApiGetLiveStreamsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<LiveStream>> {
            return localVarFp.getLiveStreams(requestParameters.live, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1liveStreamApiJoinLiveStreamRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinLiveStream(requestParameters: V1liveStreamApiJoinLiveStreamRequest, options?: AxiosRequestConfig): AxiosPromise<LiveStreamEvent> {
            return localVarFp.joinLiveStream(requestParameters.liveStreamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1liveStreamApiSendLiveSegmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendLiveSegment(requestParameters: V1liveStreamApiSendLiveSegmentRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sendLiveSegment(requestParameters.liveStreamId, requestParameters.playlistSegmentId, requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1liveStreamApiSetLiveStreamModeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLiveStreamMode(requestParameters: V1liveStreamApiSetLiveStreamModeRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.setLiveStreamMode(requestParameters.liveStreamId, requestParameters.setLiveStreamMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1liveStreamApiSetLiveStreamThumbnailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLiveStreamThumbnail(requestParameters: V1liveStreamApiSetLiveStreamThumbnailRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.setLiveStreamThumbnail(requestParameters.liveStreamId, requestParameters.setLiveStreamThumbnail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1liveStreamApiUpdateLiveSegmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLiveSegment(requestParameters: V1liveStreamApiUpdateLiveSegmentRequest, options?: AxiosRequestConfig): AxiosPromise<PlaylistSegment> {
            return localVarFp.updateLiveSegment(requestParameters.liveStreamId, requestParameters.createPlaylistSegment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1liveStreamApiUpdateLiveStreamTacticalPitchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLiveStreamTacticalPitch(requestParameters: V1liveStreamApiUpdateLiveStreamTacticalPitchRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateLiveStreamTacticalPitch(requestParameters.liveStreamId, requestParameters.tacticalPitch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createLiveMessage operation in V1liveStreamApi.
 * @export
 * @interface V1liveStreamApiCreateLiveMessageRequest
 */
export interface V1liveStreamApiCreateLiveMessageRequest {
    /**
     * the live stream to create message for
     * @type {number}
     * @memberof V1liveStreamApiCreateLiveMessage
     */
    readonly liveStreamId: number

    /**
     * the message to create
     * @type {CreateLiveMessage}
     * @memberof V1liveStreamApiCreateLiveMessage
     */
    readonly createLiveMessage: CreateLiveMessage
}

/**
 * Request parameters for createLiveSegment operation in V1liveStreamApi.
 * @export
 * @interface V1liveStreamApiCreateLiveSegmentRequest
 */
export interface V1liveStreamApiCreateLiveSegmentRequest {
    /**
     * the live stream to create segment for
     * @type {number}
     * @memberof V1liveStreamApiCreateLiveSegment
     */
    readonly liveStreamId: number

    /**
     * the segment to create
     * @type {CreatePlaylistSegment}
     * @memberof V1liveStreamApiCreateLiveSegment
     */
    readonly createPlaylistSegment: CreatePlaylistSegment
}

/**
 * Request parameters for createLiveStream operation in V1liveStreamApi.
 * @export
 * @interface V1liveStreamApiCreateLiveStreamRequest
 */
export interface V1liveStreamApiCreateLiveStreamRequest {
    /**
     * 
     * @type {CreateLiveStream}
     * @memberof V1liveStreamApiCreateLiveStream
     */
    readonly createLiveStream: CreateLiveStream
}

/**
 * Request parameters for deleteLiveSegment operation in V1liveStreamApi.
 * @export
 * @interface V1liveStreamApiDeleteLiveSegmentRequest
 */
export interface V1liveStreamApiDeleteLiveSegmentRequest {
    /**
     * 
     * @type {number}
     * @memberof V1liveStreamApiDeleteLiveSegment
     */
    readonly liveStreamId: number

    /**
     * 
     * @type {number}
     * @memberof V1liveStreamApiDeleteLiveSegment
     */
    readonly playlistSegmentId: number
}

/**
 * Request parameters for getLiveStreams operation in V1liveStreamApi.
 * @export
 * @interface V1liveStreamApiGetLiveStreamsRequest
 */
export interface V1liveStreamApiGetLiveStreamsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof V1liveStreamApiGetLiveStreams
     */
    readonly live?: boolean
}

/**
 * Request parameters for joinLiveStream operation in V1liveStreamApi.
 * @export
 * @interface V1liveStreamApiJoinLiveStreamRequest
 */
export interface V1liveStreamApiJoinLiveStreamRequest {
    /**
     * the live stream to join
     * @type {number}
     * @memberof V1liveStreamApiJoinLiveStream
     */
    readonly liveStreamId: number
}

/**
 * Request parameters for sendLiveSegment operation in V1liveStreamApi.
 * @export
 * @interface V1liveStreamApiSendLiveSegmentRequest
 */
export interface V1liveStreamApiSendLiveSegmentRequest {
    /**
     * the live stream to create segment for
     * @type {number}
     * @memberof V1liveStreamApiSendLiveSegment
     */
    readonly liveStreamId: number

    /**
     * the segment to send
     * @type {number}
     * @memberof V1liveStreamApiSendLiveSegment
     */
    readonly playlistSegmentId: number

    /**
     * the user to send the segment to
     * @type {string}
     * @memberof V1liveStreamApiSendLiveSegment
     */
    readonly userId: string
}

/**
 * Request parameters for setLiveStreamMode operation in V1liveStreamApi.
 * @export
 * @interface V1liveStreamApiSetLiveStreamModeRequest
 */
export interface V1liveStreamApiSetLiveStreamModeRequest {
    /**
     * the live stream to update
     * @type {number}
     * @memberof V1liveStreamApiSetLiveStreamMode
     */
    readonly liveStreamId: number

    /**
     * the channel and mode to set
     * @type {SetLiveStreamMode}
     * @memberof V1liveStreamApiSetLiveStreamMode
     */
    readonly setLiveStreamMode: SetLiveStreamMode
}

/**
 * Request parameters for setLiveStreamThumbnail operation in V1liveStreamApi.
 * @export
 * @interface V1liveStreamApiSetLiveStreamThumbnailRequest
 */
export interface V1liveStreamApiSetLiveStreamThumbnailRequest {
    /**
     * the live stream to update
     * @type {number}
     * @memberof V1liveStreamApiSetLiveStreamThumbnail
     */
    readonly liveStreamId: number

    /**
     * the thumbnail
     * @type {SetLiveStreamThumbnail}
     * @memberof V1liveStreamApiSetLiveStreamThumbnail
     */
    readonly setLiveStreamThumbnail: SetLiveStreamThumbnail
}

/**
 * Request parameters for updateLiveSegment operation in V1liveStreamApi.
 * @export
 * @interface V1liveStreamApiUpdateLiveSegmentRequest
 */
export interface V1liveStreamApiUpdateLiveSegmentRequest {
    /**
     * the live stream to update segment for
     * @type {number}
     * @memberof V1liveStreamApiUpdateLiveSegment
     */
    readonly liveStreamId: number

    /**
     * the updated segment
     * @type {CreatePlaylistSegment}
     * @memberof V1liveStreamApiUpdateLiveSegment
     */
    readonly createPlaylistSegment: CreatePlaylistSegment
}

/**
 * Request parameters for updateLiveStreamTacticalPitch operation in V1liveStreamApi.
 * @export
 * @interface V1liveStreamApiUpdateLiveStreamTacticalPitchRequest
 */
export interface V1liveStreamApiUpdateLiveStreamTacticalPitchRequest {
    /**
     * the live stream to update
     * @type {number}
     * @memberof V1liveStreamApiUpdateLiveStreamTacticalPitch
     */
    readonly liveStreamId: number

    /**
     * the tactical pitch
     * @type {TacticalPitch}
     * @memberof V1liveStreamApiUpdateLiveStreamTacticalPitch
     */
    readonly tacticalPitch: TacticalPitch
}

/**
 * V1liveStreamApi - object-oriented interface
 * @export
 * @class V1liveStreamApi
 * @extends {BaseAPI}
 */
export class V1liveStreamApi extends BaseAPI {
    /**
     * 
     * @param {V1liveStreamApiCreateLiveMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1liveStreamApi
     */
    public createLiveMessage(requestParameters: V1liveStreamApiCreateLiveMessageRequest, options?: AxiosRequestConfig) {
        return V1liveStreamApiFp(this.configuration).createLiveMessage(requestParameters.liveStreamId, requestParameters.createLiveMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1liveStreamApiCreateLiveSegmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1liveStreamApi
     */
    public createLiveSegment(requestParameters: V1liveStreamApiCreateLiveSegmentRequest, options?: AxiosRequestConfig) {
        return V1liveStreamApiFp(this.configuration).createLiveSegment(requestParameters.liveStreamId, requestParameters.createPlaylistSegment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1liveStreamApiCreateLiveStreamRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1liveStreamApi
     */
    public createLiveStream(requestParameters: V1liveStreamApiCreateLiveStreamRequest, options?: AxiosRequestConfig) {
        return V1liveStreamApiFp(this.configuration).createLiveStream(requestParameters.createLiveStream, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1liveStreamApiDeleteLiveSegmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1liveStreamApi
     */
    public deleteLiveSegment(requestParameters: V1liveStreamApiDeleteLiveSegmentRequest, options?: AxiosRequestConfig) {
        return V1liveStreamApiFp(this.configuration).deleteLiveSegment(requestParameters.liveStreamId, requestParameters.playlistSegmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1liveStreamApiGetLiveStreamsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1liveStreamApi
     */
    public getLiveStreams(requestParameters: V1liveStreamApiGetLiveStreamsRequest = {}, options?: AxiosRequestConfig) {
        return V1liveStreamApiFp(this.configuration).getLiveStreams(requestParameters.live, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1liveStreamApiJoinLiveStreamRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1liveStreamApi
     */
    public joinLiveStream(requestParameters: V1liveStreamApiJoinLiveStreamRequest, options?: AxiosRequestConfig) {
        return V1liveStreamApiFp(this.configuration).joinLiveStream(requestParameters.liveStreamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1liveStreamApiSendLiveSegmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1liveStreamApi
     */
    public sendLiveSegment(requestParameters: V1liveStreamApiSendLiveSegmentRequest, options?: AxiosRequestConfig) {
        return V1liveStreamApiFp(this.configuration).sendLiveSegment(requestParameters.liveStreamId, requestParameters.playlistSegmentId, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1liveStreamApiSetLiveStreamModeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1liveStreamApi
     */
    public setLiveStreamMode(requestParameters: V1liveStreamApiSetLiveStreamModeRequest, options?: AxiosRequestConfig) {
        return V1liveStreamApiFp(this.configuration).setLiveStreamMode(requestParameters.liveStreamId, requestParameters.setLiveStreamMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1liveStreamApiSetLiveStreamThumbnailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1liveStreamApi
     */
    public setLiveStreamThumbnail(requestParameters: V1liveStreamApiSetLiveStreamThumbnailRequest, options?: AxiosRequestConfig) {
        return V1liveStreamApiFp(this.configuration).setLiveStreamThumbnail(requestParameters.liveStreamId, requestParameters.setLiveStreamThumbnail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1liveStreamApiUpdateLiveSegmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1liveStreamApi
     */
    public updateLiveSegment(requestParameters: V1liveStreamApiUpdateLiveSegmentRequest, options?: AxiosRequestConfig) {
        return V1liveStreamApiFp(this.configuration).updateLiveSegment(requestParameters.liveStreamId, requestParameters.createPlaylistSegment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1liveStreamApiUpdateLiveStreamTacticalPitchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1liveStreamApi
     */
    public updateLiveStreamTacticalPitch(requestParameters: V1liveStreamApiUpdateLiveStreamTacticalPitchRequest, options?: AxiosRequestConfig) {
        return V1liveStreamApiFp(this.configuration).updateLiveStreamTacticalPitch(requestParameters.liveStreamId, requestParameters.tacticalPitch, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1orgApi - axios parameter creator
 * @export
 */
export const V1orgApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOrg} createOrg the org to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrg: async (createOrg: CreateOrg, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrg' is not null or undefined
            assertParamExists('createOrg', 'createOrg', createOrg)
            const localVarPath = `/v1.0/orgs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrg, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrg: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/orgs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrg: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/orgs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId the user to kick
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kickUserFromOrg: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('kickUserFromOrg', 'userId', userId)
            const localVarPath = `/v1.0/orgs/kick/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leaveOrg: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/orgs/leave`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrg} createOrg the updated org object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrg: async (createOrg: CreateOrg, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrg' is not null or undefined
            assertParamExists('updateOrg', 'createOrg', createOrg)
            const localVarPath = `/v1.0/orgs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrg, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1orgApi - functional programming interface
 * @export
 */
export const V1orgApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1orgApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateOrg} createOrg the org to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrg(createOrg: CreateOrg, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Org>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrg(createOrg, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrg(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrg(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrg(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgDetailed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrg(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId the user to kick
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kickUserFromOrg(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kickUserFromOrg(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leaveOrg(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leaveOrg(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateOrg} createOrg the updated org object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrg(createOrg: CreateOrg, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrg(createOrg, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1orgApi - factory interface
 * @export
 */
export const V1orgApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1orgApiFp(configuration)
    return {
        /**
         * 
         * @param {V1orgApiCreateOrgRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrg(requestParameters: V1orgApiCreateOrgRequest, options?: AxiosRequestConfig): AxiosPromise<Org> {
            return localVarFp.createOrg(requestParameters.createOrg, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrg(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteOrg(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrg(options?: AxiosRequestConfig): AxiosPromise<OrgDetailed> {
            return localVarFp.getOrg(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1orgApiKickUserFromOrgRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kickUserFromOrg(requestParameters: V1orgApiKickUserFromOrgRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.kickUserFromOrg(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leaveOrg(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.leaveOrg(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1orgApiUpdateOrgRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrg(requestParameters: V1orgApiUpdateOrgRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateOrg(requestParameters.createOrg, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createOrg operation in V1orgApi.
 * @export
 * @interface V1orgApiCreateOrgRequest
 */
export interface V1orgApiCreateOrgRequest {
    /**
     * the org to create
     * @type {CreateOrg}
     * @memberof V1orgApiCreateOrg
     */
    readonly createOrg: CreateOrg
}

/**
 * Request parameters for kickUserFromOrg operation in V1orgApi.
 * @export
 * @interface V1orgApiKickUserFromOrgRequest
 */
export interface V1orgApiKickUserFromOrgRequest {
    /**
     * the user to kick
     * @type {string}
     * @memberof V1orgApiKickUserFromOrg
     */
    readonly userId: string
}

/**
 * Request parameters for updateOrg operation in V1orgApi.
 * @export
 * @interface V1orgApiUpdateOrgRequest
 */
export interface V1orgApiUpdateOrgRequest {
    /**
     * the updated org object
     * @type {CreateOrg}
     * @memberof V1orgApiUpdateOrg
     */
    readonly createOrg: CreateOrg
}

/**
 * V1orgApi - object-oriented interface
 * @export
 * @class V1orgApi
 * @extends {BaseAPI}
 */
export class V1orgApi extends BaseAPI {
    /**
     * 
     * @param {V1orgApiCreateOrgRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1orgApi
     */
    public createOrg(requestParameters: V1orgApiCreateOrgRequest, options?: AxiosRequestConfig) {
        return V1orgApiFp(this.configuration).createOrg(requestParameters.createOrg, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1orgApi
     */
    public deleteOrg(options?: AxiosRequestConfig) {
        return V1orgApiFp(this.configuration).deleteOrg(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1orgApi
     */
    public getOrg(options?: AxiosRequestConfig) {
        return V1orgApiFp(this.configuration).getOrg(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1orgApiKickUserFromOrgRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1orgApi
     */
    public kickUserFromOrg(requestParameters: V1orgApiKickUserFromOrgRequest, options?: AxiosRequestConfig) {
        return V1orgApiFp(this.configuration).kickUserFromOrg(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1orgApi
     */
    public leaveOrg(options?: AxiosRequestConfig) {
        return V1orgApiFp(this.configuration).leaveOrg(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1orgApiUpdateOrgRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1orgApi
     */
    public updateOrg(requestParameters: V1orgApiUpdateOrgRequest, options?: AxiosRequestConfig) {
        return V1orgApiFp(this.configuration).updateOrg(requestParameters.createOrg, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1panoramaApi - axios parameter creator
 * @export
 */
export const V1panoramaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} forzaVideoAssetId the video asset to retrieve the panorama option for
         * @param {number} timestamp the timestamp of the frame to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPanoramaOption: async (forzaVideoAssetId: number, timestamp: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forzaVideoAssetId' is not null or undefined
            assertParamExists('getPanoramaOption', 'forzaVideoAssetId', forzaVideoAssetId)
            // verify required parameter 'timestamp' is not null or undefined
            assertParamExists('getPanoramaOption', 'timestamp', timestamp)
            const localVarPath = `/v1.0/panorama/{forza_video_asset_id}/options/{timestamp}`
                .replace(`{${"forza_video_asset_id"}}`, encodeURIComponent(String(forzaVideoAssetId)))
                .replace(`{${"timestamp"}}`, encodeURIComponent(String(timestamp)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forzaVideoAssetId the video asset to stitch panorama for
         * @param {StitchPanorama} stitchPanorama the images to stitch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stitchPanorama: async (forzaVideoAssetId: number, stitchPanorama: StitchPanorama, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forzaVideoAssetId' is not null or undefined
            assertParamExists('stitchPanorama', 'forzaVideoAssetId', forzaVideoAssetId)
            // verify required parameter 'stitchPanorama' is not null or undefined
            assertParamExists('stitchPanorama', 'stitchPanorama', stitchPanorama)
            const localVarPath = `/v1.0/panorama/{forza_video_asset_id}/stitch`
                .replace(`{${"forza_video_asset_id"}}`, encodeURIComponent(String(forzaVideoAssetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stitchPanorama, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1panoramaApi - functional programming interface
 * @export
 */
export const V1panoramaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1panoramaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} forzaVideoAssetId the video asset to retrieve the panorama option for
         * @param {number} timestamp the timestamp of the frame to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPanoramaOption(forzaVideoAssetId: number, timestamp: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPanoramaOption(forzaVideoAssetId, timestamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forzaVideoAssetId the video asset to stitch panorama for
         * @param {StitchPanorama} stitchPanorama the images to stitch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stitchPanorama(forzaVideoAssetId: number, stitchPanorama: StitchPanorama, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Panorama>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stitchPanorama(forzaVideoAssetId, stitchPanorama, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1panoramaApi - factory interface
 * @export
 */
export const V1panoramaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1panoramaApiFp(configuration)
    return {
        /**
         * 
         * @param {V1panoramaApiGetPanoramaOptionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPanoramaOption(requestParameters: V1panoramaApiGetPanoramaOptionRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getPanoramaOption(requestParameters.forzaVideoAssetId, requestParameters.timestamp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1panoramaApiStitchPanoramaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stitchPanorama(requestParameters: V1panoramaApiStitchPanoramaRequest, options?: AxiosRequestConfig): AxiosPromise<Panorama> {
            return localVarFp.stitchPanorama(requestParameters.forzaVideoAssetId, requestParameters.stitchPanorama, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getPanoramaOption operation in V1panoramaApi.
 * @export
 * @interface V1panoramaApiGetPanoramaOptionRequest
 */
export interface V1panoramaApiGetPanoramaOptionRequest {
    /**
     * the video asset to retrieve the panorama option for
     * @type {number}
     * @memberof V1panoramaApiGetPanoramaOption
     */
    readonly forzaVideoAssetId: number

    /**
     * the timestamp of the frame to get
     * @type {number}
     * @memberof V1panoramaApiGetPanoramaOption
     */
    readonly timestamp: number
}

/**
 * Request parameters for stitchPanorama operation in V1panoramaApi.
 * @export
 * @interface V1panoramaApiStitchPanoramaRequest
 */
export interface V1panoramaApiStitchPanoramaRequest {
    /**
     * the video asset to stitch panorama for
     * @type {number}
     * @memberof V1panoramaApiStitchPanorama
     */
    readonly forzaVideoAssetId: number

    /**
     * the images to stitch
     * @type {StitchPanorama}
     * @memberof V1panoramaApiStitchPanorama
     */
    readonly stitchPanorama: StitchPanorama
}

/**
 * V1panoramaApi - object-oriented interface
 * @export
 * @class V1panoramaApi
 * @extends {BaseAPI}
 */
export class V1panoramaApi extends BaseAPI {
    /**
     * 
     * @param {V1panoramaApiGetPanoramaOptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1panoramaApi
     */
    public getPanoramaOption(requestParameters: V1panoramaApiGetPanoramaOptionRequest, options?: AxiosRequestConfig) {
        return V1panoramaApiFp(this.configuration).getPanoramaOption(requestParameters.forzaVideoAssetId, requestParameters.timestamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1panoramaApiStitchPanoramaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1panoramaApi
     */
    public stitchPanorama(requestParameters: V1panoramaApiStitchPanoramaRequest, options?: AxiosRequestConfig) {
        return V1panoramaApiFp(this.configuration).stitchPanorama(requestParameters.forzaVideoAssetId, requestParameters.stitchPanorama, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1playerApi - axios parameter creator
 * @export
 */
export const V1playerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId the player to update
         * @param {UpdatePlayer} updatePlayer the updated player object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlayer: async (userId: string, updatePlayer: UpdatePlayer, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updatePlayer', 'userId', userId)
            // verify required parameter 'updatePlayer' is not null or undefined
            assertParamExists('updatePlayer', 'updatePlayer', updatePlayer)
            const localVarPath = `/v1.0/players/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePlayer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1playerApi - functional programming interface
 * @export
 */
export const V1playerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1playerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userId the player to update
         * @param {UpdatePlayer} updatePlayer the updated player object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePlayer(userId: string, updatePlayer: UpdatePlayer, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePlayer(userId, updatePlayer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1playerApi - factory interface
 * @export
 */
export const V1playerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1playerApiFp(configuration)
    return {
        /**
         * 
         * @param {V1playerApiUpdatePlayerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlayer(requestParameters: V1playerApiUpdatePlayerRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updatePlayer(requestParameters.userId, requestParameters.updatePlayer, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for updatePlayer operation in V1playerApi.
 * @export
 * @interface V1playerApiUpdatePlayerRequest
 */
export interface V1playerApiUpdatePlayerRequest {
    /**
     * the player to update
     * @type {string}
     * @memberof V1playerApiUpdatePlayer
     */
    readonly userId: string

    /**
     * the updated player object
     * @type {UpdatePlayer}
     * @memberof V1playerApiUpdatePlayer
     */
    readonly updatePlayer: UpdatePlayer
}

/**
 * V1playerApi - object-oriented interface
 * @export
 * @class V1playerApi
 * @extends {BaseAPI}
 */
export class V1playerApi extends BaseAPI {
    /**
     * 
     * @param {V1playerApiUpdatePlayerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1playerApi
     */
    public updatePlayer(requestParameters: V1playerApiUpdatePlayerRequest, options?: AxiosRequestConfig) {
        return V1playerApiFp(this.configuration).updatePlayer(requestParameters.userId, requestParameters.updatePlayer, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1playerDetectionApi - axios parameter creator
 * @export
 */
export const V1playerDetectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} gameId the game id the image originates from
         * @param {Homography} homography the image to perform player detection on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homography: async (gameId: number, homography: Homography, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            assertParamExists('homography', 'gameId', gameId)
            // verify required parameter 'homography' is not null or undefined
            assertParamExists('homography', 'homography', homography)
            const localVarPath = `/v1.0/player_detection/{game_id}`
                .replace(`{${"game_id"}}`, encodeURIComponent(String(gameId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(homography, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PlayerDetection} playerDetection the image to perform player detection on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerDetection: async (playerDetection: PlayerDetection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'playerDetection' is not null or undefined
            assertParamExists('playerDetection', 'playerDetection', playerDetection)
            const localVarPath = `/v1.0/player_detection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(playerDetection, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetTemplateReferencePoints} setTemplateReferencePoints the template points to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTemplateReferencePoints: async (setTemplateReferencePoints: SetTemplateReferencePoints, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setTemplateReferencePoints' is not null or undefined
            assertParamExists('setTemplateReferencePoints', 'setTemplateReferencePoints', setTemplateReferencePoints)
            const localVarPath = `/v1.0/player_detection/set_template_reference_points`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setTemplateReferencePoints, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1playerDetectionApi - functional programming interface
 * @export
 */
export const V1playerDetectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1playerDetectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} gameId the game id the image originates from
         * @param {Homography} homography the image to perform player detection on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async homography(gameId: number, homography: Homography, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomographyResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.homography(gameId, homography, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PlayerDetection} playerDetection the image to perform player detection on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playerDetection(playerDetection: PlayerDetection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlayerBoundingBox>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playerDetection(playerDetection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetTemplateReferencePoints} setTemplateReferencePoints the template points to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTemplateReferencePoints(setTemplateReferencePoints: SetTemplateReferencePoints, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTemplateReferencePoints(setTemplateReferencePoints, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1playerDetectionApi - factory interface
 * @export
 */
export const V1playerDetectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1playerDetectionApiFp(configuration)
    return {
        /**
         * 
         * @param {V1playerDetectionApiHomographyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homography(requestParameters: V1playerDetectionApiHomographyRequest, options?: AxiosRequestConfig): AxiosPromise<HomographyResult> {
            return localVarFp.homography(requestParameters.gameId, requestParameters.homography, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1playerDetectionApiPlayerDetectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerDetection(requestParameters: V1playerDetectionApiPlayerDetectionRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PlayerBoundingBox>> {
            return localVarFp.playerDetection(requestParameters.playerDetection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1playerDetectionApiSetTemplateReferencePointsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTemplateReferencePoints(requestParameters: V1playerDetectionApiSetTemplateReferencePointsRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.setTemplateReferencePoints(requestParameters.setTemplateReferencePoints, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for homography operation in V1playerDetectionApi.
 * @export
 * @interface V1playerDetectionApiHomographyRequest
 */
export interface V1playerDetectionApiHomographyRequest {
    /**
     * the game id the image originates from
     * @type {number}
     * @memberof V1playerDetectionApiHomography
     */
    readonly gameId: number

    /**
     * the image to perform player detection on
     * @type {Homography}
     * @memberof V1playerDetectionApiHomography
     */
    readonly homography: Homography
}

/**
 * Request parameters for playerDetection operation in V1playerDetectionApi.
 * @export
 * @interface V1playerDetectionApiPlayerDetectionRequest
 */
export interface V1playerDetectionApiPlayerDetectionRequest {
    /**
     * the image to perform player detection on
     * @type {PlayerDetection}
     * @memberof V1playerDetectionApiPlayerDetection
     */
    readonly playerDetection: PlayerDetection
}

/**
 * Request parameters for setTemplateReferencePoints operation in V1playerDetectionApi.
 * @export
 * @interface V1playerDetectionApiSetTemplateReferencePointsRequest
 */
export interface V1playerDetectionApiSetTemplateReferencePointsRequest {
    /**
     * the template points to store
     * @type {SetTemplateReferencePoints}
     * @memberof V1playerDetectionApiSetTemplateReferencePoints
     */
    readonly setTemplateReferencePoints: SetTemplateReferencePoints
}

/**
 * V1playerDetectionApi - object-oriented interface
 * @export
 * @class V1playerDetectionApi
 * @extends {BaseAPI}
 */
export class V1playerDetectionApi extends BaseAPI {
    /**
     * 
     * @param {V1playerDetectionApiHomographyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1playerDetectionApi
     */
    public homography(requestParameters: V1playerDetectionApiHomographyRequest, options?: AxiosRequestConfig) {
        return V1playerDetectionApiFp(this.configuration).homography(requestParameters.gameId, requestParameters.homography, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1playerDetectionApiPlayerDetectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1playerDetectionApi
     */
    public playerDetection(requestParameters: V1playerDetectionApiPlayerDetectionRequest, options?: AxiosRequestConfig) {
        return V1playerDetectionApiFp(this.configuration).playerDetection(requestParameters.playerDetection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1playerDetectionApiSetTemplateReferencePointsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1playerDetectionApi
     */
    public setTemplateReferencePoints(requestParameters: V1playerDetectionApiSetTemplateReferencePointsRequest, options?: AxiosRequestConfig) {
        return V1playerDetectionApiFp(this.configuration).setTemplateReferencePoints(requestParameters.setTemplateReferencePoints, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1playlistApi - axios parameter creator
 * @export
 */
export const V1playlistApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePlaylist} createPlaylist the playlist to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlaylist: async (createPlaylist: CreatePlaylist, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPlaylist' is not null or undefined
            assertParamExists('createPlaylist', 'createPlaylist', createPlaylist)
            const localVarPath = `/v1.0/playlists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlaylist, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} playlistId the playlist to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlaylist: async (playlistId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'playlistId' is not null or undefined
            assertParamExists('deletePlaylist', 'playlistId', playlistId)
            const localVarPath = `/v1.0/playlists/{playlist_id}`
                .replace(`{${"playlist_id"}}`, encodeURIComponent(String(playlistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} playlistId the playlist to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylist: async (playlistId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'playlistId' is not null or undefined
            assertParamExists('getPlaylist', 'playlistId', playlistId)
            const localVarPath = `/v1.0/playlists/{playlist_id}`
                .replace(`{${"playlist_id"}}`, encodeURIComponent(String(playlistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} playlistId the playlist to get exports for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistExports: async (playlistId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'playlistId' is not null or undefined
            assertParamExists('getPlaylistExports', 'playlistId', playlistId)
            const localVarPath = `/v1.0/playlists/{playlist_id}/export`
                .replace(`{${"playlist_id"}}`, encodeURIComponent(String(playlistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} teamId the team to retrieve playlist segments for
         * @param {Array<number>} [ids] 
         * @param {Array<number>} [tags] 
         * @param {number} [footballFieldLeftTeamId] 
         * @param {number} [footballFieldRightTeamId] 
         * @param {Array<string>} [footballFieldGrids] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {Array<string>} [playerTags] 
         * @param {Array<number>} [forzaTeamTags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSegments: async (teamId: number, ids?: Array<number>, tags?: Array<number>, footballFieldLeftTeamId?: number, footballFieldRightTeamId?: number, footballFieldGrids?: Array<string>, limit?: number, offset?: number, playerTags?: Array<string>, forzaTeamTags?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('getPlaylistSegments', 'teamId', teamId)
            const localVarPath = `/v1.0/teams/{team_id}/playlist_segments`
                .replace(`{${"team_id"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (ids) {
                localVarQueryParameter['ids[]'] = ids;
            }

            if (tags) {
                localVarQueryParameter['tags[]'] = tags;
            }

            if (footballFieldLeftTeamId !== undefined) {
                localVarQueryParameter['footballFieldLeftTeamId'] = footballFieldLeftTeamId;
            }

            if (footballFieldRightTeamId !== undefined) {
                localVarQueryParameter['footballFieldRightTeamId'] = footballFieldRightTeamId;
            }

            if (footballFieldGrids) {
                localVarQueryParameter['footballFieldGrids[]'] = footballFieldGrids;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (playerTags) {
                localVarQueryParameter['playerTags[]'] = playerTags;
            }

            if (forzaTeamTags) {
                localVarQueryParameter['forzaTeamTags[]'] = forzaTeamTags;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} playlistVideoId the playlist video to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistVideo: async (playlistVideoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'playlistVideoId' is not null or undefined
            assertParamExists('getPlaylistVideo', 'playlistVideoId', playlistVideoId)
            const localVarPath = `/v1.0/playlists/videos/{playlist_video_id}`
                .replace(`{${"playlist_video_id"}}`, encodeURIComponent(String(playlistVideoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} teamId the team to retrieve playlists for
         * @param {Array<number>} [tags] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {Array<string>} [playerTags] 
         * @param {string} [creator] 
         * @param {Array<number>} [forzaTeamTags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylists: async (teamId: number, tags?: Array<number>, limit?: number, offset?: number, playerTags?: Array<string>, creator?: string, forzaTeamTags?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('getPlaylists', 'teamId', teamId)
            const localVarPath = `/v1.0/teams/{team_id}/playlists`
                .replace(`{${"team_id"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (tags) {
                localVarQueryParameter['tags[]'] = tags;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (playerTags) {
                localVarQueryParameter['playerTags[]'] = playerTags;
            }

            if (creator !== undefined) {
                localVarQueryParameter['creator'] = creator;
            }

            if (forzaTeamTags) {
                localVarQueryParameter['forzaTeamTags[]'] = forzaTeamTags;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} teamId the team to retrieve playlists for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistsShared: async (teamId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('getPlaylistsShared', 'teamId', teamId)
            const localVarPath = `/v1.0/teams/{team_id}/playlists/shared`
                .replace(`{${"team_id"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} playlistId the playlist to compute homography for
         * @param {PlaylistSegmentHomography} playlistSegmentHomography the segments to generate homography for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playlistGenerateHomography: async (playlistId: number, playlistSegmentHomography: PlaylistSegmentHomography, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'playlistId' is not null or undefined
            assertParamExists('playlistGenerateHomography', 'playlistId', playlistId)
            // verify required parameter 'playlistSegmentHomography' is not null or undefined
            assertParamExists('playlistGenerateHomography', 'playlistSegmentHomography', playlistSegmentHomography)
            const localVarPath = `/v1.0/playlists/{playlist_id}/generate_homography`
                .replace(`{${"playlist_id"}}`, encodeURIComponent(String(playlistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(playlistSegmentHomography, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} playlistId the playlist to start exporting
         * @param {CreatePlaylistExport} createPlaylistExport details of the export
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startPlaylistExport: async (playlistId: number, createPlaylistExport: CreatePlaylistExport, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'playlistId' is not null or undefined
            assertParamExists('startPlaylistExport', 'playlistId', playlistId)
            // verify required parameter 'createPlaylistExport' is not null or undefined
            assertParamExists('startPlaylistExport', 'createPlaylistExport', createPlaylistExport)
            const localVarPath = `/v1.0/playlists/{playlist_id}/export`
                .replace(`{${"playlist_id"}}`, encodeURIComponent(String(playlistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlaylistExport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} playlistId the playlist to update
         * @param {CreatePlaylist} createPlaylist the updated playlist
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlaylist: async (playlistId: number, createPlaylist: CreatePlaylist, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'playlistId' is not null or undefined
            assertParamExists('updatePlaylist', 'playlistId', playlistId)
            // verify required parameter 'createPlaylist' is not null or undefined
            assertParamExists('updatePlaylist', 'createPlaylist', createPlaylist)
            const localVarPath = `/v1.0/playlists/{playlist_id}`
                .replace(`{${"playlist_id"}}`, encodeURIComponent(String(playlistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlaylist, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1playlistApi - functional programming interface
 * @export
 */
export const V1playlistApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1playlistApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePlaylist} createPlaylist the playlist to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPlaylist(createPlaylist: CreatePlaylist, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPlaylist(createPlaylist, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} playlistId the playlist to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePlaylist(playlistId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePlaylist(playlistId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} playlistId the playlist to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlaylist(playlistId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Playlist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlaylist(playlistId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} playlistId the playlist to get exports for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlaylistExports(playlistId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoExportEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlaylistExports(playlistId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} teamId the team to retrieve playlist segments for
         * @param {Array<number>} [ids] 
         * @param {Array<number>} [tags] 
         * @param {number} [footballFieldLeftTeamId] 
         * @param {number} [footballFieldRightTeamId] 
         * @param {Array<string>} [footballFieldGrids] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {Array<string>} [playerTags] 
         * @param {Array<number>} [forzaTeamTags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlaylistSegments(teamId: number, ids?: Array<number>, tags?: Array<number>, footballFieldLeftTeamId?: number, footballFieldRightTeamId?: number, footballFieldGrids?: Array<string>, limit?: number, offset?: number, playerTags?: Array<string>, forzaTeamTags?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaylistSegments>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlaylistSegments(teamId, ids, tags, footballFieldLeftTeamId, footballFieldRightTeamId, footballFieldGrids, limit, offset, playerTags, forzaTeamTags, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} playlistVideoId the playlist video to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlaylistVideo(playlistVideoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlaylistVideo(playlistVideoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} teamId the team to retrieve playlists for
         * @param {Array<number>} [tags] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {Array<string>} [playerTags] 
         * @param {string} [creator] 
         * @param {Array<number>} [forzaTeamTags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlaylists(teamId: number, tags?: Array<number>, limit?: number, offset?: number, playerTags?: Array<string>, creator?: string, forzaTeamTags?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinimalPlaylists>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlaylists(teamId, tags, limit, offset, playerTags, creator, forzaTeamTags, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} teamId the team to retrieve playlists for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlaylistsShared(teamId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Playlists>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlaylistsShared(teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} playlistId the playlist to compute homography for
         * @param {PlaylistSegmentHomography} playlistSegmentHomography the segments to generate homography for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playlistGenerateHomography(playlistId: number, playlistSegmentHomography: PlaylistSegmentHomography, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playlistGenerateHomography(playlistId, playlistSegmentHomography, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} playlistId the playlist to start exporting
         * @param {CreatePlaylistExport} createPlaylistExport details of the export
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startPlaylistExport(playlistId: number, createPlaylistExport: CreatePlaylistExport, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoExportEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startPlaylistExport(playlistId, createPlaylistExport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} playlistId the playlist to update
         * @param {CreatePlaylist} createPlaylist the updated playlist
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePlaylist(playlistId: number, createPlaylist: CreatePlaylist, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePlaylist(playlistId, createPlaylist, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1playlistApi - factory interface
 * @export
 */
export const V1playlistApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1playlistApiFp(configuration)
    return {
        /**
         * 
         * @param {V1playlistApiCreatePlaylistRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlaylist(requestParameters: V1playlistApiCreatePlaylistRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.createPlaylist(requestParameters.createPlaylist, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1playlistApiDeletePlaylistRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlaylist(requestParameters: V1playlistApiDeletePlaylistRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deletePlaylist(requestParameters.playlistId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1playlistApiGetPlaylistRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylist(requestParameters: V1playlistApiGetPlaylistRequest, options?: AxiosRequestConfig): AxiosPromise<Playlist> {
            return localVarFp.getPlaylist(requestParameters.playlistId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1playlistApiGetPlaylistExportsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistExports(requestParameters: V1playlistApiGetPlaylistExportsRequest, options?: AxiosRequestConfig): AxiosPromise<VideoExportEvent> {
            return localVarFp.getPlaylistExports(requestParameters.playlistId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1playlistApiGetPlaylistSegmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSegments(requestParameters: V1playlistApiGetPlaylistSegmentsRequest, options?: AxiosRequestConfig): AxiosPromise<PlaylistSegments> {
            return localVarFp.getPlaylistSegments(requestParameters.teamId, requestParameters.ids, requestParameters.tags, requestParameters.footballFieldLeftTeamId, requestParameters.footballFieldRightTeamId, requestParameters.footballFieldGrids, requestParameters.limit, requestParameters.offset, requestParameters.playerTags, requestParameters.forzaTeamTags, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1playlistApiGetPlaylistVideoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistVideo(requestParameters: V1playlistApiGetPlaylistVideoRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getPlaylistVideo(requestParameters.playlistVideoId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1playlistApiGetPlaylistsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylists(requestParameters: V1playlistApiGetPlaylistsRequest, options?: AxiosRequestConfig): AxiosPromise<MinimalPlaylists> {
            return localVarFp.getPlaylists(requestParameters.teamId, requestParameters.tags, requestParameters.limit, requestParameters.offset, requestParameters.playerTags, requestParameters.creator, requestParameters.forzaTeamTags, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1playlistApiGetPlaylistsSharedRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistsShared(requestParameters: V1playlistApiGetPlaylistsSharedRequest, options?: AxiosRequestConfig): AxiosPromise<Playlists> {
            return localVarFp.getPlaylistsShared(requestParameters.teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1playlistApiPlaylistGenerateHomographyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playlistGenerateHomography(requestParameters: V1playlistApiPlaylistGenerateHomographyRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.playlistGenerateHomography(requestParameters.playlistId, requestParameters.playlistSegmentHomography, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1playlistApiStartPlaylistExportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startPlaylistExport(requestParameters: V1playlistApiStartPlaylistExportRequest, options?: AxiosRequestConfig): AxiosPromise<VideoExportEvent> {
            return localVarFp.startPlaylistExport(requestParameters.playlistId, requestParameters.createPlaylistExport, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1playlistApiUpdatePlaylistRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlaylist(requestParameters: V1playlistApiUpdatePlaylistRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updatePlaylist(requestParameters.playlistId, requestParameters.createPlaylist, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createPlaylist operation in V1playlistApi.
 * @export
 * @interface V1playlistApiCreatePlaylistRequest
 */
export interface V1playlistApiCreatePlaylistRequest {
    /**
     * the playlist to create
     * @type {CreatePlaylist}
     * @memberof V1playlistApiCreatePlaylist
     */
    readonly createPlaylist: CreatePlaylist
}

/**
 * Request parameters for deletePlaylist operation in V1playlistApi.
 * @export
 * @interface V1playlistApiDeletePlaylistRequest
 */
export interface V1playlistApiDeletePlaylistRequest {
    /**
     * the playlist to delete
     * @type {number}
     * @memberof V1playlistApiDeletePlaylist
     */
    readonly playlistId: number
}

/**
 * Request parameters for getPlaylist operation in V1playlistApi.
 * @export
 * @interface V1playlistApiGetPlaylistRequest
 */
export interface V1playlistApiGetPlaylistRequest {
    /**
     * the playlist to retrieve
     * @type {number}
     * @memberof V1playlistApiGetPlaylist
     */
    readonly playlistId: number
}

/**
 * Request parameters for getPlaylistExports operation in V1playlistApi.
 * @export
 * @interface V1playlistApiGetPlaylistExportsRequest
 */
export interface V1playlistApiGetPlaylistExportsRequest {
    /**
     * the playlist to get exports for
     * @type {number}
     * @memberof V1playlistApiGetPlaylistExports
     */
    readonly playlistId: number
}

/**
 * Request parameters for getPlaylistSegments operation in V1playlistApi.
 * @export
 * @interface V1playlistApiGetPlaylistSegmentsRequest
 */
export interface V1playlistApiGetPlaylistSegmentsRequest {
    /**
     * the team to retrieve playlist segments for
     * @type {number}
     * @memberof V1playlistApiGetPlaylistSegments
     */
    readonly teamId: number

    /**
     * 
     * @type {Array<number>}
     * @memberof V1playlistApiGetPlaylistSegments
     */
    readonly ids?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof V1playlistApiGetPlaylistSegments
     */
    readonly tags?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof V1playlistApiGetPlaylistSegments
     */
    readonly footballFieldLeftTeamId?: number

    /**
     * 
     * @type {number}
     * @memberof V1playlistApiGetPlaylistSegments
     */
    readonly footballFieldRightTeamId?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof V1playlistApiGetPlaylistSegments
     */
    readonly footballFieldGrids?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof V1playlistApiGetPlaylistSegments
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof V1playlistApiGetPlaylistSegments
     */
    readonly offset?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof V1playlistApiGetPlaylistSegments
     */
    readonly playerTags?: Array<string>

    /**
     * 
     * @type {Array<number>}
     * @memberof V1playlistApiGetPlaylistSegments
     */
    readonly forzaTeamTags?: Array<number>
}

/**
 * Request parameters for getPlaylistVideo operation in V1playlistApi.
 * @export
 * @interface V1playlistApiGetPlaylistVideoRequest
 */
export interface V1playlistApiGetPlaylistVideoRequest {
    /**
     * the playlist video to get
     * @type {number}
     * @memberof V1playlistApiGetPlaylistVideo
     */
    readonly playlistVideoId: number
}

/**
 * Request parameters for getPlaylists operation in V1playlistApi.
 * @export
 * @interface V1playlistApiGetPlaylistsRequest
 */
export interface V1playlistApiGetPlaylistsRequest {
    /**
     * the team to retrieve playlists for
     * @type {number}
     * @memberof V1playlistApiGetPlaylists
     */
    readonly teamId: number

    /**
     * 
     * @type {Array<number>}
     * @memberof V1playlistApiGetPlaylists
     */
    readonly tags?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof V1playlistApiGetPlaylists
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof V1playlistApiGetPlaylists
     */
    readonly offset?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof V1playlistApiGetPlaylists
     */
    readonly playerTags?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof V1playlistApiGetPlaylists
     */
    readonly creator?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof V1playlistApiGetPlaylists
     */
    readonly forzaTeamTags?: Array<number>
}

/**
 * Request parameters for getPlaylistsShared operation in V1playlistApi.
 * @export
 * @interface V1playlistApiGetPlaylistsSharedRequest
 */
export interface V1playlistApiGetPlaylistsSharedRequest {
    /**
     * the team to retrieve playlists for
     * @type {number}
     * @memberof V1playlistApiGetPlaylistsShared
     */
    readonly teamId: number
}

/**
 * Request parameters for playlistGenerateHomography operation in V1playlistApi.
 * @export
 * @interface V1playlistApiPlaylistGenerateHomographyRequest
 */
export interface V1playlistApiPlaylistGenerateHomographyRequest {
    /**
     * the playlist to compute homography for
     * @type {number}
     * @memberof V1playlistApiPlaylistGenerateHomography
     */
    readonly playlistId: number

    /**
     * the segments to generate homography for
     * @type {PlaylistSegmentHomography}
     * @memberof V1playlistApiPlaylistGenerateHomography
     */
    readonly playlistSegmentHomography: PlaylistSegmentHomography
}

/**
 * Request parameters for startPlaylistExport operation in V1playlistApi.
 * @export
 * @interface V1playlistApiStartPlaylistExportRequest
 */
export interface V1playlistApiStartPlaylistExportRequest {
    /**
     * the playlist to start exporting
     * @type {number}
     * @memberof V1playlistApiStartPlaylistExport
     */
    readonly playlistId: number

    /**
     * details of the export
     * @type {CreatePlaylistExport}
     * @memberof V1playlistApiStartPlaylistExport
     */
    readonly createPlaylistExport: CreatePlaylistExport
}

/**
 * Request parameters for updatePlaylist operation in V1playlistApi.
 * @export
 * @interface V1playlistApiUpdatePlaylistRequest
 */
export interface V1playlistApiUpdatePlaylistRequest {
    /**
     * the playlist to update
     * @type {number}
     * @memberof V1playlistApiUpdatePlaylist
     */
    readonly playlistId: number

    /**
     * the updated playlist
     * @type {CreatePlaylist}
     * @memberof V1playlistApiUpdatePlaylist
     */
    readonly createPlaylist: CreatePlaylist
}

/**
 * V1playlistApi - object-oriented interface
 * @export
 * @class V1playlistApi
 * @extends {BaseAPI}
 */
export class V1playlistApi extends BaseAPI {
    /**
     * 
     * @param {V1playlistApiCreatePlaylistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1playlistApi
     */
    public createPlaylist(requestParameters: V1playlistApiCreatePlaylistRequest, options?: AxiosRequestConfig) {
        return V1playlistApiFp(this.configuration).createPlaylist(requestParameters.createPlaylist, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1playlistApiDeletePlaylistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1playlistApi
     */
    public deletePlaylist(requestParameters: V1playlistApiDeletePlaylistRequest, options?: AxiosRequestConfig) {
        return V1playlistApiFp(this.configuration).deletePlaylist(requestParameters.playlistId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1playlistApiGetPlaylistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1playlistApi
     */
    public getPlaylist(requestParameters: V1playlistApiGetPlaylistRequest, options?: AxiosRequestConfig) {
        return V1playlistApiFp(this.configuration).getPlaylist(requestParameters.playlistId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1playlistApiGetPlaylistExportsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1playlistApi
     */
    public getPlaylistExports(requestParameters: V1playlistApiGetPlaylistExportsRequest, options?: AxiosRequestConfig) {
        return V1playlistApiFp(this.configuration).getPlaylistExports(requestParameters.playlistId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1playlistApiGetPlaylistSegmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1playlistApi
     */
    public getPlaylistSegments(requestParameters: V1playlistApiGetPlaylistSegmentsRequest, options?: AxiosRequestConfig) {
        return V1playlistApiFp(this.configuration).getPlaylistSegments(requestParameters.teamId, requestParameters.ids, requestParameters.tags, requestParameters.footballFieldLeftTeamId, requestParameters.footballFieldRightTeamId, requestParameters.footballFieldGrids, requestParameters.limit, requestParameters.offset, requestParameters.playerTags, requestParameters.forzaTeamTags, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1playlistApiGetPlaylistVideoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1playlistApi
     */
    public getPlaylistVideo(requestParameters: V1playlistApiGetPlaylistVideoRequest, options?: AxiosRequestConfig) {
        return V1playlistApiFp(this.configuration).getPlaylistVideo(requestParameters.playlistVideoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1playlistApiGetPlaylistsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1playlistApi
     */
    public getPlaylists(requestParameters: V1playlistApiGetPlaylistsRequest, options?: AxiosRequestConfig) {
        return V1playlistApiFp(this.configuration).getPlaylists(requestParameters.teamId, requestParameters.tags, requestParameters.limit, requestParameters.offset, requestParameters.playerTags, requestParameters.creator, requestParameters.forzaTeamTags, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1playlistApiGetPlaylistsSharedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1playlistApi
     */
    public getPlaylistsShared(requestParameters: V1playlistApiGetPlaylistsSharedRequest, options?: AxiosRequestConfig) {
        return V1playlistApiFp(this.configuration).getPlaylistsShared(requestParameters.teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1playlistApiPlaylistGenerateHomographyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1playlistApi
     */
    public playlistGenerateHomography(requestParameters: V1playlistApiPlaylistGenerateHomographyRequest, options?: AxiosRequestConfig) {
        return V1playlistApiFp(this.configuration).playlistGenerateHomography(requestParameters.playlistId, requestParameters.playlistSegmentHomography, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1playlistApiStartPlaylistExportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1playlistApi
     */
    public startPlaylistExport(requestParameters: V1playlistApiStartPlaylistExportRequest, options?: AxiosRequestConfig) {
        return V1playlistApiFp(this.configuration).startPlaylistExport(requestParameters.playlistId, requestParameters.createPlaylistExport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1playlistApiUpdatePlaylistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1playlistApi
     */
    public updatePlaylist(requestParameters: V1playlistApiUpdatePlaylistRequest, options?: AxiosRequestConfig) {
        return V1playlistApiFp(this.configuration).updatePlaylist(requestParameters.playlistId, requestParameters.createPlaylist, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1playlistSegmentTagApi - axios parameter creator
 * @export
 */
export const V1playlistSegmentTagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePlaylistSegmentTag} createPlaylistSegmentTag the tag to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlaylistSegmentTag: async (createPlaylistSegmentTag: CreatePlaylistSegmentTag, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPlaylistSegmentTag' is not null or undefined
            assertParamExists('createPlaylistSegmentTag', 'createPlaylistSegmentTag', createPlaylistSegmentTag)
            const localVarPath = `/v1.0/playlist_segment_tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlaylistSegmentTag, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} tagId the tag_id to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlaylistSegmentTag: async (tagId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('deletePlaylistSegmentTag', 'tagId', tagId)
            const localVarPath = `/v1.0/playlist_segment_tags/{tag_id}`
                .replace(`{${"tag_id"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSegmentTags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/playlist_segment_tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} tagId the tag_id to update
         * @param {UpdatePlaylistSegmentTag} updatePlaylistSegmentTag the new playlist segment tag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlaylistSegmentTag: async (tagId: number, updatePlaylistSegmentTag: UpdatePlaylistSegmentTag, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('updatePlaylistSegmentTag', 'tagId', tagId)
            // verify required parameter 'updatePlaylistSegmentTag' is not null or undefined
            assertParamExists('updatePlaylistSegmentTag', 'updatePlaylistSegmentTag', updatePlaylistSegmentTag)
            const localVarPath = `/v1.0/playlist_segment_tags/{tag_id}`
                .replace(`{${"tag_id"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePlaylistSegmentTag, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1playlistSegmentTagApi - functional programming interface
 * @export
 */
export const V1playlistSegmentTagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1playlistSegmentTagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePlaylistSegmentTag} createPlaylistSegmentTag the tag to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPlaylistSegmentTag(createPlaylistSegmentTag: CreatePlaylistSegmentTag, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaylistSegmentTag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPlaylistSegmentTag(createPlaylistSegmentTag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} tagId the tag_id to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePlaylistSegmentTag(tagId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePlaylistSegmentTag(tagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlaylistSegmentTags(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlaylistSegmentTag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlaylistSegmentTags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} tagId the tag_id to update
         * @param {UpdatePlaylistSegmentTag} updatePlaylistSegmentTag the new playlist segment tag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePlaylistSegmentTag(tagId: number, updatePlaylistSegmentTag: UpdatePlaylistSegmentTag, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaylistSegmentTag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePlaylistSegmentTag(tagId, updatePlaylistSegmentTag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1playlistSegmentTagApi - factory interface
 * @export
 */
export const V1playlistSegmentTagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1playlistSegmentTagApiFp(configuration)
    return {
        /**
         * 
         * @param {V1playlistSegmentTagApiCreatePlaylistSegmentTagRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlaylistSegmentTag(requestParameters: V1playlistSegmentTagApiCreatePlaylistSegmentTagRequest, options?: AxiosRequestConfig): AxiosPromise<PlaylistSegmentTag> {
            return localVarFp.createPlaylistSegmentTag(requestParameters.createPlaylistSegmentTag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1playlistSegmentTagApiDeletePlaylistSegmentTagRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlaylistSegmentTag(requestParameters: V1playlistSegmentTagApiDeletePlaylistSegmentTagRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deletePlaylistSegmentTag(requestParameters.tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSegmentTags(options?: AxiosRequestConfig): AxiosPromise<Array<PlaylistSegmentTag>> {
            return localVarFp.getPlaylistSegmentTags(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1playlistSegmentTagApiUpdatePlaylistSegmentTagRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlaylistSegmentTag(requestParameters: V1playlistSegmentTagApiUpdatePlaylistSegmentTagRequest, options?: AxiosRequestConfig): AxiosPromise<PlaylistSegmentTag> {
            return localVarFp.updatePlaylistSegmentTag(requestParameters.tagId, requestParameters.updatePlaylistSegmentTag, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createPlaylistSegmentTag operation in V1playlistSegmentTagApi.
 * @export
 * @interface V1playlistSegmentTagApiCreatePlaylistSegmentTagRequest
 */
export interface V1playlistSegmentTagApiCreatePlaylistSegmentTagRequest {
    /**
     * the tag to create
     * @type {CreatePlaylistSegmentTag}
     * @memberof V1playlistSegmentTagApiCreatePlaylistSegmentTag
     */
    readonly createPlaylistSegmentTag: CreatePlaylistSegmentTag
}

/**
 * Request parameters for deletePlaylistSegmentTag operation in V1playlistSegmentTagApi.
 * @export
 * @interface V1playlistSegmentTagApiDeletePlaylistSegmentTagRequest
 */
export interface V1playlistSegmentTagApiDeletePlaylistSegmentTagRequest {
    /**
     * the tag_id to delete
     * @type {number}
     * @memberof V1playlistSegmentTagApiDeletePlaylistSegmentTag
     */
    readonly tagId: number
}

/**
 * Request parameters for updatePlaylistSegmentTag operation in V1playlistSegmentTagApi.
 * @export
 * @interface V1playlistSegmentTagApiUpdatePlaylistSegmentTagRequest
 */
export interface V1playlistSegmentTagApiUpdatePlaylistSegmentTagRequest {
    /**
     * the tag_id to update
     * @type {number}
     * @memberof V1playlistSegmentTagApiUpdatePlaylistSegmentTag
     */
    readonly tagId: number

    /**
     * the new playlist segment tag value
     * @type {UpdatePlaylistSegmentTag}
     * @memberof V1playlistSegmentTagApiUpdatePlaylistSegmentTag
     */
    readonly updatePlaylistSegmentTag: UpdatePlaylistSegmentTag
}

/**
 * V1playlistSegmentTagApi - object-oriented interface
 * @export
 * @class V1playlistSegmentTagApi
 * @extends {BaseAPI}
 */
export class V1playlistSegmentTagApi extends BaseAPI {
    /**
     * 
     * @param {V1playlistSegmentTagApiCreatePlaylistSegmentTagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1playlistSegmentTagApi
     */
    public createPlaylistSegmentTag(requestParameters: V1playlistSegmentTagApiCreatePlaylistSegmentTagRequest, options?: AxiosRequestConfig) {
        return V1playlistSegmentTagApiFp(this.configuration).createPlaylistSegmentTag(requestParameters.createPlaylistSegmentTag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1playlistSegmentTagApiDeletePlaylistSegmentTagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1playlistSegmentTagApi
     */
    public deletePlaylistSegmentTag(requestParameters: V1playlistSegmentTagApiDeletePlaylistSegmentTagRequest, options?: AxiosRequestConfig) {
        return V1playlistSegmentTagApiFp(this.configuration).deletePlaylistSegmentTag(requestParameters.tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1playlistSegmentTagApi
     */
    public getPlaylistSegmentTags(options?: AxiosRequestConfig) {
        return V1playlistSegmentTagApiFp(this.configuration).getPlaylistSegmentTags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1playlistSegmentTagApiUpdatePlaylistSegmentTagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1playlistSegmentTagApi
     */
    public updatePlaylistSegmentTag(requestParameters: V1playlistSegmentTagApiUpdatePlaylistSegmentTagRequest, options?: AxiosRequestConfig) {
        return V1playlistSegmentTagApiFp(this.configuration).updatePlaylistSegmentTag(requestParameters.tagId, requestParameters.updatePlaylistSegmentTag, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1playlistTagApi - axios parameter creator
 * @export
 */
export const V1playlistTagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePlaylistTag} createPlaylistTag the tag to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlaylistTag: async (createPlaylistTag: CreatePlaylistTag, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPlaylistTag' is not null or undefined
            assertParamExists('createPlaylistTag', 'createPlaylistTag', createPlaylistTag)
            const localVarPath = `/v1.0/playlist_tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlaylistTag, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} tagId the tag_id to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlaylistTag: async (tagId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('deletePlaylistTag', 'tagId', tagId)
            const localVarPath = `/v1.0/playlist_tags/{tag_id}`
                .replace(`{${"tag_id"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistTags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/playlist_tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} tagId the tag_id to update
         * @param {CreatePlaylistTag} createPlaylistTag the new playlist tag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlaylistTag: async (tagId: number, createPlaylistTag: CreatePlaylistTag, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('updatePlaylistTag', 'tagId', tagId)
            // verify required parameter 'createPlaylistTag' is not null or undefined
            assertParamExists('updatePlaylistTag', 'createPlaylistTag', createPlaylistTag)
            const localVarPath = `/v1.0/playlist_tags/{tag_id}`
                .replace(`{${"tag_id"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlaylistTag, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1playlistTagApi - functional programming interface
 * @export
 */
export const V1playlistTagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1playlistTagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePlaylistTag} createPlaylistTag the tag to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPlaylistTag(createPlaylistTag: CreatePlaylistTag, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaylistTag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPlaylistTag(createPlaylistTag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} tagId the tag_id to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePlaylistTag(tagId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePlaylistTag(tagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlaylistTags(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlaylistTag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlaylistTags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} tagId the tag_id to update
         * @param {CreatePlaylistTag} createPlaylistTag the new playlist tag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePlaylistTag(tagId: number, createPlaylistTag: CreatePlaylistTag, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePlaylistTag(tagId, createPlaylistTag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1playlistTagApi - factory interface
 * @export
 */
export const V1playlistTagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1playlistTagApiFp(configuration)
    return {
        /**
         * 
         * @param {V1playlistTagApiCreatePlaylistTagRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlaylistTag(requestParameters: V1playlistTagApiCreatePlaylistTagRequest, options?: AxiosRequestConfig): AxiosPromise<PlaylistTag> {
            return localVarFp.createPlaylistTag(requestParameters.createPlaylistTag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1playlistTagApiDeletePlaylistTagRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlaylistTag(requestParameters: V1playlistTagApiDeletePlaylistTagRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deletePlaylistTag(requestParameters.tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistTags(options?: AxiosRequestConfig): AxiosPromise<Array<PlaylistTag>> {
            return localVarFp.getPlaylistTags(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1playlistTagApiUpdatePlaylistTagRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlaylistTag(requestParameters: V1playlistTagApiUpdatePlaylistTagRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updatePlaylistTag(requestParameters.tagId, requestParameters.createPlaylistTag, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createPlaylistTag operation in V1playlistTagApi.
 * @export
 * @interface V1playlistTagApiCreatePlaylistTagRequest
 */
export interface V1playlistTagApiCreatePlaylistTagRequest {
    /**
     * the tag to create
     * @type {CreatePlaylistTag}
     * @memberof V1playlistTagApiCreatePlaylistTag
     */
    readonly createPlaylistTag: CreatePlaylistTag
}

/**
 * Request parameters for deletePlaylistTag operation in V1playlistTagApi.
 * @export
 * @interface V1playlistTagApiDeletePlaylistTagRequest
 */
export interface V1playlistTagApiDeletePlaylistTagRequest {
    /**
     * the tag_id to delete
     * @type {number}
     * @memberof V1playlistTagApiDeletePlaylistTag
     */
    readonly tagId: number
}

/**
 * Request parameters for updatePlaylistTag operation in V1playlistTagApi.
 * @export
 * @interface V1playlistTagApiUpdatePlaylistTagRequest
 */
export interface V1playlistTagApiUpdatePlaylistTagRequest {
    /**
     * the tag_id to update
     * @type {number}
     * @memberof V1playlistTagApiUpdatePlaylistTag
     */
    readonly tagId: number

    /**
     * the new playlist tag value
     * @type {CreatePlaylistTag}
     * @memberof V1playlistTagApiUpdatePlaylistTag
     */
    readonly createPlaylistTag: CreatePlaylistTag
}

/**
 * V1playlistTagApi - object-oriented interface
 * @export
 * @class V1playlistTagApi
 * @extends {BaseAPI}
 */
export class V1playlistTagApi extends BaseAPI {
    /**
     * 
     * @param {V1playlistTagApiCreatePlaylistTagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1playlistTagApi
     */
    public createPlaylistTag(requestParameters: V1playlistTagApiCreatePlaylistTagRequest, options?: AxiosRequestConfig) {
        return V1playlistTagApiFp(this.configuration).createPlaylistTag(requestParameters.createPlaylistTag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1playlistTagApiDeletePlaylistTagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1playlistTagApi
     */
    public deletePlaylistTag(requestParameters: V1playlistTagApiDeletePlaylistTagRequest, options?: AxiosRequestConfig) {
        return V1playlistTagApiFp(this.configuration).deletePlaylistTag(requestParameters.tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1playlistTagApi
     */
    public getPlaylistTags(options?: AxiosRequestConfig) {
        return V1playlistTagApiFp(this.configuration).getPlaylistTags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1playlistTagApiUpdatePlaylistTagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1playlistTagApi
     */
    public updatePlaylistTag(requestParameters: V1playlistTagApiUpdatePlaylistTagRequest, options?: AxiosRequestConfig) {
        return V1playlistTagApiFp(this.configuration).updatePlaylistTag(requestParameters.tagId, requestParameters.createPlaylistTag, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1teamApi - axios parameter creator
 * @export
 */
export const V1teamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateTeam} createTeam the team to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeam: async (createTeam: CreateTeam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTeam' is not null or undefined
            assertParamExists('createTeam', 'createTeam', createTeam)
            const localVarPath = `/v1.0/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTeam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} teamId the team to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeam: async (teamId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('deleteTeam', 'teamId', teamId)
            const localVarPath = `/v1.0/teams/{team_id}`
                .replace(`{${"team_id"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} teamId the team to kick from
         * @param {string} userId the user to kick
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kickUserFromTeam: async (teamId: number, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('kickUserFromTeam', 'teamId', teamId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('kickUserFromTeam', 'userId', userId)
            const localVarPath = `/v1.0/teams/{team_id}/kick/{user_id}`
                .replace(`{${"team_id"}}`, encodeURIComponent(String(teamId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} teamId the team to leave
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leaveTeam: async (teamId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('leaveTeam', 'teamId', teamId)
            const localVarPath = `/v1.0/teams/{team_id}/leave`
                .replace(`{${"team_id"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} teamId the team to update
         * @param {CreateTeam} createTeam the updated team object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeam: async (teamId: number, createTeam: CreateTeam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('updateTeam', 'teamId', teamId)
            // verify required parameter 'createTeam' is not null or undefined
            assertParamExists('updateTeam', 'createTeam', createTeam)
            const localVarPath = `/v1.0/teams/{team_id}`
                .replace(`{${"team_id"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTeam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1teamApi - functional programming interface
 * @export
 */
export const V1teamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1teamApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateTeam} createTeam the team to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTeam(createTeam: CreateTeam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTeam(createTeam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} teamId the team to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTeam(teamId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTeam(teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} teamId the team to kick from
         * @param {string} userId the user to kick
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kickUserFromTeam(teamId: number, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kickUserFromTeam(teamId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} teamId the team to leave
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leaveTeam(teamId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leaveTeam(teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} teamId the team to update
         * @param {CreateTeam} createTeam the updated team object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeam(teamId: number, createTeam: CreateTeam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeam(teamId, createTeam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1teamApi - factory interface
 * @export
 */
export const V1teamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1teamApiFp(configuration)
    return {
        /**
         * 
         * @param {V1teamApiCreateTeamRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeam(requestParameters: V1teamApiCreateTeamRequest, options?: AxiosRequestConfig): AxiosPromise<Team> {
            return localVarFp.createTeam(requestParameters.createTeam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1teamApiDeleteTeamRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeam(requestParameters: V1teamApiDeleteTeamRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteTeam(requestParameters.teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1teamApiKickUserFromTeamRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kickUserFromTeam(requestParameters: V1teamApiKickUserFromTeamRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.kickUserFromTeam(requestParameters.teamId, requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1teamApiLeaveTeamRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leaveTeam(requestParameters: V1teamApiLeaveTeamRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.leaveTeam(requestParameters.teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1teamApiUpdateTeamRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeam(requestParameters: V1teamApiUpdateTeamRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateTeam(requestParameters.teamId, requestParameters.createTeam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createTeam operation in V1teamApi.
 * @export
 * @interface V1teamApiCreateTeamRequest
 */
export interface V1teamApiCreateTeamRequest {
    /**
     * the team to create
     * @type {CreateTeam}
     * @memberof V1teamApiCreateTeam
     */
    readonly createTeam: CreateTeam
}

/**
 * Request parameters for deleteTeam operation in V1teamApi.
 * @export
 * @interface V1teamApiDeleteTeamRequest
 */
export interface V1teamApiDeleteTeamRequest {
    /**
     * the team to delete
     * @type {number}
     * @memberof V1teamApiDeleteTeam
     */
    readonly teamId: number
}

/**
 * Request parameters for kickUserFromTeam operation in V1teamApi.
 * @export
 * @interface V1teamApiKickUserFromTeamRequest
 */
export interface V1teamApiKickUserFromTeamRequest {
    /**
     * the team to kick from
     * @type {number}
     * @memberof V1teamApiKickUserFromTeam
     */
    readonly teamId: number

    /**
     * the user to kick
     * @type {string}
     * @memberof V1teamApiKickUserFromTeam
     */
    readonly userId: string
}

/**
 * Request parameters for leaveTeam operation in V1teamApi.
 * @export
 * @interface V1teamApiLeaveTeamRequest
 */
export interface V1teamApiLeaveTeamRequest {
    /**
     * the team to leave
     * @type {number}
     * @memberof V1teamApiLeaveTeam
     */
    readonly teamId: number
}

/**
 * Request parameters for updateTeam operation in V1teamApi.
 * @export
 * @interface V1teamApiUpdateTeamRequest
 */
export interface V1teamApiUpdateTeamRequest {
    /**
     * the team to update
     * @type {number}
     * @memberof V1teamApiUpdateTeam
     */
    readonly teamId: number

    /**
     * the updated team object
     * @type {CreateTeam}
     * @memberof V1teamApiUpdateTeam
     */
    readonly createTeam: CreateTeam
}

/**
 * V1teamApi - object-oriented interface
 * @export
 * @class V1teamApi
 * @extends {BaseAPI}
 */
export class V1teamApi extends BaseAPI {
    /**
     * 
     * @param {V1teamApiCreateTeamRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1teamApi
     */
    public createTeam(requestParameters: V1teamApiCreateTeamRequest, options?: AxiosRequestConfig) {
        return V1teamApiFp(this.configuration).createTeam(requestParameters.createTeam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1teamApiDeleteTeamRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1teamApi
     */
    public deleteTeam(requestParameters: V1teamApiDeleteTeamRequest, options?: AxiosRequestConfig) {
        return V1teamApiFp(this.configuration).deleteTeam(requestParameters.teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1teamApiKickUserFromTeamRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1teamApi
     */
    public kickUserFromTeam(requestParameters: V1teamApiKickUserFromTeamRequest, options?: AxiosRequestConfig) {
        return V1teamApiFp(this.configuration).kickUserFromTeam(requestParameters.teamId, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1teamApiLeaveTeamRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1teamApi
     */
    public leaveTeam(requestParameters: V1teamApiLeaveTeamRequest, options?: AxiosRequestConfig) {
        return V1teamApiFp(this.configuration).leaveTeam(requestParameters.teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1teamApiUpdateTeamRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1teamApi
     */
    public updateTeam(requestParameters: V1teamApiUpdateTeamRequest, options?: AxiosRequestConfig) {
        return V1teamApiFp(this.configuration).updateTeam(requestParameters.teamId, requestParameters.createTeam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1userApi - axios parameter creator
 * @export
 */
export const V1userApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateUser} createUser the user to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (createUser: CreateUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUser' is not null or undefined
            assertParamExists('createUser', 'createUser', createUser)
            const localVarPath = `/v1.0/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId the user id to delete the profile image of
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfileImage: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteProfileImage', 'userId', userId)
            const localVarPath = `/v1.0/users/{user_id}/profile_image`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId the user id to get the profile image of
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileImage: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getProfileImage', 'userId', userId)
            const localVarPath = `/v1.0/users/{user_id}/profile_image`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUser} updateUser the updated user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (updateUser: UpdateUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUser' is not null or undefined
            assertParamExists('updateUser', 'updateUser', updateUser)
            const localVarPath = `/v1.0/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1userApi - functional programming interface
 * @export
 */
export const V1userApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1userApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateUser} createUser the user to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(createUser: CreateUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(createUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId the user id to delete the profile image of
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProfileImage(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProfileImage(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId the user id to get the profile image of
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileImage(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileImage(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateUser} updateUser the updated user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(updateUser: UpdateUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(updateUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1userApi - factory interface
 * @export
 */
export const V1userApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1userApiFp(configuration)
    return {
        /**
         * 
         * @param {V1userApiCreateUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(requestParameters: V1userApiCreateUserRequest, options?: AxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.createUser(requestParameters.createUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1userApiDeleteProfileImageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfileImage(requestParameters: V1userApiDeleteProfileImageRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteProfileImage(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1userApiGetProfileImageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileImage(requestParameters: V1userApiGetProfileImageRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getProfileImage(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(options?: AxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.getUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1userApiUpdateUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(requestParameters: V1userApiUpdateUserRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateUser(requestParameters.updateUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createUser operation in V1userApi.
 * @export
 * @interface V1userApiCreateUserRequest
 */
export interface V1userApiCreateUserRequest {
    /**
     * the user to create
     * @type {CreateUser}
     * @memberof V1userApiCreateUser
     */
    readonly createUser: CreateUser
}

/**
 * Request parameters for deleteProfileImage operation in V1userApi.
 * @export
 * @interface V1userApiDeleteProfileImageRequest
 */
export interface V1userApiDeleteProfileImageRequest {
    /**
     * the user id to delete the profile image of
     * @type {string}
     * @memberof V1userApiDeleteProfileImage
     */
    readonly userId: string
}

/**
 * Request parameters for getProfileImage operation in V1userApi.
 * @export
 * @interface V1userApiGetProfileImageRequest
 */
export interface V1userApiGetProfileImageRequest {
    /**
     * the user id to get the profile image of
     * @type {string}
     * @memberof V1userApiGetProfileImage
     */
    readonly userId: string
}

/**
 * Request parameters for updateUser operation in V1userApi.
 * @export
 * @interface V1userApiUpdateUserRequest
 */
export interface V1userApiUpdateUserRequest {
    /**
     * the updated user object
     * @type {UpdateUser}
     * @memberof V1userApiUpdateUser
     */
    readonly updateUser: UpdateUser
}

/**
 * V1userApi - object-oriented interface
 * @export
 * @class V1userApi
 * @extends {BaseAPI}
 */
export class V1userApi extends BaseAPI {
    /**
     * 
     * @param {V1userApiCreateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1userApi
     */
    public createUser(requestParameters: V1userApiCreateUserRequest, options?: AxiosRequestConfig) {
        return V1userApiFp(this.configuration).createUser(requestParameters.createUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1userApiDeleteProfileImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1userApi
     */
    public deleteProfileImage(requestParameters: V1userApiDeleteProfileImageRequest, options?: AxiosRequestConfig) {
        return V1userApiFp(this.configuration).deleteProfileImage(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1userApi
     */
    public deleteUser(options?: AxiosRequestConfig) {
        return V1userApiFp(this.configuration).deleteUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1userApiGetProfileImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1userApi
     */
    public getProfileImage(requestParameters: V1userApiGetProfileImageRequest, options?: AxiosRequestConfig) {
        return V1userApiFp(this.configuration).getProfileImage(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1userApi
     */
    public getUser(options?: AxiosRequestConfig) {
        return V1userApiFp(this.configuration).getUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1userApiUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1userApi
     */
    public updateUser(requestParameters: V1userApiUpdateUserRequest, options?: AxiosRequestConfig) {
        return V1userApiFp(this.configuration).updateUser(requestParameters.updateUser, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1vgliveApi - axios parameter creator
 * @export
 */
export const V1vgliveApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {VgliveTournament} tournament tournament to get events for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vgliveEvents: async (tournament: VgliveTournament, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tournament' is not null or undefined
            assertParamExists('vgliveEvents', 'tournament', tournament)
            const localVarPath = `/v1.0/vglive_events/{tournament}`
                .replace(`{${"tournament"}}`, encodeURIComponent(String(tournament)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1vgliveApi - functional programming interface
 * @export
 */
export const V1vgliveApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1vgliveApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {VgliveTournament} tournament tournament to get events for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vgliveEvents(tournament: VgliveTournament, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VgliveEvent>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vgliveEvents(tournament, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1vgliveApi - factory interface
 * @export
 */
export const V1vgliveApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1vgliveApiFp(configuration)
    return {
        /**
         * 
         * @param {V1vgliveApiVgliveEventsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vgliveEvents(requestParameters: V1vgliveApiVgliveEventsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<VgliveEvent>> {
            return localVarFp.vgliveEvents(requestParameters.tournament, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for vgliveEvents operation in V1vgliveApi.
 * @export
 * @interface V1vgliveApiVgliveEventsRequest
 */
export interface V1vgliveApiVgliveEventsRequest {
    /**
     * tournament to get events for
     * @type {VgliveTournament}
     * @memberof V1vgliveApiVgliveEvents
     */
    readonly tournament: VgliveTournament
}

/**
 * V1vgliveApi - object-oriented interface
 * @export
 * @class V1vgliveApi
 * @extends {BaseAPI}
 */
export class V1vgliveApi extends BaseAPI {
    /**
     * 
     * @param {V1vgliveApiVgliveEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1vgliveApi
     */
    public vgliveEvents(requestParameters: V1vgliveApiVgliveEventsRequest, options?: AxiosRequestConfig) {
        return V1vgliveApiFp(this.configuration).vgliveEvents(requestParameters.tournament, options).then((request) => request(this.axios, this.basePath));
    }
}


