import ChatIcon from "@mui/icons-material/Chat";
import CircleIcon from "@mui/icons-material/Circle";
import ClearIcon from "@mui/icons-material/Clear";
import CoPresentOutlinedIcon from "@mui/icons-material/CoPresentOutlined";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonIcon from "@mui/icons-material/Person";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import theme from "app/theme";
import {
  CombinedPlayer,
  Confirm,
  ConfirmModal,
  SegmentsStatsTable,
  VideoAssets,
  VideoOffsetBar,
  VideoOffsetMenu,
} from "components";
import { VideoPlayerRef } from "components/VideoPlayer/VideoPlayer";
import { Tactical } from "containers";
import { ForzaGameTagsMenu } from "containers/ForzaGame/ForzaGameTagsMenu";
import {
  LiveMessage,
  LiveStreamDetailed,
  LiveStreamEvent,
  LiveStreamEventDiscriminants,
  LiveStreamMode,
  PlaylistSegment,
  Shape,
} from "generated/openapi";
import { current } from "immer";
import { FC, useEffect, useMemo, useState } from "react";
import { Form, useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import {
  createLiveSegment,
  deleteLiveSegment,
  selectAccessToken,
  selectUser,
  sendLiveSegment,
  setLiveMode,
  setLiveThumbnail,
  updateLiveSegment,
  updateLiveTacticalPitch,
  useAppDispatch,
  useAppSelector,
} from "store";
import { useImmer } from "use-immer";
import { dateFormat, getVideoOffset } from "utils";
import { FocusModeView } from "./FocusModeView";
import { LiveMessages } from "./LiveMessages";
import { LiveSegments } from "./LiveSegments";

const colors = [
  "#80b8e7",
  "#E88080",
  "#E880C0",
  "#C080E8",
  "#80E89D",
  "#E1E880",
  "#E8A280",
  "#809FE8",
];

export const LiveStream: FC = () => {
  const params = useParams();

  const liveStreamId = params.liveStreamId ? +params.liveStreamId : undefined;

  if (!Number.isFinite(liveStreamId)) {
    return <Navigate to="/" />;
  }

  return <LiveStreamInner id={liveStreamId!} />;
};

interface Props {
  id: number;
}

export const LiveStreamInner: FC<Props> = ({ id }) => {
  const dispatch = useAppDispatch();
  const xlBreakpoint = useMediaQuery(theme.breakpoints.up("xl"));
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down("md"));

  const token = useAppSelector(selectAccessToken);
  const user = useAppSelector(selectUser);

  const [playerRef, setPlayerRef] = useState<VideoPlayerRef | null>(null);

  const [loading, setLoading] = useState(true);
  const [liveStream, setLiveStream] = useImmer<LiveStreamDetailed | undefined>(
    undefined,
  );
  const [selectedTab, setSelectedTab] = useState<string>("clips");
  const [newMessages, setNewMessages] = useState<number>(0);
  const [newSegments, setNewSegments] = useState<number>(0);
  const [confirm, setConfirm] = useState<Confirm | undefined>(undefined);
  const [focusMode, setFocusMode] = useState<boolean>(false);
  const [colorMap, setColorMap] = useState<Record<string, string>>({});
  const [selectedSegment, setSelectedSegment] = useState<
    PlaylistSegment | undefined
  >(undefined);
  const [focusedSegment, setFocusedSegment] = useState<
    PlaylistSegment | undefined
  >(undefined);
  const [tacModalOpen, setTacModalOpen] = useState<boolean>(false);
  const [chatAlert, setChatAlert] = useState<LiveMessage | undefined>(
    undefined,
  );
  const [tagTableFilter, setTagTableFilter] = useState<number>();
  const [selectedAssetIdx, setSelectedAssetIdx] = useState(0);

  const {
    reset,
    control,
    register,
    setValue,
    getValues,
    watch,
    formState: { isDirty },
  } = useForm<PlaylistSegment>({ defaultValues: selectedSegment });

  const focusModeUsers = liveStream?.activeUsers.filter((u) =>
    u.modes.includes(LiveStreamMode.Focus),
  );

  const toTimestamp = watch("toTimestamp");
  const fromTimestamp = watch("fromTimestamp");

  const videoAssets = useMemo(
    () => (liveStream ? [...liveStream.game.videoAssets].reverse() : undefined),
    [liveStream],
  );
  const liveAsset = videoAssets?.[selectedAssetIdx];

  const liveUrl = `${liveAsset?.videoBaseUrl}/live/event/${liveAsset?.videoAssetId}/Manifest.m3u8`;
  const url = selectedSegment
    ? `${selectedSegment.videoBaseUrl}/playlist.m3u8/${selectedSegment.videoAssetId}:${fromTimestamp}:${toTimestamp}/Manifest.m3u8`
    : liveUrl;

  const setUserColor = (userId: string) => {
    let color = localStorage.getItem(userId);

    if (!color) {
      if (userId === user!.id) {
        color = colors[0];
      } else {
        const storeVals: string[] = Object.values({ ...localStorage });
        const availableColor = colors.find((c) => !storeVals.includes(c));

        color = availableColor ?? "#CBCBCB";
      }
      localStorage.setItem(userId, color);
    }

    setColorMap((curr) => {
      return { ...curr, [userId]: color! };
    });
  };

  const handleTabChange = (newVal: string) => {
    setSelectedTab(newVal);
    setNewMessages(0);
    setNewSegments(0);
    setChatAlert(undefined);
  };

  const handleSelectSegment = (segment: PlaylistSegment | undefined) => {
    setSelectedSegment(segment);
    // Need to explicitly set 'homographyFrames' and 'drawings' to empty objects. Not sure why.
    reset(segment ?? { homographyFrames: undefined, drawings: undefined });
  };

  const handleOffsetChange = (
    field: "toTimestamp" | "fromTimestamp",
    seconds: number,
  ) => {
    if (field === "fromTimestamp") {
      setValue("fromTimestamp", getValues("fromTimestamp") - seconds * 1000, {
        shouldDirty: true,
      });
    } else if (field === "toTimestamp") {
      setValue("toTimestamp", getValues("toTimestamp") + seconds * 1000, {
        shouldDirty: true,
      });
    }
  };

  const handleFocusModeChange = () => {
    dispatch(
      setLiveMode({
        liveStreamId: liveStream!.id,
        channelId: liveStream!.channelId!,
        mode: focusMode ? LiveStreamMode.Normal : LiveStreamMode.Focus,
      }),
    );
    setFocusMode((v) => !v);
    setChatAlert(undefined);
  };

  useEffect(() => {
    const source = new EventSource(
      `${process.env.REACT_APP_API_URL!}/v1.0/live_streams/${id}?authorization=${token}`,
    );

    source.onmessage = (e) => {
      const msg: LiveStreamEvent = JSON.parse(e.data);
      if (!msg) {
        console.warn("received invalid message", e);
        return;
      }

      switch (msg.type) {
        case LiveStreamEventDiscriminants.Initial:
        case LiveStreamEventDiscriminants.Archived:
          const stream = msg.value;
          setLiveStream(stream);
          setLoading(false);
          setUserColor(user!.id);
          return;
        case LiveStreamEventDiscriminants.NewlyArchived:
          setLiveStream((draft) => {
            if (draft) {
              draft.isLive = false;
            }
          });
          return;
        case LiveStreamEventDiscriminants.NewSegment:
          setLiveStream((draft) => {
            draft?.segments.pushSorted(msg.value, (a, b) => {
              const diff = a.fromTimestamp - b.fromTimestamp;
              return diff === 0 ? a.id - b.id : diff;
            });
          });
          setNewSegments((val) => (val += 1));
          return;
        case LiveStreamEventDiscriminants.UpdateSegment:
          setLiveStream((draft) => {
            const s = msg.value;
            const idx = draft?.segments.findIndex((v) => v.id === s.id);
            if (idx !== undefined && idx >= 0) {
              draft!.segments[idx] = s;
            }
          });
          return;
        case LiveStreamEventDiscriminants.DeleteSegment:
          setLiveStream((draft) => {
            const id = msg.value.id;
            const idx = draft?.segments.findIndex((v) => v.id === id);
            if (idx !== undefined && idx >= 0) {
              draft!.segments.splice(idx, 1);
            }
          });
          return;
        case LiveStreamEventDiscriminants.SendSegment:
          if (msg.value.userId === user?.id) {
            setLiveStream((draft) => {
              const segment = draft!.segments.find(
                (v) => v.id === msg.value.id,
              );
              setFocusedSegment(segment ? current(segment) : undefined);
            });
          }
          return;
        case LiveStreamEventDiscriminants.NewMessage:
          setLiveStream((draft) => {
            draft?.messages.pushSorted(msg.value, (a, b) => {
              const diff =
                new Date(a.timestamp).getTime() -
                new Date(b.timestamp).getTime();
              return diff === 0 ? a.id - b.id : diff;
            });
          });
          setNewMessages((val) => (val += 1));
          setChatAlert(msg.value);

          return;
        case LiveStreamEventDiscriminants.UserJoined:
          setLiveStream((draft) => {
            if (draft) {
              if (!draft.activeUsers.some((v) => v.id === msg.value.id)) {
                draft.activeUsers.push(msg.value);
              }
            }
          });
          return;
        case LiveStreamEventDiscriminants.UserModeUpdated:
          setLiveStream((draft) => {
            if (draft) {
              const idx = draft.activeUsers.findIndex(
                (u) => u.id === msg.value.id,
              );
              if (idx !== undefined && idx >= 0) {
                draft.activeUsers[idx] = msg.value;
              } else {
                draft.activeUsers.push(msg.value);
              }
            }
          });
          return;
        case LiveStreamEventDiscriminants.ActiveUsers:
          setLiveStream((draft) => {
            if (draft) {
              draft.activeUsers = msg.value;
            }
          });
          return;
        case LiveStreamEventDiscriminants.UpdateTacticalPitch:
          setLiveStream((draft) => {
            if (draft) {
              draft.tacticalPitch = msg.value;
            }
          });
      }
    };

    source.onerror = (e) => console.warn("event source error", e);

    return () => source.close();
  }, [id]);

  useEffect(() => {
    if (liveStream) {
      liveStream.activeUsers.forEach((u) => setUserColor(u.id));
      liveStream.segments.forEach(
        (s) => s.creator && setUserColor(s.creator.id),
      );
      liveStream.messages.forEach((m) => m.user && setUserColor(m.user.id));
    }
  }, [liveStream]);

  if (loading || !liveStream) {
    return (
      <Stack alignItems="center">
        <CircularProgress color="secondary" size={80} />
      </Stack>
    );
  }
  const handleDrawChange = (drawings: Record<string, Shape[]>) => {
    setValue("drawings", { ...drawings }, { shouldDirty: true });
  };

  if (focusMode && liveStream.isLive) {
    return (
      <FocusModeView
        liveUrl={liveUrl}
        segment={focusedSegment}
        segments={liveStream.segments}
        onExitFocusMode={() => {
          handleFocusModeChange();
        }}
        onResetSegment={() => setFocusedSegment(undefined)}
      />
    );
  }

  return (
    <Form
      control={control}
      onSubmit={({ data }) => {
        if (liveStream.isLive) {
          dispatch(
            updateLiveSegment({
              liveStreamId: liveStream.id,
              segment: data,
            }),
          ).then(() => reset(data));
        }
      }}
    >
      <Stack gap={1}>
        <Typography variant="h3">{liveStream.name}</Typography>
        {!liveStream.isLive && (
          <Typography variant="h5" fontWeight="normal">
            {dateFormat(liveStream.game.date, "dd.MM.yyyy")}
          </Typography>
        )}
        <Stack
          sx={{ pt: 1 }}
          direction="row"
          justifyContent="space-between"
          gap={2}
          flexWrap="wrap"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              sx={{
                width: 120,
                color: liveStream.isLive ? "grey.A200" : "primary.light",
                alignSelf: "center",
                borderColor: liveStream.isLive ? "grey.A200" : "primary.light",
                ":hover": {
                  bgcolor: "black",
                  borderColor: liveStream.isLive
                    ? "grey.A200"
                    : "primary.light",
                },
              }}
              variant="outlined"
              startIcon={
                <CircleIcon
                  sx={{
                    width: 14,
                    height: 14,
                    color:
                      !liveStream.isLive || selectedSegment
                        ? "primary.light"
                        : "grey.A200",
                  }}
                />
              }
              onClick={() => {
                if (selectedSegment) {
                  handleSelectSegment(undefined);
                } else if (liveStream.isLive) {
                  playerRef?.seekToSecs(playerRef.getDuration() - 2);
                }
              }}
            >
              <Typography sx={{ color: "text.secondary" }}>
                {liveStream.isLive ? "LIVE" : "ARCHIVED"}
              </Typography>
            </Button>
            {!selectedSegment && (
              <VideoAssets
                assets={videoAssets ?? []}
                onSelect={setSelectedAssetIdx}
              />
            )}
          </Stack>
          <Stack direction="row" gap={3} alignItems="center">
            <IconButton
              title="Tactical board"
              onClick={() => setTacModalOpen(true)}
            >
              <WebAssetIcon
                sx={{
                  fontSize: "1.9rem",
                  color: "text.secondary",
                }}
              />
            </IconButton>
            {liveStream.isLive && (
              <>
                <IconButton
                  title="Enter focus mode"
                  onClick={() => handleFocusModeChange()}
                >
                  <CoPresentOutlinedIcon
                    sx={{ fontSize: "1.9rem", color: "fourth.light" }}
                  />
                </IconButton>
                <Tooltip
                  sx={{ fontSize: "3rem" }}
                  placement="bottom-end"
                  title={
                    <Stack gap={1} sx={{ p: 0.5, pl: 0 }}>
                      {liveStream.activeUsers.map((u) => (
                        <Box
                          key={u.id}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <PersonIcon
                            sx={{ fontSize: 25, mr: 1, color: colorMap[u.id] }}
                          />
                          <Typography
                            variant="subtitle1"
                            sx={{ lineHeight: 1.7 }}
                          >
                            {u.firstName} {u.lastName}
                          </Typography>
                        </Box>
                      ))}
                    </Stack>
                  }
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <PeopleAltIcon
                      sx={{ fontSize: "2rem", color: "grey.A200" }}
                    />
                    <Typography
                      color="text.secondary"
                      sx={{
                        fontSize: "1.3rem",
                        lineHeight: 1.2,
                        fontWeight: 600,
                      }}
                    >
                      {liveStream.activeUsers.length}
                    </Typography>
                  </Stack>
                </Tooltip>
              </>
            )}
          </Stack>
        </Stack>
        <Stack
          gap={3}
          direction={xlBreakpoint ? "row" : "column"}
          sx={{
            color: "white",
          }}
        >
          <Stack gap={1} sx={{ width: "100%" }}>
            <CombinedPlayer
              ref={(v) => setPlayerRef(v as VideoPlayerRef | null)}
              url={url}
              gameId={liveStream.game.id}
              getVideoOffset={() =>
                getVideoOffset(liveAsset!, playerRef!.getCurrentMillis())
              }
              drawings={watch("drawings")}
              onDrawChange={
                liveStream.isLive && selectedSegment
                  ? handleDrawChange
                  : undefined
              }
              staticHomography={watch("homographyFrames")}
              onStaticHomographyChange={(homography) =>
                setValue("homographyFrames", homography, {
                  shouldDirty: true,
                })
              }
            />

            {liveStream.isLive && !selectedSegment && (
              <ForzaGameTagsMenu
                videoPlayer={playerRef}
                onClick={(tag, timestamp) => {
                  const asset = liveAsset!;

                  let fromTimestamp = Math.max(
                    0,
                    timestamp - (tag.startOffset ?? 30_000),
                  );
                  let toTimestamp = timestamp + (tag.endOffset ?? 30_000);

                  if (asset.firstHalfZeroOffsetWallClockTime) {
                    fromTimestamp += asset.firstHalfZeroOffsetWallClockTime;
                    toTimestamp += asset.firstHalfZeroOffsetWallClockTime;
                  }

                  const segment = {
                    id: undefined,
                    userId: user?.id,
                    type: LiveStreamEventDiscriminants.NewMessage,
                    forzaGameId: liveStream.game.id,
                    videoAssetId: asset.videoAssetId,
                    homeTeamId: liveStream.game.homeTeamId,
                    visitingTeamId: liveStream.game.visitingTeamId,
                    gameHasPanorama: liveStream.game.hasPanorama,
                    videoBaseUrl: asset.videoBaseUrl,
                    hasHomography: false,
                    favourite: false,
                    description: "",
                    fromTimestamp,
                    toTimestamp,
                    tags: [tag.id],
                    playerTags: [],
                    forzaTeamTags: [],
                    title: tag.displayName,
                    drawings: {},
                    homographyFrames: {},
                    footballFieldGrids: [],
                    footballFieldSequenceGrids: [],
                  };
                  dispatch(
                    createLiveSegment({
                      liveStreamId: liveStream.id,
                      segment,
                    }),
                  );
                  if (!liveStream.segments.length) {
                    dispatch(
                      setLiveThumbnail({
                        liveStreamId: liveStream.id,
                        thumbnail: playerRef!.getCurrentFrame(600),
                      }),
                    );
                  }
                }}
              />
            )}
            {selectedSegment && (
              <Stack gap={3}>
                {liveStream.isLive && (
                  <Stack direction="row" justifyContent="space-between">
                    {mobileBreakpoint ? (
                      <>
                        <VideoOffsetMenu
                          onClick={(seconds) =>
                            handleOffsetChange("fromTimestamp", seconds)
                          }
                        />
                        <VideoOffsetMenu
                          mirrored
                          onClick={(seconds) =>
                            handleOffsetChange("toTimestamp", seconds)
                          }
                        />
                      </>
                    ) : (
                      <>
                        <VideoOffsetBar
                          onClick={(seconds) =>
                            handleOffsetChange("fromTimestamp", seconds)
                          }
                        />
                        <VideoOffsetBar
                          mirrored
                          onClick={(seconds) =>
                            handleOffsetChange("toTimestamp", seconds)
                          }
                        />
                      </>
                    )}
                  </Stack>
                )}
                <Stack direction="row" gap={2} sx={{ width: "100%" }}>
                  <Stack
                    sx={{
                      width: "100%",
                      "& .Mui-disabled": {
                        color: "inherit !important",
                        WebkitTextFillColor: "inherit !important",
                      },
                    }}
                    gap={1}
                  >
                    <TextField
                      sx={{
                        "& .MuiInputBase-input": {
                          fontWeight: 600,
                          fontSize: "1.4rem",
                        },
                      }}
                      spellCheck={false}
                      fullWidth
                      hiddenLabel
                      size="small"
                      variant="standard"
                      disabled={!liveStream.isLive}
                      defaultValue={selectedSegment.title}
                      {...register("title")}
                      onKeyDownCapture={(e) => e.stopPropagation()}
                    />

                    <TextField
                      sx={{
                        "& .MuiInputBase-root": {
                          p: 1.5,
                        },
                      }}
                      multiline
                      placeholder="Add description here..."
                      rows={4}
                      fullWidth
                      variant="filled"
                      disabled={!liveStream.isLive}
                      defaultValue={selectedSegment.description ?? ""}
                      {...register("description")}
                      onKeyDownCapture={(e) => e.stopPropagation()}
                    />
                  </Stack>
                  <Divider
                    orientation="vertical"
                    sx={{ bgcolor: "primary.light" }}
                  />
                  <Stack gap={2}>
                    <Button
                      disabled={!isDirty || !liveStream.isLive}
                      type="submit"
                      color="success"
                      variant="contained"
                      startIcon={<SaveSharpIcon />}
                      sx={{
                        alignItems: "center",
                        width: 150,
                        fontSize: "1.1rem",
                      }}
                    >
                      SAVE
                    </Button>
                    <Button
                      disabled={!isDirty || !liveStream.isLive}
                      color="primary"
                      variant="contained"
                      startIcon={<ClearIcon sx={{ width: 18, height: 18 }} />}
                      sx={{
                        width: 150,
                        fontSize: "1.0rem",
                        fontWeight: 500,
                      }}
                      onClick={() => reset(selectedSegment)}
                    >
                      RESET
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            )}
            <SegmentsStatsTable
              segments={liveStream.segments}
              onFilterChange={
                liveStream.isLive ? (id) => setTagTableFilter(id) : undefined
              }
            />
          </Stack>
          <Stack
            gap={1}
            sx={{
              width: xlBreakpoint ? 500 : "100%",
            }}
          >
            <Tabs
              sx={{
                pb: 2,
                "& .MuiTab-root": {
                  width: xlBreakpoint ? "50%" : mobileBreakpoint ? "50%" : 200,
                  color: "text.secondary",
                  minHeight: 48,
                  "&:hover": {
                    color: "white",
                  },
                  "&.Mui-selected": {
                    color: "white",
                    fontWeight: theme.typography.fontWeightBold,
                  },
                },
              }}
              value={selectedTab}
              indicatorColor="secondary"
              onChange={(_, val: string) => handleTabChange(val)}
            >
              <Tab
                label="Clips"
                value="clips"
                icon={
                  newSegments >= 1 && selectedTab !== "clips"
                    ? notifyIcon(newSegments)
                    : undefined
                }
                iconPosition="end"
              />
              <Tab
                label="Chat"
                value="chat"
                icon={
                  newMessages >= 1 && selectedTab !== "chat" ? (
                    <Typography
                      sx={{
                        width: 22,
                        fontSize: "0.8rem",
                        bgcolor: "red",
                        color: "white",
                        alignContent: "center",
                        borderRadius: 20,
                      }}
                    >
                      {newMessages}
                    </Typography>
                  ) : undefined
                }
                iconPosition="end"
              />
            </Tabs>
            {selectedTab === "clips" ? (
              <LiveSegments
                colorMap={colorMap}
                segments={liveStream.segments}
                focusModeUsers={
                  focusModeUsers?.length ? focusModeUsers : undefined
                }
                selected={selectedSegment}
                tagFilter={tagTableFilter}
                onSelect={handleSelectSegment}
                onChange={
                  liveStream.isLive
                    ? (s) => {
                        dispatch(
                          updateLiveSegment({
                            liveStreamId: liveStream.id,
                            segment: s,
                          }),
                        );
                      }
                    : undefined
                }
                onDelete={
                  liveStream.isLive
                    ? (s) => {
                        setConfirm({
                          message: `Are you sure you want to delete '${s.title}'?`,
                          onConfirm: () => {
                            dispatch(
                              deleteLiveSegment({
                                liveStreamId: liveStream.id,
                                segmentId: s.id,
                              }),
                            );
                          },
                        });
                      }
                    : undefined
                }
                onSendToFocusedUser={
                  liveStream.isLive
                    ? (segment, user) => {
                        dispatch(
                          sendLiveSegment({
                            liveStreamId: liveStream.id,
                            segmentId: segment.id,
                            userId: user.id,
                          }),
                        );
                      }
                    : undefined
                }
                onFilterChange={() => setTagTableFilter(undefined)}
              />
            ) : (
              <LiveMessages
                liveStreamId={id}
                disabled={!liveStream.isLive}
                messages={liveStream.messages}
                colorMap={colorMap}
              />
            )}
          </Stack>
        </Stack>
        {confirm && (
          <ConfirmModal
            {...confirm}
            open
            onClose={() => setConfirm(undefined)}
          />
        )}
      </Stack>
      <Tactical
        open={tacModalOpen}
        value={liveStream.tacticalPitch}
        disabled={!liveStream.isLive}
        onClose={() => setTacModalOpen(false)}
        onChange={(tacticalPitch) => {
          dispatch(
            updateLiveTacticalPitch({
              liveStreamId: liveStream.id,
              tacticalPitch,
            }),
          );
        }}
      />
      {chatAlert && selectedTab === "clips" && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={!!chatAlert}
          onClose={() => setChatAlert(undefined)}
          autoHideDuration={5000}
        >
          <Box
            sx={{
              bgcolor: "fourth.dark",
              borderRadius: 1,
              p: 2,
              width: 300,
            }}
          >
            <Stack
              direction="row"
              spacing={1.5}
              alignItems="center"
              sx={{ pb: 1.5 }}
            >
              <ChatIcon fontSize="small" sx={{ color: "fourth.light" }} />
              <Typography fontStyle="italic" color="fourth.light">
                {chatAlert.user?.firstName} {chatAlert?.user?.lastName}
              </Typography>
            </Stack>
            <Typography>{chatAlert.message}</Typography>
          </Box>
        </Snackbar>
      )}
    </Form>
  );
};

const notifyIcon = (value: number) => {
  return (
    <Typography
      sx={{
        width: 22,
        fontSize: "0.8rem",
        bgcolor: "red",
        color: "white",
        alignContent: "center",
        borderRadius: 20,
      }}
    >
      {value}
    </Typography>
  );
};
