import { Stack } from "@mui/system";
import { CreateInvite, User, UserRole } from "generated/openapi";
import { FC, useMemo, useState } from "react";
import { selectOrg, selectUser, useAppSelector } from "store";
import { InviteUser } from "./InviteUser";
import { OrgUsersSection } from "./OrgUsersSection";

export const OrgUsers: FC = () => {
  const user = useAppSelector(selectUser)!;
  const org = useAppSelector(selectOrg)!;

  const [inviteUser, setInviteUser] = useState<CreateInvite | undefined>(
    undefined,
  );

  const isAdmin = user.userRole === UserRole.Admin;
  const isCoach = user.userRole === UserRole.Coach;

  const [admins, coaches, players] = useMemo(() => {
    const admins: Record<string, User> = {};
    const coaches: Record<string, User> = {};
    const players: Record<string, User> = {};

    for (const t of org.teams) {
      for (const u of t.admins) admins[u.id] = u;
      for (const u of t.coaches) coaches[u.id] = u;
      for (const u of t.players) players[u.id] = u;
    }
    for (const u of org.otherUsers) {
      if (u.userRole === UserRole.Admin) admins[u.id] = u;
      else if (u.userRole === UserRole.Coach) coaches[u.id] = u;
      else players[u.id] = u;
    }

    return [
      Object.values(admins),
      Object.values(coaches),
      Object.values(players),
    ];
  }, [org]);

  return (
    <Stack gap={4}>
      <OrgUsersSection
        title="Players"
        userRole={UserRole.Player}
        canManage={isCoach || isAdmin}
        users={players}
      />
      <OrgUsersSection
        title="Coaches"
        userRole={UserRole.Coach}
        canManage={isCoach || isAdmin}
        users={coaches}
      />
      <OrgUsersSection
        title="Admins"
        userRole={UserRole.Admin}
        canManage={isAdmin}
        users={admins}
      />

      {inviteUser && (
        <InviteUser
          invite={inviteUser}
          onClose={() => setInviteUser(undefined)}
        />
      )}
    </Stack>
  );
};
