import { Autocomplete, TextField, Typography } from "@mui/material";
import { ForzaTeam } from "generated/openapi";
import { FC } from "react";
import {
  selectForzaTeamsMap,
  selectForzaTeamsSorted,
  useAppSelector,
} from "store";

interface Props {
  label?: string;
  value?: number;
  teams?: ForzaTeam[];
  onChange: (_?: ForzaTeam) => void;
}

export const ForzaTeamsFilter: FC<Props> = ({
  label,
  value,
  teams,
  onChange,
}) => {
  const _teams = useAppSelector(selectForzaTeamsSorted);
  const teamsMap = useAppSelector(selectForzaTeamsMap);

  label ??= "Team";
  teams ??= _teams;

  return (
    <Autocomplete
      size="small"
      value={value !== undefined ? teamsMap[value] : null}
      options={teams}
      renderInput={(params) => (
        <TextField {...params} label={label ?? "Team"} />
      )}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Typography {...props} key={option.id}>
          {option.name}
        </Typography>
      )}
      onChange={(_, v) => onChange(v ?? undefined)}
    />
  );
};
