import { Button, TextField, Typography } from "@mui/material";
import { FileUpload, Modal } from "components";
import { DateFilter } from "components/HighlightsFilters/DateFilter";
import { UpdateUser as UpdateUserBody, User } from "generated/openapi";
import { FC } from "react";
import { Form, useForm } from "react-hook-form";
import { updateUser, useAppDispatch } from "store";

export interface Props {
  user: User;
  onClose: () => void;
}

export const UpdateUser: FC<Props> = ({ user, onClose }) => {
  const dispatch = useAppDispatch();

  const {
    watch,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm<UpdateUserBody>({ defaultValues: user });

  return (
    <Modal open={true} onClose={onClose}>
      <Form
        control={control}
        style={{ display: "flex", flexDirection: "column", gap: 18 }}
        onSubmit={({ data }) => {
          dispatch(updateUser({ user: data })).then(() => onClose());
        }}
      >
        <Typography sx={{ pb: 2 }} variant="h3">
          Update User
        </Typography>

        <Typography>Profile Picture</Typography>
        <FileUpload
          accept="image/*"
          valid={!!watch("profileImage")}
          onChange={(v) =>
            setValue(
              "profileImage",
              v.substring("data:image/png;base64,".length),
            )
          }
        />

        <TextField
          sx={{ mt: 2 }}
          color="info"
          label="First Name"
          variant="outlined"
          error={!!errors.firstName}
          {...register("firstName", { required: true })}
        />
        <TextField
          color="info"
          label="Last Name"
          variant="outlined"
          error={!!errors.lastName}
          {...register("lastName", { required: true })}
        />

        <DateFilter
          label="Birthday"
          size="medium"
          value={watch("birthday")}
          onChange={(v) => setValue("birthday", v?.toISOString())}
        />

        <Button color="info" type="submit">
          Submit
        </Button>
      </Form>
    </Modal>
  );
};
