import { User } from "generated/openapi";
import { FC, useEffect, useState } from "react";
import { selectAccessToken, useAppSelector } from "store";

export interface Props {
  user: User;
}

export const ProfilePicture: FC<Props> = ({ user }) => {
  const token = useAppSelector(selectAccessToken);

  const [version, setVersion] = useState(0);

  useEffect(() => setVersion((v) => v + 1), [user]);

  return (
    <img
      height={300}
      src={`${process.env.REACT_APP_API_URL}/v1.0/users/${user.id}/profile_image?authorization=${token}&version=${version}`}
      onError={(e) => {
        e.currentTarget.src = "/default-profile-picture.svg";
      }}
    />
  );
};
